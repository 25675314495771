import axios from 'axios';
import {
  DataProp,
  LayeredBuyBid,
  LayeredBought,
  TradeNotations,
} from '../types';
import { getSalesData } from '../utils/salesPageUtils';
import { getKlineData } from '../utils/binanceHistoricalWrapper';
import { BarData, Time } from 'lightweight-charts';
import { millisecToSec } from '../backtest-node/utils';

const TWEET_TRADES_URL =
  'https://aljn817hni.execute-api.eu-central-1.amazonaws.com/tweetTrades-getrich';
const ALERT_LAMBDA_URL =
  'https://aljn817hni.execute-api.eu-central-1.amazonaws.com/reactToAlerts-getrich';
const GET_RICH_SLOW_URL =
  'https://aljn817hni.execute-api.eu-central-1.amazonaws.com/getSalesPageData-getrich';
const BINANCE_TICKERS_PRICES = 'https://api.binance.com/api/v3/ticker/price';

const COIN_PAPRIKA_GLOBAL = 'https://api.coinpaprika.com/v1/global';

declare global {
  interface Window {
    sparklineCache: {
      [key: string]: {
        data: number[];
        timestamp: number;
      };
    };
  }
}

// Initialize the cache
if (typeof window !== 'undefined') {
  window.sparklineCache = window.sparklineCache || {};
}

const BATCH_SIZE = 5; // Process 5 coins at a time
const BATCH_DELAY = 1000; // 1 second delay between batches

const CACHE_PREFIX = 'sparkline_';
export const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

export type TweetLambdaProps = {
  coinID: string;
  notation: string;
  buyType: string;
  candleData: DataProp;
  order: LayeredBuyBid | LayeredBought;
};

export type SparklineData = number[];

export const getTotalMarketCapData = async () => {
  const response = await axios.get(COIN_PAPRIKA_GLOBAL);
  return response.data.market_cap_usd;
};

export const historicalApiUrl = (
  symbol: string,
  notation: TradeNotations,
  timeType: string,
  time: number
) =>
  `https://min-api.cryptocompare.com/data/v2/histo${timeType}?fsym=${symbol}&tsym=${notation.toUpperCase()}&limit=${time}`;

export const getHistoricalDataForCryptocompareCoin = async (
  endpoint: string
) => {
  const response = await axios.get(endpoint);
  return response.data.Data.Data;
};

export const getHistoricalDataForCoin = async (
  symbol: string,
  tradeNotation: string,
  interval: '1h' | 'day' = 'day',
  limit: number = 2000
): Promise<BarData[]> => {
  try {
    const binanceInterval = interval === 'day' ? '1d' : '1h';

    // Calculate start date based on limit
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - limit);

    const klineData = await getKlineData(
      symbol,
      tradeNotation,
      binanceInterval,
      startDate,
      endDate,
      limit
    );

    // Convert Binance data to BarData format
    return klineData.map((kline: any) => ({
      time: millisecToSec(kline.openTime) as Time,
      open: Number(kline.open),
      high: Number(kline.high),
      low: Number(kline.low),
      close: Number(kline.close),
    }));
  } catch (error) {
    console.error('Error fetching historical data:', error);
    return [];
  }
};

export const geFirstXCoins = async (endpoint: string, max: number) => {
  const response = await axios.get(endpoint);
  return response.data.slice(0, max);
};

export const getBinanceTickerPrices = async () => {
  const response = await axios.get(BINANCE_TICKERS_PRICES);
  return response.data;
};

export const callLambdaToPostTweet = (event: TweetLambdaProps) => {
  // we stop posting to twitter because of API changes that costs money
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return null;
  }

  console.log(`calling tweetTrades Lambda function for ${event.coinID}`);
  axios
    .post(TWEET_TRADES_URL, event)
    .then((res) => {
      console.log(
        `Success - response from lambda res --> ${res.status} ${res.data}`
      );
    })
    .catch((error) => {
      console.log('Error when invoking lambda tweet', error.message);
    });

  return null;
};

type AlertLambdaProps = {
  currentPrice: string;
  alertID: string;
  stoplossPrice: number;
};
export const callLambdaReactAlert = (payload: AlertLambdaProps) => {
  axios
    .post(ALERT_LAMBDA_URL, payload)
    .then((res) => {
      console.log(
        `Success - response from lambda res --> ${res.status} ${res.data}`
      );
    })
    .catch((error) => {
      console.log('Error when invoking lambda alert', error.message);
    });
};

export const isLocalhost =
  (typeof window !== 'undefined' && window.location.hostname === 'localhost') ||
  (typeof window !== 'undefined' && window.location.hostname === '127.0.0.1');

export const getSalesPageData = async () => {
  // Check if we're running on localhost

  if (isLocalhost) {
    console.log('Development mode: Using mock sales data');
    const salesPageData = await getSalesData();
    return salesPageData;
  }

  console.log('Production mode: Fetching from API');
  const response = await axios.get(GET_RICH_SLOW_URL);
  return response.data;
};

const getFromCache = (coinId: string): number[] | null => {
  try {
    const cached = localStorage.getItem(`${CACHE_PREFIX}${coinId}`);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        // Also update the in-memory cache
        window.sparklineCache[coinId] = { data, timestamp };
        return data;
      }
    }
    return null;
  } catch (error) {
    console.warn('Failed to read from cache:', error);
    return null;
  }
};

const setToCache = (coinId: string, data: number[]) => {
  try {
    const cacheData = {
      data,
      timestamp: Date.now(),
    };

    // Update both localStorage and in-memory cache
    localStorage.setItem(`${CACHE_PREFIX}${coinId}`, JSON.stringify(cacheData));
    window.sparklineCache[coinId] = cacheData;
  } catch (error) {
    console.warn('Failed to cache sparkline data:', error);
  }
};

export const getSparklinePoints = async (
  coinId: string,
  days: number = 7
): Promise<SparklineData | null> => {
  try {
    // Check cache first
    const cached = getFromCache(coinId);
    if (cached) {
      return cached;
    }

    const [coin] = coinId.split('-');
    const symbolMap: { [key: string]: string } = {
      bitcoin: 'BTC',
      ethereum: 'ETH',
      chainlink: 'LINK',
      cardano: 'ADA',
      polkadot: 'DOT',
      // Add more mappings as needed
    };

    const baseSymbol = symbolMap[coin] || coin.toUpperCase();
    const symbol = `${baseSymbol}USDT`;

    const response = await axios.get('https://api.binance.com/api/v3/klines', {
      params: {
        symbol,
        interval: '1d',
        limit: days,
      },
    });

    // Binance klines returns array of arrays where index 4 is the closing price
    const prices = response.data.map((kline: any[]) => Number(kline[4]));

    // Cache the result
    setToCache(coinId, prices);
    return prices;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 429) {
        // Try to get cached data even if expired
        try {
          const expired = localStorage.getItem(`${CACHE_PREFIX}${coinId}`);
          if (expired) {
            const { data } = JSON.parse(expired);
            console.warn(
              `Rate limited for ${coinId}, using expired cached data`
            );
            return data;
          }
        } catch (e) {
          console.warn('Failed to read expired cache:', e);
        }
      }
      console.warn(
        `Failed to fetch sparkline for ${coinId}:`,
        error.response?.data
      );
    }
    return Array(7).fill(0); // Fallback data
  }
};

export const preloadSparklineData = async (
  coinIds: string[],
  lastUpdated: number | undefined
) => {
  if (!lastUpdated) return;

  const nextUpdateTime = lastUpdated + CACHE_DURATION;
  const now = Date.now();

  // If we've passed the next update time, just clear the caches and return
  if (now >= nextUpdateTime) {
    console.log('Cache expired, clearing all sparkline data');
    coinIds.forEach((id) => {
      localStorage.removeItem(`${CACHE_PREFIX}${id}`);
      if (window.sparklineCache) {
        delete window.sparklineCache[id];
      }
    });
    return; // Exit early without fetching new data
  }

  // Only check and fetch uncached items if we're within the cache window
  let cachedCount = 0;
  const uncachedCoinIds = coinIds.filter((id) => {
    try {
      const cached = localStorage.getItem(`${CACHE_PREFIX}${id}`);
      if (!cached) return true;
      cachedCount++;
      return false;
    } catch {
      return true;
    }
  });

  if (cachedCount > 0) {
    console.log(`Using cached data for ${cachedCount} coins`);
  }

  if (uncachedCoinIds.length === 0) return;

  console.log(`Fetching new data for ${uncachedCoinIds.length} coins`);

  // Process in smaller batches to respect Binance rate limits
  const chunks = [];
  for (let i = 0; i < uncachedCoinIds.length; i += BATCH_SIZE) {
    chunks.push(uncachedCoinIds.slice(i, i + BATCH_SIZE));
  }

  for (const chunk of chunks) {
    await Promise.all(
      chunk.map(async (id) => {
        try {
          await getSparklinePoints(id);
        } catch (error) {
          console.warn(`Failed to fetch sparkline for ${id}:`, error);
        }
      })
    );
    await new Promise((resolve) => setTimeout(resolve, BATCH_DELAY));
  }
};
