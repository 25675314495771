import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';
import { calcTimeTakenForCompleteTrade } from '../../../utils/calculations/timeCalculation';

import {
  LayeredBuyBidDetails,
  LayeredBoughtDetails,
  LayeredBuyBid,
  LayeredBought,
  isLayerBuyBought,
  CoinOrdersTable,
} from '../../../types';
import { ReactTable } from './ReactTable';
import { defaulthideColumnsOnMobile } from './coinsTableParts';
import { BuyOrderType } from '../../../API';

type OrdersTableProps = {
  currentPrice?: number;
  bids?: LayeredBuyBidDetails;
  boughts?: LayeredBoughtDetails;
  compact?: boolean;
  coinName?: string;
  isTradePairBtc?: boolean;
};

export const convertToTableFormat = (
  data: LayeredBuyBidDetails | LayeredBoughtDetails,
  compact: boolean = false
) => {
  const convertedData: CoinOrdersTable[] = [];
  let i = 0;
  const coin: CoinOrdersTable[] = [];

  data.forEach((order: LayeredBuyBid | LayeredBought) => {
    let buyTime = null;
    let sellTime = null;
    let timeTaken = null;
    let hasRealOrder = false;
    let isFibOrder = false;
    if (isLayerBuyBought(order)) {
      const result = calcTimeTakenForCompleteTrade(
        order.buyTime,
        order.sellTime
      );
      buyTime = result.buyTime;
      sellTime = result.sellTime;
      timeTaken = compact && !order.sellTime ? null : result.timeTaken;

      hasRealOrder = Boolean(
        (order.buyOrderInfo && order.realBuyOrderStatus !== 'canceled') ||
          (order.sellOrderInfo && order.realSellOrderStatus !== 'canceled')
      );

      isFibOrder = order.buyOrderType === BuyOrderType.piggyBankFibOrder;
    }

    coin[i] = {
      percAndColor: [
        isFibOrder
          ? Number((order as LayeredBought).fibLevel ?? 0)
          : order.buyPercent ?? 0,
        order.buyColor ?? '',
        order.buyOrderType ?? '',
      ],
      invested: order.invested ?? 0,
      noOfCoins: order.invested
        ? ((0.995 * order.invested) / order.buyPrice).toFixed(3)
        : null,
      buyPrice: order.buyPrice,
      buyTime,
      stoplossInfo: isLayerBuyBought(order)
        ? [order.stoploss, order.stoplossPrice]
        : undefined,
      profitSecured: isLayerBuyBought(order) ? order.profitSecured : undefined,
      sellTime: sellTime ?? undefined,
      timeTaken,
      orderInfo: [Boolean(hasRealOrder)],
      orderData: order,
    };
    convertedData.push(coin[i]);
    i += 1;
  });
  return convertedData;
};

const areEqual = (prevProps: OrdersTableProps, nextProps: OrdersTableProps) =>
  // Only re-render if bids or boughts has changed
  prevProps.bids === nextProps.bids && prevProps.boughts === nextProps.boughts;

export const AwsOrdersTable: React.FC<OrdersTableProps> = memo(
  ({
    currentPrice,
    bids,
    boughts,
    compact = false,
    coinName,
    isTradePairBtc,
  }) => {
    const data = bids && boughts ? bids.concat(boughts) : bids || boughts || [];
    const tableCompatableData = React.useMemo(
      () => convertToTableFormat(data, compact),
      [data]
    );

    const columns = React.useMemo(
      () => [
        {
          ...(compact
            ? { Header: `${coinName} Realtime` }
            : { Header: 'Coin Statistics' }),
          columns: [
            {
              Header: 'Buy %',
              accessor: 'percAndColor',
              width: 80,
            },
            {
              Header: 'Invested',
              accessor: 'invested',
              className: 'marketName',
              width: 80,
            },
            {
              Header: 'profitSecured',
              accessor: 'profitSecured',
              width: 120,
            },

            ...(!compact
              ? [
                  {
                    Header: 'No. of coins',
                    accessor: 'noOfCoins',
                    width: 100,
                  },
                  {
                    Header: 'Real Order?',
                    accessor: 'orderInfo',
                    width: 100,
                  },
                  {
                    Header: 'sellTime',
                    accessor: 'sellTime',
                  },
                ]
              : []),

            {
              Header: 'Time taken',
              accessor: 'timeTaken',
              width: 100,
            },
            {
              Header: 'buyTime',
              accessor: 'buyTime',
              width: 90,
            },
            {
              Header: 'buyPrice',
              accessor: 'buyPrice',
              width: 80,
            },
            {
              Header: 'stoploss',
              accessor: 'stoplossInfo',
              width: 80,
            },
            ...(!compact
              ? [
                  {
                    Header: 'Actions',
                    accessor: 'coinActions',
                  },
                ]
              : []),
          ],
        },
      ],
      [tableCompatableData]
    );

    if (!bids && !boughts && !currentPrice) return null;

    return compact ? (
      <ReactTable
        columns={columns}
        data={tableCompatableData}
        sortAccessor="buyTime"
        descending
        compact
        istradePairBtc={isTradePairBtc}
        hideColumnsOnMobile={defaulthideColumnsOnMobile}
      />
    ) : (
      <Grid.Row>
        <Grid.Column>
          <div className="col-lg-10 coinholdings">
            <ReactTable
              columns={columns}
              data={tableCompatableData}
              currentPrice={currentPrice}
              sortAccessor="buyTime"
              descending
              istradePairBtc={isTradePairBtc}
              hideColumnsOnMobile={defaulthideColumnsOnMobile}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  },
  areEqual
);
