import React from 'react';
import { Price } from '../Prices';

type PriceInfoColumnProps = {
  percentage: number;
  price: number;
  istradePairBtc: boolean;
  cell: any;
};

export const PriceInfoColumn: React.FC<PriceInfoColumnProps> = ({
  percentage,
  price,
  istradePairBtc,
  cell,
}) => {
  let textColor = '#2185d0'; // blue
  if (percentage >= 90) {
    textColor = '#21ba45'; // green
  } else if (percentage >= 40) {
    textColor = '#fbbd08'; // yellow
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '4px',
      }}
    >
      {/* Percentage with color coding */}
      <span
        style={{
          fontWeight: 'bold',
          color: textColor,
        }}
      >
        {percentage.toFixed(2)}%
      </span>

      {/* Separator */}
      <div style={{ color: '#666' }}>|</div>

      {/* Price */}
      <div style={{ color: '#666' }}>
        <Price
          istradePairBtc={istradePairBtc}
          cell={{ ...cell, value: price }}
        />
      </div>
    </div>
  );
};
