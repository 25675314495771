import { HighLowPrice, MarketCycle } from '../API';
import {
  dateTimeInMillisecToSec,
  getCurrentCycleStartDateInSeconds,
} from '../backtest-node/utils';
import { findMarketPeriodStartDate } from '../backtest-node/utils';
import { FibLevels } from '../types';

export const FIB_LEVELS = {
  FIRST_LEVEL: 0.236,
  SECOND_LEVEL: 0.382,
  HALF_LEVEL: 0.5,
  GOLDEN_RATIO: 0.618,
  DEEPER_LEVEL: 0.702,
  MAJOR_LEVEL: 0.786,
  FULL_LEVEL: 1,
  EXTENSION_1: 1.618,
  EXTENSION_2: 2.618,
  EXTENSION_3: 3.618,
  EXTENSION_4: 4.236,
} as const;

export const DRAWDOWN_THRESHOLD_BUFFER = 10; // Additional percentage points below average market drawdown
export const AVERAGE_CYCLE_DRAWDOWN = 88;

export const calculateFibLevels = (
  coinID: string,
  notation: string,
  highsAndLows: Omit<HighLowPrice, '__typename'>[]
) => {
  const { ATH, ATL, drawdownPercentage } = calculateMarketDrawdown(
    coinID,
    notation,
    highsAndLows
  );
  const diff = ATH - ATL;

  const adjustedDrawdown = Math.max(
    (drawdownPercentage + AVERAGE_CYCLE_DRAWDOWN) / 2,
    AVERAGE_CYCLE_DRAWDOWN
  );
  // const percision = notation === 'usd' ? 2 : 8;
  // notation === 'usd' &&
  //   console.log(
  //     coinID + '-' + notation,
  //     ' drawdown --',
  //     Number(adjustedDrawdown.toFixed(2)) + '%',
  //     'ATH --',
  //     Number(ATH.toFixed(percision)),
  //     'ATL --',
  //     Number(ATL.toFixed(percision))
  //   );
  return {
    [FIB_LEVELS.DEEPER_LEVEL]: ATL + diff * FIB_LEVELS.DEEPER_LEVEL,
    [FIB_LEVELS.EXTENSION_1]: ATL + diff * FIB_LEVELS.EXTENSION_1,
    [FIB_LEVELS.EXTENSION_2]: ATL + diff * FIB_LEVELS.EXTENSION_2,
    [FIB_LEVELS.EXTENSION_3]: ATL + diff * FIB_LEVELS.EXTENSION_3,
    [FIB_LEVELS.EXTENSION_4]: ATL + diff * FIB_LEVELS.EXTENSION_4,
    ATH,
    ATL,
    drawdownPercentage: adjustedDrawdown,
  };
};

export const calculateMarketDrawdown = (
  coinID: string,
  notation: string,
  highsAndLows: Omit<HighLowPrice, '__typename'>[]
): {
  ATH: number;
  ATL: number;
  drawdownPercentage: number;
} => {
  if (!highsAndLows.length) {
    throw new Error('No highs and lows found for ' + coinID + notation);
  }

  // Get the highest high and lowest low
  const highs = highsAndLows.filter((point) => point.priceType === 'high');
  const lows = highsAndLows.filter((point) => point.priceType === 'low');

  if (!highs.length || !lows.length)
    return { ATH: 0, ATL: 0, drawdownPercentage: 0 };

  const ATH = Math.max(...highs.map((h) => h.price));
  const ATL = Math.min(...lows.map((l) => l.price));

  // Calculate drawdown percentage
  return {
    ATH,
    ATL,
    drawdownPercentage: ((ATH - ATL) / ATH) * 100,
  };
};

export const isPiggyBankEligible = (
  marketCycle: MarketCycle,
  currentDrawdown: number,
  fibLevels: FibLevels,
  collectFreeCoins: boolean
): boolean => {
  if (!collectFreeCoins) return false;

  const { drawdownPercentage } = fibLevels;

  const isInAccumulationPhase =
    marketCycle === MarketCycle.bear ||
    marketCycle === MarketCycle.accumulation;

  const isDrawdownDeepEnough =
    currentDrawdown >= drawdownPercentage - DRAWDOWN_THRESHOLD_BUFFER;

  return isInAccumulationPhase && isDrawdownDeepEnough;
};

export const getFibLevelsForCurrentCycle = (
  coinID: string,
  notation: string,
  highsAndLows: HighLowPrice[],
  currentTime: number
) => {
  const marketCycleStartDate = getCurrentCycleStartDateInSeconds();
  const startDate = findMarketPeriodStartDate(marketCycleStartDate);

  // Filter highs and lows for current cycle
  const highsAndLowsForThisCycle = highsAndLows.filter(
    (point): point is HighLowPrice =>
      point !== null &&
      dateTimeInMillisecToSec(point.time) >= startDate &&
      dateTimeInMillisecToSec(point.time) <= currentTime
  );

  return calculateFibLevels(coinID, notation, highsAndLowsForThisCycle);
};
