import React from 'react';
import { Grid } from 'semantic-ui-react';
import { AwsOrdersTable } from '../SubComponents/tables/CoinOrdersTable';
import { NOTATION } from '../../config/backTestConfig';
import { BackTestCoin, BackTestTableCoin } from '../../backtest-node/utils';

interface BackTestTableProps {
  selectedCoinDetails: Record<string, BackTestTableCoin>;
}

const formatCoinDetails = (coinDetail: BackTestTableCoin) => {
  const { Coin, percentageStatistics, fibLevels } = coinDetail;
  const percision = NOTATION === 'usd' ? 2 : 8;
  return `${Coin.name} - ${
    percentageStatistics.averageAlertPercentage
  }% alert - ATH: ${fibLevels.ATH.toFixed(
    percision
  )} - ATL: ${fibLevels.ATL.toFixed(
    percision
  )} - Drawdown: ${fibLevels.drawdownPercentage.toFixed(2)}% - `;
};

export const BackTestTable: React.FC<BackTestTableProps> = ({
  selectedCoinDetails,
}) => (
  <Grid
    divided="vertically"
    padded
    relaxed
    style={{ justifyContent: 'center', margin: '0 auto' }}
    className="tableContainer"
  >
    {Object.entries(selectedCoinDetails).map(([coinKey, coinDetail]) => (
      <AwsOrdersTable
        key={coinKey}
        currentPrice={coinDetail.currentPrice}
        bids={coinDetail.layeredBuyBids}
        boughts={coinDetail.layeredBuyBoughts}
        compact
        coinName={formatCoinDetails(coinDetail)}
        isTradePairBtc={NOTATION !== 'usd'}
      />
    ))}
  </Grid>
);
