/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import * as path from 'path';
import fs from 'fs';

import {
  AWSCoinInformation,
  LayeredBuyBidDetails,
  LayeredBoughtDetails,
  LinesInChart,
  PercentageStatistics,
  FibLevels,
} from '../types';
import { getTickerData } from '../chart-specifics/linesAndMarkers';
import AwsDatabase from '../database/Database';
import {
  calculatePercentageStatistics,
  calculateTotalBalance,
} from '../utils/calculations/percentageAndprice';
import { PiggyBankPosition } from '../API';
import { calculateFibLevels } from '../utils/piggyBank';
import { marketPeriods } from '../config/backTestConfig';

export type BackTestCoin = Omit<
  AWSCoinInformation,
  'layeredBuyBids' | 'layeredBuyBoughts' | 'alertPrice'
> & {
  tickers: [];
  highPrice: number;
  alertPrice: number | null;
  lowFromHighPrice: number | null;
  layeredBuyBids: LayeredBuyBidDetails;
  layeredBuyBoughts: LayeredBoughtDetails;
  hasAlertTriggered: boolean;
  linesInChart: LinesInChart;
  percentageStatistics: PercentageStatistics;
  hasBreakEvenStoploss: boolean;
  piggyBankPositions: PiggyBankPosition[];
  fibLevels: FibLevels;
};

export type BackTestTableCoin = {
  currentPrice: number;
  layeredBuyBids: LayeredBuyBidDetails;
  layeredBuyBoughts: LayeredBoughtDetails;
  Coin: {
    name: string;
  };
  percentageStatistics: {
    averageAlertPercentage: number;
  };
  fibLevels: {
    ATH: number;
    ATL: number;
    drawdownPercentage: number;
  };
};

export const millisecToSec = (millisec: number) => {
  return Math.floor(millisec / 1000);
};

export const dateTimeInMillisecToSec = (time: number) => {
  if (time.toString().length > 10) {
    return millisecToSec(time);
  }
  return time;
};

const trimDataBasedOnDate = (
  data: any[],
  startDateTime: number,
  endDateTime: number
) =>
  data.reverse().filter((point: any) => {
    const t = point.unix ?? point['Unix Timestamp'];
    const correctTimeStamp = t.toString().length > 10 ? millisecToSec(t) : t;
    return correctTimeStamp >= startDateTime && correctTimeStamp <= endDateTime;
  });

const processChunks = async (url: string): Promise<[]> => {
  let result: string;

  if (typeof window === 'undefined') {
    // Node.js environment

    const filePath = path.resolve(
      __dirname,
      '..',
      '..',
      'public',
      'binance',
      url
    );
    result = await fs.promises.readFile(filePath, 'utf-8'); // Use promises to read the file
  } else {
    // Browser environment
    const response = await fetch(url);
    if (!response.body) {
      throw new Error('Response body is null or undefined');
    }
    const reader = (
      response.body as unknown as ReadableStream<Uint8Array>
    ).getReader();
    result = '';
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      result += new TextDecoder('utf-8').decode(value);
    }
  }

  // Now you can parse the JSON
  const json = JSON.parse(result);

  return json;
};

const COIN_SYMBOLS: Record<string, string> = {
  xrp: 'XRP',
  litecoin: 'LTC',
  ethereum: 'ETH',
  'bitcoin cash': 'BCH',
  monero: 'XMR',
  'ethereum classic': 'ETC',
  zcash: 'ZEC',
  dash: 'DASH',
  omisego: 'OMG',
  neo: 'NEO',
  verge: 'XVG',
  cardano: 'ADA',
  dogecoin: 'DOGE',
  decentraland: 'MANA',
  tron: 'TRX',
  cosmos: 'ATOM',
  uniswap: 'UNI',
  chainlink: 'LINK',
  aave: 'AAVE',
  tezos: 'XTZ',
  polkadot: 'DOT',
  compound: 'COMP',
  maker: 'MKR',
  polygon: 'MATIC',
  'synthetix network token': 'SNX',
  filecoin: 'FIL',
  stellar: 'XLM',
  eos: 'EOS',
  theta: 'THETA',
  algorand: 'ALGO',
  avalanche: 'AVAX',
  'binance coin': 'BNB',
  thorchain: 'RUNE',
  'hedera hashgraph': 'HBAR',
  'near protocol': 'NEAR',
  solana: 'SOL',
  stacks: 'STX',
  'injective protocol': 'INJ',
  'shiba inu': 'SHIB',
  'internet computer': 'ICP',
};

export const getTicketDataForCoin = async (
  coinInfo: BackTestCoin,
  tradeNotation: string,
  startDateTime: number,
  endDateTime: number
) => {
  const name = coinInfo.Coin.name.toLowerCase();
  if (typeof window !== 'undefined') {
    console.log('getting data for', name);
  }

  const coinFilePaths: Record<string, string> = Object.fromEntries(
    Object.entries(COIN_SYMBOLS).map(([coin, symbol]) => [
      coin,
      `../datasets/hourly/binance/${tradeNotation}/${symbol}${tradeNotation.toUpperCase()}_1h.json`,
    ])
  );

  let data: [];
  if (coinFilePaths[name]) {
    data = await processChunks(coinFilePaths[name]);
  } else {
    data = [];
  }

  return trimDataBasedOnDate(data, startDateTime, endDateTime);
};

export const isEveryCoinLengthSame = (
  coinDetails: Record<string, BackTestCoin>
) => {
  const coinDetailsArray = Object.values(coinDetails);
  const firstCoin = coinDetailsArray[0];
  const { tickers } = firstCoin;
  const tickerLength = tickers.length;
  const areLengthSame = coinDetailsArray.every((coin) => {
    if (typeof window !== 'undefined') {
      console.log('length for', coin.id, coin.tickers.length);
    }
    return coin.tickers.length === tickerLength;
  });
  return areLengthSame;
};

export const checkIfTimeStampAreIncrementingHourly = (data: any[]) => {
  let prevTimeStamp = 0;
  let allIncrementing = true; // Track overall incrementing status

  data.forEach((point, i) => {
    const t = point.unix ?? point['Unix Timestamp'];
    const correctTimeStamp = t.toString().length > 10 ? millisecToSec(t) : t;
    const difference = correctTimeStamp - prevTimeStamp;

    if (prevTimeStamp !== 0 && difference !== 3600) {
      console.log(
        `${point.symbol} ${correctTimeStamp} Timestamp is not incrementing hourly at index ${i}`
      );
      allIncrementing = false; // Set to false if any timestamp is not incrementing
    }

    prevTimeStamp = correctTimeStamp;
  });

  return allIncrementing;
};

const ticketDataCache: Record<string, BackTestCoin> = {};

// Get the last market period's start date
export const getCurrentCycleStartDateInSeconds = () => {
  const lastPeriod = marketPeriods[marketPeriods.length - 1];
  // Convert Date to seconds (Unix timestamp)
  return Math.floor(lastPeriod.startDate.getTime() / 1000);
};

export const findMarketPeriodStartDate = (startDateTime: number) => {
  const dateToCheck = new Date(startDateTime * 1000); // Convert Unix timestamp to Date

  const currentPeriod = marketPeriods.find(
    (period) => dateToCheck >= period.startDate && dateToCheck <= period.endDate
  );

  return currentPeriod
    ? Math.floor(currentPeriod.startDate.getTime() / 1000)
    : startDateTime;
};

export const fetchCoinDetails = async (
  COINS_TO_TEST: string[],
  AwsDB: AwsDatabase,
  tradeNotation: string,
  startDateTime: number,
  endDateTime: number
) => {
  const startTime = performance.now();

  const coins: Record<string, BackTestCoin> = {};
  for (const coin of COINS_TO_TEST) {
    if (ticketDataCache[coin]) {
      if (typeof window !== 'undefined') {
        console.log('👍 Getting cached data for', coin);
      }
      coins[coin] = ticketDataCache[coin];
    } else {
      const coinInfo = await AwsDB.getCoinDetailsWithBuysAndBoughts(
        `${coin}-${tradeNotation}`
      );
      const data = await getTicketDataForCoin(
        coinInfo,
        tradeNotation,
        startDateTime,
        endDateTime
      );

      const isIncrementing = checkIfTimeStampAreIncrementingHourly(data);
      if (!isIncrementing) {
        console.log(
          'Timestamps are not incrementing hourly for',
          coin.toUpperCase()
        );
      }
      const trimmedHighsAndLows = coinInfo.highsAndLows.filter(
        (point: { time: number }) =>
          point.time >= (getTickerData(data[0]).time as number)
      );
      const percentageStatistics =
        calculatePercentageStatistics(trimmedHighsAndLows);

      const startDate = findMarketPeriodStartDate(startDateTime);

      const highsAndLowsForThisCycle = coinInfo.highsAndLows.filter(
        (point: { time: number }) =>
          dateTimeInMillisecToSec(point.time) >= startDate &&
          dateTimeInMillisecToSec(point.time) <= endDateTime
      );

      const fibLevels = calculateFibLevels(
        coin,
        tradeNotation,
        highsAndLowsForThisCycle
      );

      coins[coin] = {
        ...coinInfo,
        tickers: data,
        percentageStatistics,
        highsAndLows: trimmedHighsAndLows,
        layeredBuyBids: [],
        layeredBuyBoughts: [],
        hasAlertTriggered: false,
        alertPrice: null,
        lowFromHighPrice: null,
        highPrice: null,
        linesInChart: {
          highPriceLine: undefined,
          lowPriceLine: undefined,
          alertPriceLine: undefined,
          breakEvenLine: undefined,
          buyLines: undefined,
          boughtLines: undefined,
        },
        piggyBankPositions: [],
        fibLevels,
      };

      // Cache the data
      ticketDataCache[coin] = coins[coin];
    }
  }

  const endTime = performance.now();
  const executionTime = millisecToSec(endTime - startTime);
  if (typeof window !== 'undefined') {
    console.log(
      `Execution time to prepare coin details: ${executionTime.toFixed(
        1
      )} seconds`
    );
  }

  return coins;
};

export const calculateGainLossPercentage = (
  balance: number,
  INITIAL_BALANCE: number,
  coinDetails: Record<string, BackTestCoin>
) => {
  const currentBalance = balance;
  const percentageChangeWithUnlocked =
    ((currentBalance - INITIAL_BALANCE) / INITIAL_BALANCE) * 100;

  // map over coinDetails and get the balance locked in trade
  const lockedBalance = Object.values(coinDetails).reduce((acc, coin) => {
    // add all the invested values for all boughts
    const total = calculateTotalBalance(
      coin.currentPrice,
      0,
      coin.layeredBuyBoughts
    );

    return acc + total;
  }, 0);

  const PercChangeWithLockedAlso =
    ((currentBalance + lockedBalance - INITIAL_BALANCE) / INITIAL_BALANCE) *
    100;

  return {
    percentageChangeWithUnlocked: Number.isNaN(percentageChangeWithUnlocked)
      ? 0
      : percentageChangeWithUnlocked,
    PercChangeWithLockedAlso,
    lockedBalance,
  };
};

export const getTradeStatistics = (
  coinDetails: Record<string, BackTestCoin>
) => {
  const totalBoughtTrades = Object.values(coinDetails).reduce(
    (acc, coin) => acc + coin.layeredBuyBoughts.length,
    0
  );

  const totalNotSoldTrades = Object.values(coinDetails).reduce((acc, coin) => {
    // filter out the trades that are not sold
    const notSoldTrades = coin.layeredBuyBoughts.filter(
      (trade) => trade.sellTime === undefined || trade.sellTime === null
    );
    return acc + notSoldTrades.length;
  }, 0);

  // calculate the % of sold trades
  const totalSoldTrades = totalBoughtTrades - totalNotSoldTrades;
  const percentageOfSoldTrades = (totalSoldTrades / totalBoughtTrades) * 100;
  return { percentageOfSoldTrades, totalSoldTrades, totalBoughtTrades };
};
