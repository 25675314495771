import React from 'react';
import { Cell } from 'react-table';
import { Icon } from 'semantic-ui-react';
import { LargeText } from './LargeText';

export type PricesProps = { satoshiPrice: number; usdPrice: number };

const btcAmountContainerStyle = {
  position: 'relative' as const,
  display: 'inline-block',
};

const usdTooltipStyle = {
  position: 'absolute' as const,
  top: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  padding: '4px 8px',
  borderRadius: '4px',
  fontSize: '0.8em',
  whiteSpace: 'nowrap' as const,
  zIndex: 1000,
  opacity: 0,
  transition: 'opacity 0.2s',
};

export type BalanceDisplayProps = {
  value: number;
  istradePairBtc: boolean;
  btcPrice: number;
  showUsdValue: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  localeStringOption: Intl.NumberFormatOptions;
};

export const BalanceDisplay: React.FC<BalanceDisplayProps> = ({
  value,
  istradePairBtc,
  btcPrice,
  showUsdValue,
  onMouseEnter,
  onMouseLeave,
  localeStringOption,
}) => (
  <LargeText>
    <div
      style={{
        ...btcAmountContainerStyle,
        cursor: istradePairBtc ? 'help' : 'default',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {value.toLocaleString(undefined, localeStringOption)}
      {istradePairBtc && btcPrice > 0 && (
        <div
          style={{
            ...usdTooltipStyle,
            opacity: showUsdValue ? 1 : 0,
          }}
        >
          <UsdPrice usdPrice={Number((value * btcPrice).toFixed(2))} />
        </div>
      )}
    </div>
  </LargeText>
);

export const UsdPrice: React.FC<{
  usdPrice: number;
}> = ({ usdPrice }) => (
  <>
    <Icon name="dollar sign" color="grey" size="small" />
    {usdPrice}
  </>
);

export const SatoshiPrice: React.FC<{ satoshiPrice: number }> = ({
  satoshiPrice,
}) => (
  <>
    <Icon name="btc" color="grey" size="small" />
    <i style={{ color: 'grey' }}>{satoshiPrice.toFixed(8)}</i>
  </>
);

export const Prices: React.FC<PricesProps> = ({ satoshiPrice, usdPrice }) => (
  <span style={{ display: 'inline-block', width: '100%' }}>
    <UsdPrice usdPrice={usdPrice} />
    <span className="floatRight">
      <SatoshiPrice satoshiPrice={satoshiPrice} />
    </span>
  </span>
);

export const Price: React.FC<{
  istradePairBtc: boolean;
  cell: Cell<{}, any>;
}> = ({ istradePairBtc, cell }) =>
  istradePairBtc ? (
    <SatoshiPrice satoshiPrice={cell.value} />
  ) : (
    <UsdPrice usdPrice={cell.value} />
  );
