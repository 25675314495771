import React from 'react';
import { Button, Container, Icon } from 'semantic-ui-react';
import { enable, setFetchMethod } from 'darkreader';
import useAuth from '../hooks/useAuth';
import PortfolioChart from '../layout/PortfolioChart';
import { usePortfolio } from '../hooks/usePortfolio';

setFetchMethod(window.fetch);
// enable({
//   darkSchemeBackgroundColor: '#000',
// });

export const LoginPage: React.FC = () => {
  const { isLoggedIn, signIn } = useAuth({ useAuto: false });
  const portfolio = usePortfolio();
  // Object.assign(document.body.style, {
  //   background: '#000',
  //   backgroundImage: 'url(../../bg-alternate2.png)',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'bottom center',
  // });

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = '/';
    }
  }, [isLoggedIn]);

  return (
    <>
      <div className="backdrop-bg" />
      <Container className="login-page-container">
        {portfolio && (
          <>
            <PortfolioChart data={portfolio} />
            <div className="login-container">
              <img
                src="../../getRichSlow-trans.png"
                alt="logo"
                width={300}
                height={300}
              />
              <Button color="google plus" onClick={signIn} size="massive">
                <Icon name="google" /> Login with google
              </Button>
            </div>
          </>
        )}
      </Container>
    </>
  );
};
