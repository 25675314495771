export const getCoinsWithPairAndOrdersForDashboard = `
query ListCoins(
    $filter: ModelCoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      rank
      symbol
        pairs{
          items{
            id
            notation
            alertPrice
            breakEvenStopLoss
            currentPrice
            higestPrice
            dontTrade
            relativeLow
            relativeHigh
            initialBalance
            reservedBalance
            highsAndLows {
              price
              time
              priceType
            }
            
            layeredBuyBids{
              items{
                id
                pairID
                buyPercent
                stoploss
                stoplossPrice
                buyOrderType
              }
            }
            
            layeredBuyBoughts{
              items{
                id
                pairID
                buyPercent
                invested
                profitSecured
                buyPrice
                buyColor
                buyTime
                realBuyOrderPlaced
                realBuyOrderStatus
                realSellOrderStatus
                sellTime
                stoploss
                stoplossPrice
                invested
                buyPrice
                buyOrderType
                buyOrderInfo{
                    id
                    symbol
                  }
              }
            }

            piggyBankPositions {
            items {
              totalCoins
              status
              sourceOrderId
              soldCoins
              pairID
              notation
              isRealPiggyBankTrade
              invested
              id
              entryPrice
              baseType
            }
          }

          }
        }
      }
      nextToken
    }
  }
`;

export const getPairInfoWithOrders = `
query GetPairInfo($id: ID!) {
  getPairInfo(id: $id) {
    id
    Coin {
      id
      name
      rank
      symbol
    }
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    layeredBuyBids {
      items{
        id
        buyPercent
        originalBuyPrice
        buyPrice
        invested
        stoplossPrice
        stoploss
        buyColor
        buyHit
        bidTime
        buyOrderType
      }
    }

    layeredBuyBoughts {
      items{
        id
        buyPercent
        originalBuyPrice
        buyPrice
        buyTime
        buyTweetId
        invested
        stoplossPrice
        stoploss
        buyColor
        buyOrderType
        buyOrderInfo{
          status
          datetime
          amount
          
        }
        profitSecured
        realSellOrderStatus
        realBuyOrderStatus
        realBuyOrderPlaced
        realSellOrderPlaced
        sellTime
        sellOrderInfo{
          status
          timestamp
        }
      }
    }

    piggyBankPositions {
      items {
        totalCoins
        status
        sourceOrderId
        soldCoins
        pairID
        notation
        isRealPiggyBankTrade
        invested
        id
        entryPrice
        baseType
      }
    }
      
  }
}`;

export const getPairInfoWithBuyOrders = `
query GetPairInfo($id: ID!) {
  getPairInfo(id: $id) {
    id
    alertPrice
    breakEvenStopLoss
    currentPrice
    higestPrice
    highsAndLows {
      price
      time
      priceType
    }
    initialBalance
    dontTrade
    relativeLow
    relativeHigh
    reservedBalance
    layeredBuyBids {
      items{
        id
        buyPercent
        originalBuyPrice
        buyPrice
        invested
        stoplossPrice
        stoploss
        buyColor
        buyHit
        bidTime
        buyOrderType
      }
    }
  }
}`;

export const listCoinsWithBuyOrders = `
  query ListCoins(
    $filter: ModelCoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        rank
        symbol
        pairs {
          items {
            id
            notation
            alertPrice
            breakEvenStopLoss
            currentPrice
            higestPrice
            dontTrade
            relativeLow
            relativeHigh
            initialBalance
            reservedBalance
            highsAndLows {
              price
              time
              priceType
            }
            layeredBuyBids {
              items {
                id
                pairID
                buyPercent
                buyColor
                bidTime
                buyOrderType
                originalBuyPrice
                highPrice
                buyPrice
                stoploss
                stoplossPrice
              }
            }

            layeredBuyBoughts(
              filter: {
                or: [
                  { sellTime: { attributeExists: false } },
                  { sellTime: { eq: null } },
                  {
                    and: [
                      { realBuyOrderPlaced: { eq: true } },
                      {
                        or: [
                          { realSellOrderStatus: { attributeExists: false } },
                          { realSellOrderStatus: { eq: null } }
                        ]
                      }
                    ]
                  }
                ]
              }
            ) {
              items {
                id
                pairID
                buyPercent
                invested
                profitSecured
                buyPrice
                buyColor
                highPrice
                profitMoved
                stoplossPrice
                buyOrderType
                buyTime
                realBuyOrderPlaced
                realBuyOrderStatus
                realSellOrderStatus
                sellTime
                stoploss
                invested
                buyPrice
                buyOrderInfo {
                  id
                  symbol
                  amount
                  status
                  datetime
                  price
                }
              }
            }
            piggyBankPositions {
              items {
                totalCoins
                status
                sourceOrderId
                soldCoins
                pairID
                notation
                isRealPiggyBankTrade
                invested
                id
                entryPrice
                baseType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listAlertsNotProessed = `
  query ListAlerts(
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        buyType
        buyOrBoughtId
        recheckCount
        status
        buyOrder {
          id
          buyPercent
          pairID
          stoplossPrice
          buyOrderType
          pairInfo {
            highsAndLows{
              price
              time
              priceType
            }
            notation
            relativeHigh
            Coin {
              id
              symbol
            }
          }
        }
        boughtOrder {
          id
          buyPercent
          pairID
          buyPrice
          stoplossPrice
          buyOrderType
          profitMoved
          profitSecured
          buyOrderType
          sellTime
          pairInfo {
            highsAndLows{
              price
              time
              priceType
            }
            notation
            relativeHigh
            Coin {
              id
              symbol
            }
          }
        }
      }
    }
  }
`;

export const getAlertWithOrderAndPairInfo = /* GraphQL */ `
  query GetAlerts($id: ID!) {
    getAlerts(id: $id) {
      id
      buyType
      buyOrBoughtId
      recheckCount
      status
      buyOrder {
        id
        buyPercent
        pairID
        stoplossPrice
        pairInfo {
          highsAndLows {
            price
            time
            priceType
          }
          notation
          relativeHigh
          Coin {
            id
            symbol
          }
        }
      }
      boughtOrder {
        id
        buyPercent
        pairID
        buyPrice
        buyTweetId
        sellTweetId
        stoplossPrice
        profitMoved
        profitSecured
        sellTime
        buyOrderType
        pairInfo {
          highsAndLows {
            price
            time
            priceType
          }
          notation
          relativeHigh
          Coin {
            id
            symbol
          }
        }
      }
    }
  }
`;
