import { MarketCycle } from '../API';
import { TradeNotations } from '../types';

// 10 coins
export const FIRST_BEAR_COINS_TO_TEST = [
  'xrp-xrp',
  'eth-ethereum',
  'bch-bitcoin-cash',
  'etc-ethereum-classic',
  'ada-cardano',
  'doge-dogecoin',
  'trx-tron',
  'link-chainlink',
  'xtz-tezos',
  'xlm-stellar',
];

// 4 coins
export const COINS_WITH_ONLY_SEC_BEAR_DATA = [
  'matic-polygon',
  'uni-uniswap',
  'aave-new',
  'dot-polkadot',
];

// 10 coins
const NEWLY_ADDED: string[] = [
  'theta-theta-token',
  'algo-algorand',
  'avax-avalanche',
  'hbar-hedera-hashgraph',
  'near-near-protocol',
  'sol-solana',
  'bnb-binance-coin',
  'stx-stacks',
  'inj-injective-protocol',
  'shib-shiba-inu',
];

// 12 coins
export const DELETED_FROM_BOT_COINS = [
  // with first bear data
  'ltc-litecoin',
  'zec-zcash',
  'dash-dash',
  'eos-eos',
  'mana-decentraland',
  'mkr-maker',
  'fil-filecoin',

  // with second bear data
  'atom-cosmos',
  'snx-synthetix-network-token',

  // newly added
  'rune-thorchain',
];

export const NOT_TESTING_COINS = [
  // 'xmr-monero',
  // 'icp-internet-computer',
];

export const ALL_COINS_TO_TEST = [
  ...FIRST_BEAR_COINS_TO_TEST,
  ...COINS_WITH_ONLY_SEC_BEAR_DATA,
  ...NEWLY_ADDED,
  ...DELETED_FROM_BOT_COINS,
];
// export const ALL_COINS_TO_TEST = ['stx-stacks'];

export const NOTATION: TradeNotations = 'usd';
export const MARKET_CYCLE: MarketCycle = MarketCycle.bear;
export const COLLECT_FREE_COINS = false;
export const INITIAL_BALANCE = (NOTATION as string) === 'btc' ? 2 : 200_000;
export const TIME_TO_TRADE: 'hour' | 'day' = 'hour';
export const SHOW_TABLE = false;
export const SHOW_CHART_AT_ONCE = false;
export const CHART_TO_SHOW =
  ALL_COINS_TO_TEST.length === 1 ? ALL_COINS_TO_TEST[0] : null;
// export const CHART_TO_SHOW = 'shib-shiba-inu';

export const LAYERS_TO_SKIP = 2;
export const FIRST_SELL_OFF_START_LAYER_TO_SKIP = 5;
export const ACC_START_LAYER_TO_SKIP = 0;

export const FIRST_BEAR_START_DATE = 1513209600;
export const FIRST_BEAR_END_DATE = 1593993600;
export const FIRST_BEAR_SELL_OFF_START_DATE = 1525305600;
export const FIRST_BEAR_ACCUMULATION_START_DATE = 1544832000;
// ------------------- 2018 FIRST BEAR MARKET -------------------
// | Timestamp    | Date and Time           | Event Description                                      |
// |--------------|-------------------------|--------------------------------------------------------|
// | 1513209600   | 2017-12-14 00:00:00     | Start of bear market                                   |
// | 1525305600   | 2018-05-03 00:00:00     | Violent market selloff starts                          |
// | 1544832000   | 2018-12-15 00:00:00     | Accumulation starts / First selloff ends/ BOTTOM is in |
// | 1593993600   | 2020-07-06 00:00:00     | Accumulation ends - full bear market                   |

export const SEC_BULL_STARTS = 1601816400; // Oct 04 2020 in seconds
export const SEC_BULL_MARKET_TOP = 1620777600; // 12th May 2021 in seconds

export const SEC_BEAR_START_DATE = 1620694800; // May 11 2021
export const SEC_BEAR_END_DATE = 1699488000; // Nov 09 2023
export const SEC_BEAR_SELL_OFF_START_DATE = 1648771200;
export const SEC_BEAR_ACCUMULATION_START_DATE = 1655596800;
// ------------------- 2021 SECOND BEAR MARKET -------------------
// | Timestamp    | Date and Time           | Event Description                                      |
// |--------------|-------------------------|--------------------------------------------------------|
// | 1620694800   | 2021-05-11 00:00:00     | Start of bear market                                   |
// | 1648771200   | 2022-04-01 00:00:00     | Violent market selloff starts                          |
// | 1655596800   | 2022-06-19 00:00:00     | Accumulation starts / First selloff ends               |
// | 1699488000   | 2023-11-09 00:00:00     | Accumulation ends with a bang! / BOTTOM is in          |
// ------------------- ------------------- -------------------
// | 1736982000   | 2025-01-15 00:00:00     | Final date until we have data                          |

type DateRangePeriod = {
  startDate: Date;
  endDate: Date;
  label: string;
};

export const marketPeriods: DateRangePeriod[] = [
  {
    startDate: new Date('2017-12-01'),
    endDate: new Date('2020-01-01'),
    label: 'Bull 2017 - Bear 2019',
  },
  {
    startDate: new Date('2021-02-01'),
    endDate: new Date('2024-01-00'),
    label: 'Bull 2021 - Bear 2024',
  },
  {
    startDate: new Date('2024-01-01'),
    endDate: new Date(),
    label: 'Bull 2024 - Now',
  },
];
