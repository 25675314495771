import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Balances, OrderBoughts, PairInfo } from '../../../API';
import { isRealBoughtOrder } from '../../../chart-specifics/coinCalculation';
import { Statistic, Icon } from 'semantic-ui-react';

type BalanceData = {
  name: string;
  value: number;
  invested: number;
};

interface BalancePieChartProps {
  notationBasedCoins: PairInfo[];
  balance: Balances;
  notation: string;
}

const generateColors = (count: number): string[] => {
  return Array.from({ length: count }, (_, i) => {
    const hue = (i * 137.508) % 360;
    return `hsl(${hue}, 85%, 60%)`;
  });
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  payload,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#E3CC8C"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${name} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const calculateTotalCoins = (coin: PairInfo): number => {
  const validOrders =
    coin.layeredBuyBoughts?.items?.filter((order): order is OrderBoughts =>
      Boolean(isRealBoughtOrder(order as unknown as OrderBoughts))
    ) ?? [];

  return validOrders.reduce(
    (acc, order) => acc + (order.invested || 0) / (order.buyPrice || 0),
    0
  );
};

export const hasPieChartData = (
  notationBasedCoins: PairInfo[] | null
): boolean => {
  if (!notationBasedCoins) return false;

  const chartData = notationBasedCoins
    .map((coin) => coin.currentPrice * calculateTotalCoins(coin))
    .filter((value) => value > 0);

  return chartData.length > 0;
};

const BalancePieChart: React.FC<BalancePieChartProps> = ({
  notationBasedCoins,
  balance,
  notation,
}) => {
  const prepareChartData = (): BalanceData[] => {
    if (!notationBasedCoins || !balance) return [];

    return notationBasedCoins
      .map((coin) => {
        const name = coin.id.split('-')[0].toUpperCase();
        const totalNumOfCoins = calculateTotalCoins(coin);
        const totalInvested =
          coin.layeredBuyBoughts?.items
            ?.filter((order): order is OrderBoughts =>
              Boolean(isRealBoughtOrder(order as unknown as OrderBoughts))
            )
            .reduce((acc, order) => acc + (order?.invested || 0), 0) ?? 0;

        return {
          name,
          value: coin.currentPrice * totalNumOfCoins,
          invested: totalInvested,
        };
      })
      .filter((item) => item.value > 0)
      .sort((a, b) => b.value - a.value);
  };

  const chartData = prepareChartData();
  const colors = generateColors(chartData.length);
  const decimals = notation === 'usd' ? 2 : 6;
  const currencySymbol = notation === 'usd' ? '$' : '₿';

  const totalInvested = chartData.reduce(
    (acc, item) => acc + (item.invested || 0),
    0
  );
  const totalCurrentValue = chartData.reduce(
    (acc, item) => acc + item.value,
    0
  );
  const profitLoss = totalCurrentValue - totalInvested;
  const profitLossPercentage = ((profitLoss / totalInvested) * 100).toFixed(2);

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            labelLine={{
              stroke: '#E3CC8C',
              strokeWidth: 2,
              strokeDasharray: '2 2',
            }}
            label={(props) => {
              const { name, percent } = props;
              return `${name} ${(percent * 100).toFixed(0)}%`;
            }}
            outerRadius="53%"
            fill="#8884d8"
            dataKey="value"
            paddingAngle={5}
            strokeWidth={0}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} stroke="none" />
            ))}
          </Pie>
          <Tooltip
            formatter={(value: number, name: string, props: any) => [
              `invested ${currencySymbol}${props.payload.invested.toFixed(
                decimals
              )} -> ${currencySymbol}${value.toFixed(decimals)}`,
              name,
            ]}
          />
        </PieChart>
      </ResponsiveContainer>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '0.5rem',
          marginTop: '0',
        }}
      >
        <Statistic size="mini">
          <Statistic.Value>
            {currencySymbol}
            {totalInvested.toFixed(decimals)}
          </Statistic.Value>
          <Statistic.Label style={{ color: 'rgba(228, 226, 223, 0.6)' }}>
            Invested
          </Statistic.Label>
        </Statistic>

        <Statistic size="mini">
          <Statistic.Value>
            {currencySymbol}
            {totalCurrentValue.toFixed(decimals)}
          </Statistic.Value>
          <Statistic.Label style={{ color: 'rgba(228, 226, 223, 0.6)' }}>
            Current
          </Statistic.Label>
        </Statistic>

        <Statistic size="mini">
          <Statistic.Value
            style={{ color: profitLoss >= 0 ? '#4CAF50' : '#FF5252' }}
          >
            <Icon name={profitLoss >= 0 ? 'arrow up' : 'arrow down'} />
            {currencySymbol}
            {Math.abs(profitLoss).toFixed(decimals)}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#E3CC8C' }}>
            {profitLossPercentage}%
          </Statistic.Label>
        </Statistic>
      </div>
    </div>
  );
};

export default BalancePieChart;
