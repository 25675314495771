/* eslint-disable no-param-reassign */
import {
  BarData,
  BarPrice,
  ChartOptions,
  CrosshairMode,
  DeepPartial,
  ISeriesApi,
  LineStyle,
  LineWidth,
  PriceFormat,
} from 'lightweight-charts';
import {
  AWSCoinInformation,
  MainChartProps,
  LayeredBoughtDetails,
  LayeredBuyBidDetails,
  TradeNotations,
  LinesInChart,
  FibLevels,
} from '../types';
import {
  calculateTotalBalance,
  FIB_ORDER_PLACEMENTS,
  getPercentageDiff,
} from '../utils/calculations/percentageAndprice';
import {
  createPriceLineInChart,
  setAlertPriceLine,
  setBreakEvenLine,
  setBuyBidsLineInChart,
  setHighPriceLine,
  setLowPriceLine,
  setMarkerInTime,
} from './linesAndMarkers';
import { BuyOrderType, HighLowPrice } from '../API';
import {
  DRAWDOWN_THRESHOLD_BUFFER,
  AVERAGE_CYCLE_DRAWDOWN,
  getFibLevelsForCurrentCycle,
} from '../utils/piggyBank';

export const chartLayoutStyle: DeepPartial<ChartOptions> = {
  layout: {
    backgroundColor: '#1D1F20',
    textColor: '#fff',
  },
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      color: '#1D1F20',
    },
  },
  crosshair: {
    vertLine: {
      width: 1,
      style: 2,
      visible: true,
      labelVisible: false,
    },
    horzLine: {
      width: 1,
      style: 2,
      visible: true,
      labelVisible: true,
    },
    mode: 0,
  },
};

export const chartLayoutStyleDark = (
  backgroundColor = '#000000'
): DeepPartial<ChartOptions> => ({
  layout: {
    backgroundColor,
    textColor: '#E3CC8C',
  },
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      visible: false,
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
    vertLine: {
      visible: false,
      labelVisible: false,
    },
    horzLine: {
      visible: false,
      labelVisible: false,
    },
  },
  timeScale: {
    borderVisible: false,
    rightOffset: 40,
  },
  rightPriceScale: {
    borderVisible: false,
    scaleMargins: {
      top: 0.2,
      bottom: 0.1,
    },
  },
});

export const timeScale = {
  // show time in x-axis
  timeVisible: true,
  secondsVisible: false,
  visible: true,
};

type OptionProp = {
  priceLineVisible: boolean;
  priceLineWidth: LineWidth;
  priceLineColor: string;
  priceLineStyle: LineStyle;
  priceFormat: PriceFormat;
};

export const chartSeriesOptions: OptionProp = {
  priceLineVisible: false,
  priceLineWidth: 1,
  priceLineColor: 'green',
  priceLineStyle: 4,
  priceFormat: {
    minMove: 0.00000001, // minimum move that is to be shown in the x-axis
    type: 'custom',
    formatter: (price: BarPrice) =>
      parseFloat(price as unknown as string).toFixed(8),
    // show 8 decimal places in the price
  },
};

const deletePriceLinesAndAddNewOnes = (
  chart: MainChartProps,
  priceLines: LinesInChart,
  currentPrice: number,
  relativeLow: number,
  relativeHigh: number,
  alertPrice: number,
  breakEvenStopLoss: boolean = false,
  layeredBuyBids: LayeredBuyBidDetails | undefined,
  layeredBuyBoughts: LayeredBoughtDetails | undefined,
  fibLevels?: FibLevels,
  chartData?: BarData[],
  showFibLevels: boolean = true
) => {
  // Remove existing fib lines if they exist
  if (priceLines.fibLines) {
    Object.values(priceLines.fibLines).forEach((line) => {
      if (line) {
        chart.mainCandlestickSeries.removePriceLine(line);
      }
    });
    priceLines.fibLines = {};
  }

  // Remove existing fib areas if they exist
  if (priceLines.fibAreas) {
    priceLines.fibAreas.forEach((areaSeries) => {
      chart.mainChart.removeSeries(areaSeries);
    });
    priceLines.fibAreas = [];
  }

  // Base price lines (always show these)
  setLowPriceLine(relativeLow, priceLines, chart.mainCandlestickSeries);
  setHighPriceLine(relativeHigh, priceLines, chart.mainCandlestickSeries);

  if (alertPrice) {
    const percentage = getPercentageDiff(alertPrice, relativeHigh);
    setAlertPriceLine(
      alertPrice,
      priceLines,
      percentage,
      chart.mainCandlestickSeries
    );
  }

  // Handle trade lines independently of fib levels
  if (layeredBuyBids && chart.mainCandlestickSeries) {
    setBuyBidsLineInChart(
      layeredBuyBids,
      relativeHigh,
      priceLines,
      chart.mainCandlestickSeries
    );
  }

  if (layeredBuyBoughts) {
    // calculate breakeven price and add a priceLine
    setBreakEvenLine(
      chart.mainCandlestickSeries,
      layeredBuyBoughts,
      currentPrice,
      breakEvenStopLoss,
      priceLines
    );

    layeredBuyBoughts.forEach((bought) => {
      if (bought.stoploss && !bought.sellTime) {
        const {
          stoplossPrice,
          buyColor,
          profitSecured,
          buyPercent,
          buyOrderType,
          fibLevel,
        } = bought;
        const stopLossLine = createPriceLineInChart(
          chart.mainCandlestickSeries,
          stoplossPrice!,
          buyColor,
          `${
            buyOrderType === BuyOrderType.piggyBankFibOrder
              ? fibLevel
              : buyPercent
          }% stoploss ${
            profitSecured && `${profitSecured.toFixed(1)}% profit`
          }`,
          1
        );

        if (stopLossLine) {
          if (priceLines.boughtLines) {
            priceLines.boughtLines[bought.id] = {
              stopLossLine,
            };
          }
        }
      }
    });
  }

  // Only add Fibonacci levels if showFibLevels is true
  if (fibLevels && chartData && showFibLevels) {
    priceLines = addFibonacciLevels(
      chart.mainCandlestickSeries,
      fibLevels,
      priceLines,
      chartData,
      chart
    );
  }

  return { chart, updatePriceLines: priceLines };
};

const getColorForLevel = (level: string) => {
  if (level === 'ATH') return '#4CAF50'; // Green for ATH
  if (level === 'ATL') return '#FF5252'; // Red for ATL

  return (
    FIB_ORDER_PLACEMENTS[level as keyof typeof FIB_ORDER_PLACEMENTS]?.color ||
    '#FFFFFF'
  );
};

const addTransparency = (color: string, opacity: number) => {
  // If it's a hex color
  if (color.startsWith('#')) {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  // If it's already rgb/rgba
  if (color.startsWith('rgb')) {
    return color.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
  }

  return color; // fallback
};

const addFibonacciLevels = (
  series: ISeriesApi<'Candlestick'>,
  fibLevels: FibLevels,
  priceLines: LinesInChart,
  chartData: BarData[],
  chart: MainChartProps
) => {
  // Clean up existing fib elements
  if (priceLines.fibLines) {
    Object.values(priceLines.fibLines).forEach((line) => {
      if (line) {
        series.removePriceLine(line);
      }
    });
  }
  priceLines.fibLines = {};

  if (priceLines.fibAreas) {
    priceLines.fibAreas.forEach((areaSeries) => {
      chart.mainChart.removeSeries(areaSeries);
    });
  }
  priceLines.fibAreas = [];

  // Sort levels by price to create areas between adjacent levels
  const sortedLevels = Object.entries(fibLevels)
    .filter(
      ([level, price]) =>
        typeof price === 'number' && level !== 'drawdownPercentage'
    )
    .sort(([, priceA], [, priceB]) => Number(priceB) - Number(priceA));

  // Get time range from chart data
  const startTime = chartData[0].time;
  const endTime = chartData[chartData.length - 1].time;

  // Calculate PiggyBank eligible zone
  const { ATH, ATL, drawdownPercentage } = fibLevels;
  const adjustedDrawdown = Math.max(
    (drawdownPercentage + AVERAGE_CYCLE_DRAWDOWN) / 2,
    AVERAGE_CYCLE_DRAWDOWN
  );

  // Calculate price at drawdown threshold
  const drawdownThresholdPrice =
    ATH * (1 - (adjustedDrawdown - DRAWDOWN_THRESHOLD_BUFFER) / 100);

  // Add PiggyBank eligible zone (red shaded area)
  const piggyBankAreaSeries = chart.mainChart.addAreaSeries({
    topColor: 'rgba(255, 0, 0, 0.1)', // Light red
    bottomColor: 'rgba(255, 0, 0, 0.2)', // Slightly darker red
    lineColor: 'transparent',
    priceLineVisible: false,
    lastValueVisible: false,
  });

  // Create data for the PiggyBank eligible area
  piggyBankAreaSeries.setData([
    { time: startTime, value: drawdownThresholdPrice },
    { time: endTime, value: drawdownThresholdPrice },
    { time: endTime, value: ATL },
    { time: startTime, value: ATL },
  ]);

  // Store reference to PiggyBank area series
  priceLines.fibAreas.push(piggyBankAreaSeries);

  // Add a dashed line for the drawdown threshold
  const thresholdLine = createPriceLineInChart(
    series,
    drawdownThresholdPrice,
    '#FF0000', // Red
    `PiggyBank Entry Zone (${(
      adjustedDrawdown - DRAWDOWN_THRESHOLD_BUFFER
    ).toFixed(1)}% drawdown)`,
    2, // Dashed line
    1
  );

  if (thresholdLine) {
    priceLines.fibLines!['piggyBankThreshold'] = thresholdLine;
  }

  // Add areas between levels
  for (let i = 0; i < sortedLevels.length - 1; i++) {
    const [currentLevel, currentPrice] = sortedLevels[i];
    const [, nextPrice] = sortedLevels[i + 1];

    const color = getColorForLevel(currentLevel);
    const areaColor = addTransparency(color, 0.2);

    // Create area series between levels
    const areaSeries = chart.mainChart.addAreaSeries({
      topColor: areaColor,
      bottomColor: 'transparent',
      lineColor: 'transparent',
      priceLineVisible: false,
      lastValueVisible: false,
    });

    // Store reference to area series for cleanup
    priceLines.fibAreas.push(areaSeries);

    // Create data for the area using actual time range
    areaSeries.setData([
      { time: startTime, value: currentPrice },
      { time: endTime, value: currentPrice },
      { time: endTime, value: nextPrice },
      { time: startTime, value: nextPrice },
    ]);

    // Add the price lines
    const line = createPriceLineInChart(
      series,
      currentPrice,
      color,
      `Fib ${currentLevel} (${Number(currentPrice).toFixed(8)})`,
      0,
      1
    );

    if (line) {
      priceLines.fibLines![currentLevel] = line;
    }
  }

  // Add the last level's line
  const [lastLevel, lastPrice] = sortedLevels[sortedLevels.length - 1];
  const lastColor = getColorForLevel(lastLevel);
  const lastLine = createPriceLineInChart(
    series,
    lastPrice,
    lastColor,
    `Fib ${lastLevel} (${Number(lastPrice).toFixed(8)})`,
    0,
    1
  );

  if (lastLine) {
    priceLines.fibLines![lastLevel] = lastLine;
  }

  return priceLines;
};

export const preparechart = (
  tradeNotation: TradeNotations,
  notation: 'btcPair' | 'usdPair',
  coinDetails: AWSCoinInformation,
  coinHistData: BarData[],
  priceLines: LinesInChart,
  chart?: MainChartProps,
  setPriceLines?: React.Dispatch<React.SetStateAction<LinesInChart>>,
  setMainChart?: React.Dispatch<
    React.SetStateAction<MainChartProps | undefined>
  >,
  showPnL?: boolean,
  showTrades = true,
  showFibLevels = true
) => {
  if (!chart || !coinHistData.length) return;

  const {
    currentPrice,
    relativeLow,
    relativeHigh,
    alertPrice,
    layeredBuyBids,
    layeredBuyBoughts,
    initialBalance,
    reservedBalance,
    highsAndLows,
    breakEvenStopLoss,
  } = coinDetails;

  chart.mainCandlestickSeries.setData(coinHistData);
  chart.mainChart.timeScale().scrollToPosition(30, false);

  const fibLevels = getFibLevelsForCurrentCycle(
    coinDetails.Coin.id,
    notation,
    highsAndLows as unknown as HighLowPrice[],
    coinHistData[coinHistData.length - 1].time as number
  );

  const updateChartAndPriceLines = deletePriceLinesAndAddNewOnes(
    chart,
    priceLines,
    currentPrice,
    relativeLow,
    relativeHigh,
    alertPrice,
    breakEvenStopLoss,
    layeredBuyBids.items,
    layeredBuyBoughts.items,
    fibLevels,
    coinHistData,
    showFibLevels
  );

  const runningProfitLoss = calculateTotalBalance(
    currentPrice,
    initialBalance,
    layeredBuyBoughts.items || []
  );

  const trimmedHighsAndLows = highsAndLows?.filter(
    (point) => point.time >= (coinHistData[0].time as number)
  );

  setMarkerInTime(
    chart.mainCandlestickSeries,
    tradeNotation,
    layeredBuyBoughts.items,
    initialBalance,
    runningProfitLoss,
    reservedBalance,
    trimmedHighsAndLows,
    coinHistData[coinHistData.length - 1].time as number,
    coinHistData[coinHistData.length - 1].close,
    showPnL,
    showTrades,
    showFibLevels ? fibLevels : undefined
  );

  if (setMainChart && setPriceLines) {
    setMainChart(updateChartAndPriceLines.chart);
    setPriceLines(updateChartAndPriceLines.updatePriceLines);
  }
};
