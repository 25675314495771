import { PiggyBankPosition } from './../API';
/* eslint-disable no-param-reassign */
import { Order } from 'ccxt';
import { ISeriesApi } from 'lightweight-charts';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {
  LinesInChart,
  DataProp,
  TradeNotations,
  Balances,
  LayeredBoughtDetails,
  LayeredBuyBid,
  LayeredBuyBidDetails,
  LayeredBought,
  PercentageStatistics,
  FibLevels,
} from '../types/index';
import {
  AlertStatus,
  CreateAlertsInput,
  OrderBoughts,
  HighLowPrice,
  NotificationType,
  UpdatePairInfoInput,
  BuyType,
  MarketCycle,
  BuyOrderType,
} from '../API';
import AwsDatabase from '../database/Database';
import {
  cancelSpecificOrder,
  checkOrderStatus,
  placeLimitBuyOrder,
  placeLimitSellOrder,
  placeMarketSellOrder,
} from '../server/automatedTrading';

import {
  getPercentageDiff,
  getPriceBelowPercentage,
  getPercentNeededForBuys,
  getProfitGenerated,
  getBEPriceForNotSoldCoins,
  getInvestmentAmount,
  getStoplossPriceAndPerc,
  setLowAndHighPrice,
  percentageDIffFromHighToLowPrice,
  getLayers,
  handlePiggyBankSplit,
  BoughtWithStoploss,
  calculateProfitSplit,
} from '../utils/calculations/percentageAndprice';

import { getRainbowColor, stripUndefinedValue } from '../utils/utils';
import {
  createPriceLineInChart,
  setHighPriceAndAlertLine,
  setLowPriceLine,
} from './linesAndMarkers';
import { pure, transformPairBoughtOrder } from '../utils/transformations';
import { callLambdaToPostTweet } from '../api/apiCalls';
import { dateTimeInMillisecToSec } from '../backtest-node/utils';
import { isPiggyBankEligible } from '../utils/piggyBank';
import { createFibLevelOrders } from '../utils/calculations/percentageAndprice';

const awsDB = new AwsDatabase();
const LAST_DB_UPDATE_MINUTE = 15 * 60000; // 60000 millisecond is 1 min
const STOPLOSS_ADJUSTMENT_THRESHOLD = 9;
const SELL_AT_A_LOSS = true;
let LAYERS_TO_SELL_AT_LOSS =
  process.env.REACT_APP_NODE_ENV !== 'development' ? 2 : 3;
const DAYS_TO_HOLD = 50;
const HOLD_DAYS_FOR_LOWER_LAYERS = 10;
let ACCEPTED_DOWN_PERCENT = -30;

// TODO WE NEED TO GET THE 40% CONST OUT SO WE CAN BACKTEST IT

export const getAcceptedDownPercent = () => ACCEPTED_DOWN_PERCENT;

export const setAcceptedDownPercent = (newPercent: number) => {
  ACCEPTED_DOWN_PERCENT = newPercent;
};

export const getLayersToSellAtLoss = () => LAYERS_TO_SELL_AT_LOSS;

export const setLayersToSellAtLoss = (newLayers: number) => {
  LAYERS_TO_SELL_AT_LOSS = newLayers;
};

const convertDaysToSeconds = (days: number) => days * 24 * 60 * 60;
export const convertSecondsToDays = (seconds: number) =>
  seconds / (24 * 60 * 60);

export const setRecentHighLowPriceAndLine = (
  candleClose: number,
  sanitizedData: DataProp,
  highPrice: number,
  lowFromHighPrice: number,
  layeredBuyBids: LayeredBuyBidDetails,
  alertTriggered: boolean,
  alertPrice: number | null,
  percentageStatistics: PercentageStatistics,
  highsAndLows: Omit<HighLowPrice, '__typename'>[],
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>
) => {
  let newHighPrice = highPrice || candleClose;
  let newLowFromHighPrice = lowFromHighPrice || sanitizedData.low;
  let previousLowPrice = newLowFromHighPrice;
  let isNewlyAddedHighPrice = false;
  // set new temporary low
  if (
    sanitizedData.low < highPrice &&
    sanitizedData.low < newLowFromHighPrice
  ) {
    newLowFromHighPrice = sanitizedData.low;
    if (candlestickSeries) {
      setLowPriceLine(newLowFromHighPrice, linesInChart, candlestickSeries);
    }
  }

  // check if the current price from lowest price is higher >= 40%
  const percentageDifference = getPercentageDiff(
    candleClose,
    newLowFromHighPrice
  );
  // const percentageDifference = 41;

  const highsAndLowsUntilNow = highsAndLows?.filter(
    (point) => point.priceType === 'low' && sanitizedData.time >= point.time
  );

  // check if the current price from prev lowest price is higher >= 40%
  const percDiffFromPrevLow = getPercentageDiff(
    candleClose,
    highsAndLowsUntilNow[highsAndLowsUntilNow.length - 1]?.price
  );

  if (
    candleClose > newLowFromHighPrice &&
    (percDiffFromPrevLow >= 40 || percentageDifference >= 40)
  ) {
    isNewlyAddedHighPrice = true;
  }

  // check if the current price from lowest price is higher >= 40%
  if (candleClose > newLowFromHighPrice && percentageDifference >= 40) {
    newHighPrice = candleClose;
    previousLowPrice = newLowFromHighPrice;
    newLowFromHighPrice = candleClose;

    const { newLayeredBuyBids, newAlertTriggered, newAlertPrice } =
      setHighPriceAndAlertLine(
        layeredBuyBids,
        alertTriggered,
        newHighPrice,
        percentageStatistics.averageAlertPercentage,
        linesInChart,
        candleClose,
        candlestickSeries
      );
    layeredBuyBids = newLayeredBuyBids;
    alertTriggered = newAlertTriggered;
    alertPrice = newAlertPrice;
  }

  // set new relative high price
  if (
    candleClose > newHighPrice ||
    (layeredBuyBids.length === 0 && !alertPrice)
  ) {
    if (candleClose > newHighPrice) {
      newHighPrice = candleClose;
    }

    const { newLayeredBuyBids, newAlertTriggered, newAlertPrice } =
      setHighPriceAndAlertLine(
        layeredBuyBids,
        alertTriggered,
        newHighPrice,
        percentageStatistics.averageAlertPercentage,
        linesInChart,
        candleClose,
        candlestickSeries
      );
    layeredBuyBids = newLayeredBuyBids;
    alertTriggered = newAlertTriggered;
    alertPrice = newAlertPrice;
  }

  return {
    newHighPrice,
    newLowFromHighPrice,
    newLayeredBuyBids: layeredBuyBids,
    newAlertTriggered: alertTriggered,
    newAlertPrice: alertPrice,
    previousLowPrice,
    isNewlyAddedHighPrice,
  };
};

export const checkAlertTriggered = (
  alertPrice: number | null,
  lowFromHighPrice: number,
  alertTriggered: boolean,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>
) => {
  if (!alertTriggered && alertPrice && lowFromHighPrice <= alertPrice) {
    if (candlestickSeries && linesInChart.alertPriceLine) {
      candlestickSeries.removePriceLine(linesInChart.alertPriceLine);
      linesInChart.alertPriceLine = undefined;
    }
    return true;
  }
  return false;
};

const getPercentForTradeFromTotalBalance = (
  notation: TradeNotations,
  totalBal: number
) => 2;
// let percToUsePerTrade = 2;

// if (notation === 'btc') {
//   if (totalBal > 0.5) percToUsePerTrade = 15;
//   if (totalBal > 1) percToUsePerTrade = 10;
//   if (totalBal > 2) percToUsePerTrade = 2;
// }

// if (notation === 'usd') {
//   if (totalBal > 2000) percToUsePerTrade = 15;
//   if (totalBal > 5000) percToUsePerTrade = 10;
//   if (totalBal > 10000) percToUsePerTrade = 2;
// }

// no matter how much balance we have, we need to have atleast 2% free
const checkIfEnoughBalForOneTrade = (
  notation: TradeNotations,
  balances: Balances,
  buyBoughts: LayeredBoughtDetails
) => {
  const totalBal = balances[notation].total;
  const freeBal = balances[notation].free;
  const percToUsePerTrade = getPercentForTradeFromTotalBalance(
    notation,
    totalBal
  );
  const freeBalReqdPerTrade = +((percToUsePerTrade / 100) * totalBal).toFixed(
    8
  );
  const alreadyInvestedBal = buyBoughts.reduce((acc, boughts) => {
    const invested =
      boughts?.realBuyOrderStatus === 'open' ||
      boughts?.realBuyOrderStatus === 'toBePlaced'
        ? boughts?.invested
        : 0;
    return acc + invested!;
  }, 0);

  if (freeBal + alreadyInvestedBal >= freeBalReqdPerTrade)
    return { isEnough: true, freeBalReqdPerTrade };

  return { isEnough: false, freeBalReqdPerTrade };
};

export const isRealBoughtOrder = (order: OrderBoughts) =>
  order.buyOrderInfo?.id &&
  order.realBuyOrderStatus !== 'canceled' &&
  order.realSellOrderStatus !== 'canceled' &&
  order.realSellOrderStatus !== 'closed';

export const hasRealTrades = (layeredBuyBoughts: OrderBoughts[]) =>
  layeredBuyBoughts.some(isRealBoughtOrder);

export const isInTrade = (layeredBuyBoughts: OrderBoughts[]) =>
  layeredBuyBoughts.filter((buys) => Boolean(buys.sellTime) === false);

export const getFinalTrade = (trades: OrderBoughts[]) =>
  trades.reduce(
    (acc, trade) =>
      trade.buyPercent > acc.buyPercent && trade.stoploss ? trade : acc,
    { buyPercent: 0 } as OrderBoughts
  );

// xrpbtc - donttrade true
// skipallBtcTrade - false
// skipAlltrades - false
export const shouldCoinBePaperTraded = (
  skipAlltrades: boolean,
  dontTrade: boolean | null | undefined,
  notation: string,
  skipAllBtctrades: boolean,
  skipAllUsdtrades: boolean
) => {
  let shouldPlacePaperOrder = false;
  if (notation === 'btc') shouldPlacePaperOrder = skipAllBtctrades;
  if (notation === 'usd') shouldPlacePaperOrder = skipAllUsdtrades;

  if (skipAlltrades) shouldPlacePaperOrder = true;
  if (dontTrade) shouldPlacePaperOrder = true;

  return shouldPlacePaperOrder;
};

export const areBuysInTradeAndHasStoploss = (
  layeredBuyBoughts: OrderBoughts[]
) => {
  let hasStopLossPrice = false;
  let finalTrade;

  const hasRealOrder = hasRealTrades(layeredBuyBoughts);
  const currentlyInTrade = isInTrade(layeredBuyBoughts);
  const isCurrentlyInTrade = Boolean(
    currentlyInTrade && currentlyInTrade?.length
  );

  if (isCurrentlyInTrade) {
    const buysWithStopLoss =
      layeredBuyBoughts &&
      layeredBuyBoughts.length &&
      layeredBuyBoughts.filter(
        (buys) => Boolean(buys.sellTime) === false && buys.stoploss
      );

    if (currentlyInTrade && buysWithStopLoss && buysWithStopLoss?.length) {
      hasStopLossPrice = true;
      finalTrade = getFinalTrade(currentlyInTrade);
    }
  }

  return {
    isCurrentlyInTrade,
    hasStopLossPrice,
    hasRealOrder,
    finalTrade,
  };
};

export const placeLayerdBuyBids = (
  coinID: string,
  notation: string,
  highPrice: number,
  higestPrice: number,
  layeredBuyBoughts: LayeredBoughtDetails,
  alertPercentage: number,
  noOfLayersToSkip: number,
  candleClose: number,
  linesInChart: LinesInChart,
  highsAndLows: Omit<HighLowPrice, '__typename'>[],
  collectFreeCoins: boolean,
  marketCycle: MarketCycle,
  fibLevels: FibLevels,
  candlestickSeries?: ISeriesApi<'Candlestick'>
): LayeredBuyBidDetails => {
  const layeredBuys: LayeredBuyBidDetails = [];
  let buyPercBelow = alertPercentage + 5 * (1 + noOfLayersToSkip);
  const colors = getRainbowColor();

  // Place regular buy orders
  for (let i = 0; i < 5; i += 1) {
    const priceToPlaceBuyOrder = getPriceBelowPercentage(
      highPrice,
      buyPercBelow
    );

    if (candleClose && priceToPlaceBuyOrder > candleClose) {
      buyPercBelow += 5;
      continue;
    }

    // check if already bought in same price and not sold already
    const sameBuyOrder = layeredBuyBoughts.filter(
      (bought) =>
        bought.buyPercent === buyPercBelow &&
        bought.buyPrice === priceToPlaceBuyOrder &&
        !bought.sellTime
    );

    const dateStamp = +new Date();
    const id = `${coinID}-${notation}-bids-${buyPercBelow}-${dateStamp}`;

    if (!sameBuyOrder.length) {
      if (candlestickSeries) {
        const priceLineReference = createPriceLineInChart(
          candlestickSeries,
          priceToPlaceBuyOrder,
          colors[i],
          `${buyPercBelow}% buy order`,
          0
        );

        linesInChart.buyLines = {
          ...linesInChart.buyLines,
          [id]: {
            buyLine: priceLineReference,
            stopLossLine: undefined,
          },
        };
      }

      const stoplossBuyPriceIfHit = +(
        priceToPlaceBuyOrder +
        (5 / 100) * priceToPlaceBuyOrder
      ).toFixed(8);

      // Check for piggyBank opportunity
      const currentDrawdown =
        fibLevels.ATH !== 0
          ? ((fibLevels.ATH - stoplossBuyPriceIfHit) / fibLevels.ATH) * 100
          : 0;

      // Check eligibility
      const shouldPlacePiggyBankBuy = isPiggyBankEligible(
        marketCycle,
        currentDrawdown,
        fibLevels,
        collectFreeCoins
      );
      const buy: LayeredBuyBid = {
        id,
        pairID: `${coinID}-${notation}`,
        buyPercent: buyPercBelow,
        originalBuyPrice: priceToPlaceBuyOrder,
        highPrice,
        buyOrderType: shouldPlacePiggyBankBuy
          ? BuyOrderType.piggyBankEligible
          : BuyOrderType.regular,
        bidTime: dateStamp,
        buyPrice: priceToPlaceBuyOrder,
        buyHit: false,
        buyColor: colors[i],
        stoploss: false,
      };

      layeredBuys.push(buy);
    }

    buyPercBelow += 5;
  }

  return layeredBuys;
};

export const checkBuyOrdersHit = (
  layeredBuyBids: LayeredBuyBidDetails,
  candleData: DataProp
): boolean => {
  if (!layeredBuyBids.length) return false;
  if (!layeredBuyBids.some((buys) => buys.buyPrice > candleData.low)) {
    return false;
  }
  return true;
};

const sendTradeNotification = (
  isRealTrade: boolean,
  id: string,
  coinID: string,
  notation: TradeNotations,
  buyOrder: LayeredBuyBid | LayeredBought,
  buyType: 'buy' | 'sell',
  candleData: DataProp,
  amountToInvest?: number,
  customMsg?: string
) => {
  const { buyPercent } = buyOrder;
  if (isRealTrade) {
    if (buyType === 'buy') {
      console.info(
        `REALORDER --> ${coinID} ${buyPercent}% will use ${amountToInvest} ${
          notation === 'btc' ? '₿' : '$'
        } ${customMsg ?? ''}`
      );
    }

    awsDB.addNewNotification(
      id,
      coinID,
      notation,
      NotificationType.warning,
      `💸 REAL - ${coinID} - ${buyPercent}% buy will be placed with 
      ${notation === 'btc' ? '₿' : '$'} ${amountToInvest}! ${customMsg ?? ''}`,
      false
    );
  }

  if (!isRealTrade) {
    awsDB.addNewNotification(
      id,
      coinID,
      notation,
      NotificationType.success,
      `🧻 Paper - ${coinID} - ${buyPercent}% ${buyType} is a success!  ${
        customMsg ?? ''
      }`,
      false
    );
  }
  if (process.env.REACT_APP_NODE_ENV !== 'development') {
    console.log('marking ', `Alert-${id}`, ' as complete');
    awsDB.markAlertAsComplete(`Alert-${id}`);
  }

  callLambdaToPostTweet({
    coinID,
    notation,
    buyType,
    candleData,
    order: buyOrder,
  });
};

const filterRemainingBuyBids = (
  remainingBuyBids: LayeredBuyBidDetails,
  layeredBuyBids: LayeredBuyBidDetails,
  notation: TradeNotations,
  paperTrade: boolean,
  initialBalance: number,
  remainingBalance: number,
  reservedBalance: number,
  alertPercentage: number,
  noOfLayersToSkip: number,
  isRealTrade: boolean,
  coinID: string,
  coinHighPrice: number,
  candleData: DataProp,
  buyBoughts: LayeredBoughtDetails,
  marketCycle: MarketCycle,
  customMsg?: string
) => {
  const realOrderProps = {
    buyOrderInfo: null,
    sellOrderInfo: null,
    realBuyOrderPlaced: false,
    realBuyOrderStatus: 'toBePlaced',
  };
  remainingBuyBids = layeredBuyBids.filter((buys) => {
    const {
      id,
      buyPercent,
      buyColor,
      buyHit,
      buyPrice,
      bidTime,
      stoplossPrice,
      originalBuyPrice,
      buyOrderType,
      highPrice,
    } = buys;
    if (buyHit) {
      if (!isRealTrade && initialBalance <= 0.2) {
        remainingBalance = initialBalance + reservedBalance;
        reservedBalance = 0;
      }

      const currentBuyPercent = stoplossPrice
        ? percentageDIffFromHighToLowPrice(highPrice, stoplossPrice)
        : buyPercent;

      const amountToInvest = getInvestmentAmount(
        remainingBalance,
        currentBuyPercent > buyPercent ? currentBuyPercent : buyPercent,
        alertPercentage,
        noOfLayersToSkip,
        marketCycle
      );

      const dateStamp = +new Date();
      const newBuyBought = {
        id: `${coinID}-${notation}-boughts-${buyPercent}-${dateStamp}`,
        pairID: `${coinID}-${notation}`,
        ...(isRealTrade && realOrderProps),
        highPrice: highPrice ?? coinHighPrice,
        buyPercent,
        // buyPercent:
        //   currentBuyPercent > buyPercent
        //     ? `${buyPercent} - ${currentBuyPercent.toFixed(0)}`
        //     : buyPercent,
        buyColor,
        bidTime,
        buyOrderType: buyOrderType || BuyOrderType.regular,
        buyPrice: stoplossPrice || buyPrice,
        originalBuyPrice: originalBuyPrice ?? buyPrice,
        buyTime: candleData.time,
        invested: amountToInvest,
      };

      sendTradeNotification(
        isRealTrade,
        id,
        coinID,
        notation,
        newBuyBought,
        'buy',
        candleData,
        amountToInvest,
        customMsg
      );

      const alreadyContainsOrder = buyBoughts.some((elem) =>
        isEqual(newBuyBought, elem)
      );

      if (amountToInvest > 0 && !alreadyContainsOrder) {
        buyBoughts.push(newBuyBought);
      }

      remainingBalance = +Math.abs(remainingBalance - amountToInvest).toFixed(
        4
      );
      return false;
    }

    return true;
  });

  return { remainingBuyBids, remainingBalance, reservedBalance };
};

export const deleteFromBidsAddToBought = (
  coinID: string,
  notation: TradeNotations,
  coinHighPrice: number,
  layeredBuyBids: LayeredBuyBidDetails,
  layeredBuyBoughts: LayeredBoughtDetails,
  candleData: DataProp,
  initialBalance: number,
  reservedBalance: number,
  percentageStatistics: PercentageStatistics,
  paperTrade: boolean,
  balances: Balances,
  noOfLayersToSkip: number,
  marketCycle: MarketCycle,
  customMsg?: string
) => {
  const buyBoughts: LayeredBoughtDetails = layeredBuyBoughts;
  let remainingBuyBids: LayeredBuyBidDetails = [];
  let isRealTrade: boolean = !paperTrade;
  let remainingBalance = paperTrade ? initialBalance : balances[notation].free;

  if (isRealTrade && balances[notation].free !== undefined) {
    const balForOneTrade = checkIfEnoughBalForOneTrade(
      notation,
      balances,
      buyBoughts
    );

    if (!balForOneTrade.isEnough) {
      isRealTrade = false;
    }

    remainingBalance = isRealTrade ? remainingBalance : initialBalance;
  }

  ({ remainingBuyBids, remainingBalance, reservedBalance } =
    filterRemainingBuyBids(
      remainingBuyBids,
      layeredBuyBids,
      notation,
      paperTrade,
      initialBalance,
      remainingBalance,
      reservedBalance,
      percentageStatistics.averageAlertPercentage,
      noOfLayersToSkip,
      isRealTrade,
      coinID,
      coinHighPrice,
      candleData,
      buyBoughts,
      marketCycle,
      customMsg
    ));

  return {
    buyBoughts,
    remainingBuyBids,
    remainingBalance,
    remReservedBalance: reservedBalance,
  };
};

export const checkIfInProfit = (
  coinID: string,
  candleData: DataProp,
  layeredBuyBoughts: LayeredBoughtDetails,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  marketCycle: MarketCycle
): boolean =>
  layeredBuyBoughts.some((buys) => {
    if (!buys) {
      return false;
    }

    const { buyPrice, stoploss, sellTime, buyPercent } = buys;

    // if stoploss already set or already sold, no need to check
    if (stoploss || sellTime) return false;
    const percIncreaseNeeded = getPercentNeededForBuys(
      buyPercent,
      percentageStatistics,
      layerSkipped,
      marketCycle
    );

    return candleData.close >= buyPrice + (percIncreaseNeeded / 100) * buyPrice;
  });

export const hasBreakEvenStoplossFromBuys = (
  layeredBuyBoughts: LayeredBoughtDetails,
  alertPercentage: number,
  hasBreakEvenStoploss: boolean,
  isNewHighPriceSet: boolean,
  candleData: DataProp,
  breakEvenPrice: number
): boolean => {
  const hasLastTwoBuyLayers = Boolean(
    layeredBuyBoughts.find(
      (layer) =>
        layer && !layer.sellTime && layer.buyPercent >= alertPercentage + 20
    )
  );

  if (!hasLastTwoBuyLayers) hasBreakEvenStoploss = false;

  if (
    isNewHighPriceSet &&
    candleData.close > breakEvenPrice &&
    hasLastTwoBuyLayers
  ) {
    hasBreakEvenStoploss = true;
  }
  return hasBreakEvenStoploss;
};

const handleBreakEvenStoploss = (
  buys: LayeredBought,
  breakEvenPrice: number,
  low: number,
  time: number,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>,
  fibLevels?: FibLevels
) => {
  const { id, sellTime, buyPrice, invested, buyOrderType } = buys;

  if (sellTime) return { buys, profitBalance: 0 };

  const doNotExecuteBEstoploss = true;
  if (doNotExecuteBEstoploss) return { buys, profitBalance: 0 };

  // Remove existing stopLoss line
  const stopLossLine = linesInChart.boughtLines?.[id]?.stopLossLine;
  if (candlestickSeries && stopLossLine) {
    candlestickSeries.removePriceLine(stopLossLine);
    linesInChart.boughtLines![id].stopLossLine = undefined;
  }

  const updatedBuys = {
    ...buys,
    sellTime: time,
    stoplossPrice: breakEvenPrice,
  };
  const profSecured = ((breakEvenPrice - buyPrice) / buyPrice) * 100;
  const profitBalance = getProfitGenerated(
    profSecured,
    invested!,
    0,
    buyOrderType || BuyOrderType.regular,
    buys.buyPrice,
    fibLevels
  );

  return { buys: updatedBuys, profitBalance };
};

const handleStopLossTriggered = (
  buys: LayeredBought,
  low: number,
  time: number,
  linesInChart: LinesInChart,
  coinID: string,
  notation: TradeNotations,
  candleData: DataProp,
  piggyBankPositions: PiggyBankPosition[],
  fibLevels: FibLevels,
  candlestickSeries?: ISeriesApi<'Candlestick'>,
  customMsg?: string
) => {
  const {
    id,
    sellTime,
    stoplossPrice,
    profitSecured,
    buyOrderInfo,
    realBuyOrderPlaced,
    invested,
    buyOrderType,
  } = buys;

  if (sellTime || !stoplossPrice || !profitSecured) {
    return { buys, profitBalance: 0, piggyBankPositions };
  }

  // Remove stopLoss line
  const stopLossLine = linesInChart.boughtLines?.[id]?.stopLossLine;
  if (candlestickSeries && stopLossLine) {
    candlestickSeries.removePriceLine(stopLossLine);
    linesInChart.boughtLines![id].stopLossLine = undefined;
  }

  const updatedBuys = { ...buys, sellTime: time };

  // Handle piggyBank position
  if (updatedBuys.buyOrderType === BuyOrderType.piggyBankEligible) {
    const piggyBankPosition = handlePiggyBankSplit(
      updatedBuys as BoughtWithStoploss,
      fibLevels
    );
    if (piggyBankPosition) {
      piggyBankPositions.push(piggyBankPosition);
    }
  }

  // Send notification for paper trades
  if (!buyOrderInfo && !realBuyOrderPlaced) {
    sendTradeNotification(
      false,
      id,
      coinID,
      notation,
      updatedBuys,
      'sell',
      candleData,
      undefined,
      customMsg
    );
  }

  const profitBalance = getProfitGenerated(
    profitSecured,
    invested!,
    0,
    buyOrderType || BuyOrderType.regular,
    buys.buyPrice,
    fibLevels
  );

  return { buys: updatedBuys, profitBalance, piggyBankPositions };
};

const handleProfitIncrease = (
  buys: LayeredBought,
  low: number,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  marketCycle: MarketCycle,
  linesInChart: LinesInChart,
  fibLevels: FibLevels,
  candlestickSeries?: ISeriesApi<'Candlestick'>
) => {
  const {
    id,
    buyPrice,
    buyPercent,
    profitSecured,
    profitMoved,
    buyColor,
    buyOrderType,
    fibLevel,
  } = buys;

  const { stopLossPrice, stopLossPerc, newProfitMoved } =
    getStoplossPriceAndPerc(
      low,
      buyPrice,
      buyPercent,
      profitSecured!,
      percentageStatistics,
      layerSkipped,
      marketCycle,
      fibLevel ? Number(fibLevel) : undefined,
      fibLevels,
      profitMoved,
      buyOrderType || BuyOrderType.regular
    );

  if (stopLossPerc < 5) return buys;

  // Update chart lines
  const stopLossLine = linesInChart.boughtLines?.[id]?.stopLossLine;
  if (candlestickSeries && stopLossLine) {
    candlestickSeries.removePriceLine(stopLossLine);
    linesInChart.boughtLines![id].stopLossLine = undefined;

    const priceLineReference = createPriceLineInChart(
      candlestickSeries,
      stopLossPrice,
      buyColor!,
      `${
        buyOrderType === BuyOrderType.piggyBankFibOrder ? fibLevel : buyPercent
      }% stoploss  ${stopLossPerc.toFixed(1)}% profit}`,
      1
    );

    if (linesInChart.boughtLines) {
      linesInChart.boughtLines[id].stopLossLine = priceLineReference;
    }
  }

  return {
    ...buys,
    stoplossPrice: stopLossPrice,
    profitSecured: stopLossPerc,
    profitMoved: newProfitMoved,
  };
};

const handleSellAtLoss = (
  buys: LayeredBought,
  time: number,
  low: number,
  fibLevels?: FibLevels
) => {
  const { stoplossPrice, sellTime, buyTime, buyColor, buyPrice, buyOrderType } =
    buys;

  if (
    !SELL_AT_A_LOSS ||
    stoplossPrice ||
    sellTime ||
    buyOrderType === BuyOrderType.piggyBankFibOrder
  )
    return { buys, profitBalance: 0 };

  const layerNumber = Number(getLayers(buyColor));
  if (layerNumber > LAYERS_TO_SELL_AT_LOSS) return { buys, profitBalance: 0 };

  const daysElapsedInSec =
    dateTimeInMillisecToSec(time) - dateTimeInMillisecToSec(buyTime);
  const isHoldingTooLong =
    daysElapsedInSec > convertDaysToSeconds(DAYS_TO_HOLD);
  const isPriceBelowBuy = low < buyPrice;

  let shouldSell = false;
  if (layerNumber <= 2) {
    shouldSell = isHoldingTooLong && isPriceBelowBuy;
  } else {
    const currentPercentageDrawdown = ((low - buyPrice) / buyPrice) * 100;
    const isMoreLossThanAcceptable =
      currentPercentageDrawdown < ACCEPTED_DOWN_PERCENT;
    shouldSell =
      (isHoldingTooLong && isPriceBelowBuy) ||
      (isMoreLossThanAcceptable &&
        daysElapsedInSec > convertDaysToSeconds(HOLD_DAYS_FOR_LOWER_LAYERS));
  }

  if (!shouldSell) return { buys, profitBalance: 0 };

  const stoplossPerc = ((low - buyPrice) / buyPrice) * 100;
  const updatedBuys = {
    ...buys,
    sellTime: time,
    stoplossPrice: low,
    profitSecured: stoplossPerc,
  };

  const profitBalance = getProfitGenerated(
    stoplossPerc,
    buys.invested!,
    0,
    buys.buyOrderType || BuyOrderType.regular,
    buys.buyPrice,
    fibLevels
  );

  return { buys: updatedBuys, profitBalance };
};

export const adjustStopLossSecureProfitAddLine = (
  coinID: string,
  notation: TradeNotations,
  layeredBuyBoughts: LayeredBoughtDetails,
  candleData: DataProp,
  initialBalance: number,
  percentageStatistics: PercentageStatistics,
  isNewHighPriceSet: boolean,
  hasBreakEvenStoploss: boolean,
  linesInChart: LinesInChart,
  layerSkipped: number,
  marketCycle: MarketCycle,
  piggyBankPositions: PiggyBankPosition[],
  fibLevels: FibLevels,
  candlestickSeries?: ISeriesApi<'Candlestick'>,
  customMsg?: string
) => {
  const { low, time } = candleData;
  let profitBalance = initialBalance;

  const breakEvenPrice = getBEPriceForNotSoldCoins(
    candleData.close,
    layeredBuyBoughts
  );
  hasBreakEvenStoploss = hasBreakEvenStoplossFromBuys(
    layeredBuyBoughts,
    percentageStatistics.averageAlertPercentage,
    hasBreakEvenStoploss,
    isNewHighPriceSet,
    candleData,
    breakEvenPrice
  );

  const newBuyBoughts = layeredBuyBoughts.map((buys) => {
    // Handle break even stoploss
    if (hasBreakEvenStoploss && breakEvenPrice >= low) {
      const { buys: updatedBuys, profitBalance: breakEvenProfit } =
        handleBreakEvenStoploss(
          buys,
          breakEvenPrice,
          low,
          time,
          linesInChart,
          candlestickSeries,
          fibLevels
        );
      profitBalance += breakEvenProfit;
      if (updatedBuys !== buys) return updatedBuys;
    }

    // Handle stoploss triggered
    if (buys.stoplossPrice && low <= buys.stoplossPrice) {
      const {
        buys: updatedBuys,
        profitBalance: stopLossProfit,
        piggyBankPositions: updatedPositions,
      } = handleStopLossTriggered(
        buys,
        low,
        time,
        linesInChart,
        coinID,
        notation,
        candleData,
        piggyBankPositions,
        fibLevels,
        candlestickSeries,
        customMsg
      );
      profitBalance += stopLossProfit;
      piggyBankPositions = updatedPositions;
      if (updatedBuys !== buys) return updatedBuys;
    }

    // Handle profit increase
    if (!buys.sellTime && buys.profitSecured) {
      return handleProfitIncrease(
        buys,
        low,
        percentageStatistics,
        layerSkipped,
        marketCycle,
        linesInChart,
        fibLevels,
        candlestickSeries
      );
    }

    // Handle sell at loss
    const { buys: updatedBuys, profitBalance: sellAtLossProfit } =
      handleSellAtLoss(buys, time, low, fibLevels);
    profitBalance += sellAtLossProfit;
    return updatedBuys;
  });

  return {
    newBuyBoughts,
    profitBalance,
    newHasBreakEvenStoploss: hasBreakEvenStoploss,
    newPiggyBankPositions: piggyBankPositions,
  };
};

export const getDynamicStoploss = (
  highPrice: number,
  close: number,
  buyPercent: number,
  bufferPercentage: number
) => {
  const fallPercent = ((highPrice - close) / highPrice) * 100;
  const percToCapture = fallPercent - buyPercent;
  const dynamicScalingFactor = Math.max(1 - (percToCapture * 2) / 100, 0);

  const adjustedBuffer = bufferPercentage * dynamicScalingFactor; // Use dynamic scaling factor
  const newStoplossPrice = +(close + adjustedBuffer * close).toFixed(8); // Adjust stoploss price lower
  return newStoplossPrice;
};

export const changeStoplossPriceAndLine = (
  stoplossPrice: number,
  close: number,
  linesInChart: LinesInChart,
  candlestickSeries: ISeriesApi<'Candlestick'> | undefined,
  buyColor: string,
  buyPercent: number,
  bid: LayeredBuyBid
) => {
  const diffFromStoplossToClose =
    ((stoplossPrice - close) / stoplossPrice) * 100;

  if (diffFromStoplossToClose >= STOPLOSS_ADJUSTMENT_THRESHOLD) {
    const bufferPercentage = STOPLOSS_ADJUSTMENT_THRESHOLD / 2 / 100; // Set a buffer % above the closing price
    const newStoplossPrice = +(close + bufferPercentage * close).toFixed(8);

    // ! This is the dynamic stoploss calculation maybe we can use this in future
    // let newStoplossPrice = getDynamicStoploss(
    //   bid.highPrice,
    //   close,
    //   buyPercent,
    //   bufferPercentage
    // ); // Adjust stoploss price lower
    // if (newStoplossPrice < close) {
    //   newStoplossPrice = simpleStoploss;
    // }

    const stopLossLine =
      linesInChart.buyLines && linesInChart.buyLines[bid.id].stopLossLine;
    if (candlestickSeries && stopLossLine && stopLossLine !== null) {
      candlestickSeries.removePriceLine(stopLossLine);
      linesInChart.buyLines![bid.id].stopLossLine = createPriceLineInChart(
        candlestickSeries,
        newStoplossPrice,
        buyColor,
        `${buyPercent}% stoploss`,
        1
      );
    }
    bid = {
      ...bid,
      stoplossPrice: newStoplossPrice,
    };
  }
  return bid;
};

export const adjustBuyStopLossAndLine = (
  layeredBuyBids: LayeredBuyBidDetails,
  sanitizedData: DataProp,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>
) =>
  layeredBuyBids.map((bid) => {
    const { id, buyPercent, stoplossPrice, buyColor, buyHit } = bid;
    const { close } = sanitizedData;

    if (buyHit || !stoplossPrice) return bid;

    if (close > stoplossPrice) {
      bid.buyHit = true;
      const stopLossLine =
        linesInChart.buyLines && linesInChart.buyLines[id].stopLossLine;
      if (candlestickSeries && stopLossLine) {
        candlestickSeries.removePriceLine(stopLossLine);
        linesInChart.buyLines![id].stopLossLine = undefined;
      }
      return bid;
    }

    bid = changeStoplossPriceAndLine(
      stoplossPrice,
      close,
      linesInChart,
      candlestickSeries,
      buyColor,
      buyPercent,
      bid
    );
    return bid;
  });

export const getTradeSymbol = (coinID: string, notation: string) =>
  `${coinID.split('-')[0]}/${notation}${
    notation === 'usd' ? 'T' : ''
  }`.toUpperCase();

export const placeOrCheckRealBuyOrders = async (
  coinID: string,
  notation: TradeNotations,
  layeredBuyBoughts: LayeredBoughtDetails,
  candleData: DataProp,
  paperTrade: boolean,
  customMsg?: string
) => {
  if (paperTrade) return layeredBuyBoughts;

  const symbol = getTradeSymbol(coinID, notation);

  return layeredBuyBoughts.map(async (buys) => {
    const {
      id,
      buyPercent,
      buyPrice,
      invested,
      buyOrderInfo: orderInfo,
      realBuyOrderPlaced,
      realBuyOrderStatus,
    } = buys;

    if (orderInfo && realBuyOrderPlaced && realBuyOrderStatus === 'open') {
      // check if full order is executed
      const orderStatus = (await checkOrderStatus(
        id,
        symbol,
        buyPercent,
        orderInfo?.id
      )) as Order;

      if (orderStatus.status) {
        if (orderStatus.status === 'closed') {
          awsDB.addNewNotification(
            id,
            coinID,
            notation,
            NotificationType.success,
            `💸 REAL - Order for ${coinID} - ${buyPercent}% is executed and a success!  ${
              customMsg ?? ''
            }`,
            false
          );
        }
        return { ...buys, realBuyOrderStatus: orderStatus.status };
      }
      console.error(
        `REALORDER --> checkOrderStatus for ${coinID} - ${buyPercent}% did not succeed checkOrderStatus: ${orderStatus}`
      );
    }

    if (!orderInfo && realBuyOrderStatus === 'toBePlaced') {
      const realOrderInfo = (await placeLimitBuyOrder(
        buyPercent,
        symbol,
        buyPrice < candleData.close ? buyPrice : candleData.close,
        invested!
      )) as Order;

      if (realOrderInfo.status) {
        if (realOrderInfo.status === 'closed') {
          awsDB.addNewNotification(
            id,
            coinID,
            notation,
            NotificationType.success,
            `💸 REAL - Order for ${coinID} - ${buyPercent}% is executed and a success! ${
              customMsg ?? ''
            }`,
            false
          );
        }
        console.info(
          `REALORDER --> Placing buy order for ${coinID} - ${buyPercent}% is a success!  ${
            customMsg ?? ''
          }`
        );
        const strippedOrderInfo = stripUndefinedValue(realOrderInfo);
        // TODO check if we need to change other values in buy obj from realOrderInfo
        return {
          ...buys,
          buyOrderInfo: strippedOrderInfo,
          realBuyOrderPlaced: true,
          buyPrice: realOrderInfo.price,
          realBuyOrderStatus: realOrderInfo.status,
        };
      }

      console.error(
        `REALORDER --> order placement for ${coinID} - ${buys.buyPercent}% did not succeed placeLimitBuyOrder  ${customMsg} : ${realOrderInfo}`
      );

      awsDB.addNewNotification(
        id,
        coinID,
        notation,
        NotificationType.error,
        `Error placing order for buy ${buyPercent} : ${symbol} --  ${
          customMsg ?? ''
        } -- trying to invest ${invested} -- ${realOrderInfo}`,
        false
      );

      if (
        (realOrderInfo as any).message.includes('failure: NOTIONAL') ||
        (realOrderInfo as any).message.includes('insufficient balance')
      ) {
        awsDB.addNewNotification(
          id,
          coinID,
          notation,
          NotificationType.warning,
          `marking ${buyPercent} : ${symbol} -- as Paper trade as we dont have enough balance - ${invested}`,
          false
        );

        return { ...buys, realBuyOrderStatus: null };
      }
    }

    return buys;
  });
};

const sendSellSuccessNotificationAndTweet = (
  id: string,
  coinID: string,
  notation: TradeNotations,
  buyPercent: number,
  profitSecured: number | null | undefined,
  customMsg: string | undefined,
  buys: LayeredBought,
  candleData: DataProp
) => {
  awsDB.addNewNotification(
    id,
    coinID,
    notation,
    NotificationType.success,
    `💸 REAL - Placing sell order for ${coinID} - ${buyPercent}% Executed with ${profitSecured?.toFixed(
      2
    )}% profit  ${customMsg ?? ''}`,
    false
  );
  callLambdaToPostTweet({
    coinID,
    notation,
    buyType: 'sell',
    candleData,
    order: buys,
  });
};

export const placeOrCheckRealSellOrders = async (
  coinID: string,
  notation: TradeNotations,
  layeredBuyBoughts: LayeredBoughtDetails,
  candleData: DataProp,
  fibLevels: FibLevels,
  customMsg?: string
) => {
  const symbol = getTradeSymbol(coinID, notation);

  return layeredBuyBoughts.map(async (buys) => {
    const {
      id,
      buyPercent,
      buyOrderInfo,
      sellOrderInfo,
      realBuyOrderPlaced: realOrderPlaced,
      realSellOrderStatus,
      sellTime,
      stoplossPrice,
      profitSecured,
      buyOrderType,
      invested,
      buyPrice,
    } = buys;

    if (!buyOrderInfo && !realOrderPlaced) return buys;

    if (
      sellTime &&
      stoplossPrice &&
      sellOrderInfo &&
      realOrderPlaced &&
      realSellOrderStatus &&
      realSellOrderStatus === 'open'
    ) {
      const orderStatus = (await checkOrderStatus(
        id,
        symbol,
        buyPercent,
        sellOrderInfo?.id
      )) as Order;

      if (orderStatus.status === 'open') {
        if (candleData.low < stoplossPrice) {
          const canceledDetail = (await cancelSpecificOrder(
            buyPercent,
            symbol,
            sellOrderInfo?.id
          )) as Order;
          if (
            canceledDetail &&
            canceledDetail.status === 'canceled' &&
            buyOrderInfo
          ) {
            console.info(
              `REALORDER --> cancelled order for ${coinID} - ${buyPercent}% with orderId ${
                sellOrderInfo?.id
              }  ${customMsg ?? ''}`
            );
            awsDB.addNewNotification(
              id,
              coinID,
              notation,
              NotificationType.warning,
              `Cancelled sell order for ${coinID} - ${buyPercent}%  ${
                customMsg ?? ''
              }`,
              false
            );
            const marketOrderDetail = (await placeMarketSellOrder(
              buyPercent,
              symbol,
              buyOrderInfo.amount
            )) as Order;
            if (marketOrderDetail && marketOrderDetail.status) {
              console.info(
                `REALORDER --> placing sell order for ${coinID} - ${buyPercent}% is a success!  ${
                  customMsg ?? ''
                }`
              );
              awsDB.addNewNotification(
                id,
                coinID,
                notation,
                NotificationType.success,
                `Placing sell order for ${coinID} - ${buyPercent}% is a success with ${profitSecured?.toFixed(
                  2
                )}% profit!  ${customMsg ?? ''}`,
                false
              );
              const strippedOrderInfo = stripUndefinedValue(marketOrderDetail);
              return {
                ...buys,
                sellOrderInfo: strippedOrderInfo,
                realSellOrderStatus: marketOrderDetail.status,
                stoplossPrice:
                  marketOrderDetail.average || marketOrderDetail.price,
                profitSecured:
                  marketOrderDetail.status === 'closed'
                    ? ((marketOrderDetail.average || marketOrderDetail.price) /
                        buys.buyPrice -
                        1) *
                      100
                    : buys.profitSecured,
              };
            }
          }
        }
        return { ...buys, realSellOrderStatus: orderStatus.status };
      }

      if (orderStatus.status === 'closed') {
        sendSellSuccessNotificationAndTweet(
          id,
          coinID,
          notation,
          buyPercent,
          profitSecured,
          customMsg,
          buys,
          candleData
        );

        return {
          ...buys,
          stoplossPrice: orderStatus.average || orderStatus.price,
          profitSecured:
            ((orderStatus.average || orderStatus.price) / buys.buyPrice - 1) *
            100,
          realSellOrderStatus: orderStatus.status,
        };
      }

      console.error(
        `REALORDER --> checkOrderStatus for ${coinID} - ${buyPercent}% did not succeed checkOrderStatus: ${orderStatus}  ${
          customMsg ?? ''
        }`
      );
    }

    if (sellTime && !realSellOrderStatus && !sellOrderInfo && buyOrderInfo) {
      let coinAmountToSell = buyOrderInfo?.amount;

      if (buyOrderType === BuyOrderType.piggyBankEligible) {
        const profit = profitSecured! * 0.01 * invested!;
        const buyDrawdown = Number(
          ((fibLevels.ATH - buyPrice) / fibLevels.ATH) * 100
        );
        const { piggyBankPerc } = calculateProfitSplit(
          buyDrawdown,
          fibLevels.drawdownPercentage
        );
        const reservedProfitAmount = profit * (piggyBankPerc / 100);
        const reservedCoinsForPiggy = reservedProfitAmount / stoplossPrice!;

        // Calculate coins to sell (total coins minus piggyBank portion)
        coinAmountToSell = (coinAmountToSell ?? 0) - reservedCoinsForPiggy;

        console.log(`
          🐷 PiggyBank Split for ${symbol}:
          Total Coins: ${buyOrderInfo?.amount}
          PiggyBank Reserved: ${reservedCoinsForPiggy.toFixed(8)} coins (${(
          reservedCoinsForPiggy * stoplossPrice!
        ).toFixed(2)} USD)
          Main Sell: ${coinAmountToSell.toFixed(8)} coins (${(
          coinAmountToSell * stoplossPrice!
        ).toFixed(2)} USD)
          Split Percentage: ${piggyBankPerc}%
        `);
      }

      const realOrderInfo = (await placeLimitSellOrder(
        symbol,
        candleData.low,
        coinAmountToSell
      )) as Order;

      if (realOrderInfo?.status) {
        try {
          console.log('marking ', `Alert-${id}`, ' as complete');
          await awsDB.markAlertAsComplete(`Alert-${id}`);
        } catch (error) {
          console.error(
            `Failed to mark alert complete for Alert-${id}:`,
            error
          );
        }

        if (realOrderInfo.status === 'closed') {
          sendSellSuccessNotificationAndTweet(
            id,
            coinID,
            notation,
            buyPercent,
            profitSecured,
            customMsg,
            buys,
            candleData
          );
        }

        const strippedOrderInfo = stripUndefinedValue(realOrderInfo);

        return {
          ...buys,
          sellOrderInfo: strippedOrderInfo,
          realSellOrderPlaced: true,
          stoplossPrice: realOrderInfo.price,
          realSellOrderStatus: realOrderInfo.status,
        };
      }

      // if we are here that means we have an error

      const errorMessage = (realOrderInfo as any).message;
      const ERROR_MESSAGES = {
        NOTIONAL:
          ' -- available coin is not enough (NOTIONAL) to place order marking this trade as sold and convert to BNB manually!',
        INSUFFICIENT:
          ' -- insufficient balance to place order, please check your account balance and try again. Marking this trade as sold',
      } as const;

      const notSolvableErrors =
        errorMessage.includes('failure: NOTIONAL') ||
        errorMessage.includes('insufficient balance');

      customMsg = errorMessage.includes('failure: NOTIONAL')
        ? ERROR_MESSAGES.NOTIONAL
        : errorMessage.includes('insufficient balance')
        ? ERROR_MESSAGES.INSUFFICIENT
        : customMsg;

      console.error(
        `REALORDER --> order placement for ${id} - ${
          buys.buyPercent
        }% did not succeed placeLimitSellOrder: ${realOrderInfo} ${
          customMsg ?? ''
        }`
      );

      awsDB.addNewNotification(
        id,
        coinID,
        notation,
        NotificationType.error,
        `REALORDER --> Error placing order for sell ${buyPercent}% : ${symbol} -- buyOrderInfo amount ${
          buyOrderInfo?.amount
        } ${realOrderInfo}  ${customMsg ?? ''}`,
        false
      );

      if (notSolvableErrors) {
        return { ...buys, realSellOrderStatus: 'closed' };
      }
    }

    return buys;
  });
};

export const placeFakeOrder = async (
  coinID: string,
  notation: TradeNotations,
  customMsg?: string
) => {
  const symbol = getTradeSymbol(coinID, notation);
  const realOrderInfo = (await placeLimitBuyOrder(
    60,
    symbol,
    7.33,
    15
  )) as Order;

  if (realOrderInfo.status) {
    awsDB.addNewNotification(
      coinID,
      coinID,
      notation,
      NotificationType.success,
      `Placing buy order for ${coinID} is a success!  ${customMsg} --> orderInfo.timestamp = ${
        realOrderInfo.timestamp
      }  server.timestamp = ${new Date().getTime()}`,
      false
    );

    return;
  }

  awsDB.addNewNotification(
    symbol + new Date().getTime(),
    coinID,
    notation,
    NotificationType.error,
    `Error placing order for buy : ${symbol}  ${customMsg} --> error = ${realOrderInfo}  server.timestamp = ${new Date().getTime()}`,
    false
  );
};

export type MakeEachCandleTickChecksProps = {
  coinID: string;
  notation: TradeNotations;
  sanitizedData: DataProp;
  highPrice: number;
  lowFromHighPrice: number;
  layeredBuyBids: LayeredBuyBidDetails;
  layeredBuyBoughts: LayeredBoughtDetails;
  initialBalance: number;
  reservedBalance: number;
  alertPrice: number | null;
  alertTriggered: boolean;
  percentageStatistics: PercentageStatistics;
  hasBreakEvenStoploss: boolean;
  saveInDB: Boolean;
  higestPrice: number; // ATH price
  highsAndLows: Omit<HighLowPrice, '__typename'>[];
  paperTrade: boolean;
  balances: Balances;
  noOfLayersToSkip: number;
  timeSinceLastUpdate: number;
  linesInChart: LinesInChart;
  marketCycle: MarketCycle;
  collectFreeCoins: boolean;
  piggyBankPositions: PiggyBankPosition[];
  fibLevels: FibLevels;
  candlestickSeries?: ISeriesApi<'Candlestick'>;
};

const saveUpdatedbuyBids = async (
  originalBuyBids: LayeredBuyBidDetails,
  layeredBuyBids: LayeredBuyBidDetails,
  coinID: string,
  notation: TradeNotations
) => {
  if (isEqual(originalBuyBids, layeredBuyBids)) {
    return;
  }

  if (originalBuyBids.length === 0) {
    const bidsPromises = layeredBuyBids.map(async (bids) =>
      awsDB.createBidOrder({
        coinID,
        notation,
        ...bids,
      })
    );

    await Promise.all(bidsPromises);
    console.log('all new bids added for ', coinID, notation);
    return;
  }

  // if original has bids then know if any bids are deleted or updated
  const promisedBids = originalBuyBids.map(async (orgBid) => {
    const bid = layeredBuyBids.find((updBid) => orgBid.id === updBid.id);

    if (!bid) {
      await awsDB.deleteCoinBidOrder(orgBid.id);
      console.log('deleted the orderBid ', orgBid.id);
      return;
    }

    // check if any values are changed
    if (!isEqual(bid, orgBid)) {
      await awsDB.updateCoinBidOrder(bid);
      console.log('updated the orderBid ', bid.id);
    }
  });

  await Promise.all(promisedBids);
};

const saveBoughtsInDB = async (
  orgBuyBoughts: LayeredBoughtDetails,
  layeredBuyBoughts: LayeredBoughtDetails,
  coinID: string,
  notation: string
) => {
  if (isEqual(orgBuyBoughts, layeredBuyBoughts)) {
    return;
  }

  const promisedBoughts = layeredBuyBoughts.map(async (updBought) => {
    const boughtInOrg = orgBuyBoughts.find(
      (orgBought) => orgBought.id === updBought.id
    );

    if (!boughtInOrg) {
      const data = pure(
        transformPairBoughtOrder(
          updBought,
          coinID,
          notation,
          updBought.highPrice
        )
      ) as OrderBoughts;
      // return;
      await awsDB.createBoughtOrderTransformed(data);
      console.log('bought added for ', updBought.id);
    } else {
      // check if any values are changed
      if (!isEqual(updBought, boughtInOrg)) {
        const data = transformPairBoughtOrder(
          updBought,
          coinID,
          notation,
          boughtInOrg.highPrice
        );

        await awsDB.updateCoinBoughtOrder({ ...data, id: updBought.id });
        console.log(
          'updated the orderBought ',
          updBought.id,
          data.buyPercent,
          data.stoplossPrice,
          data?.profitSecured?.toFixed(3)
        );
      }
    }
  });

  await Promise.all(promisedBoughts);
};

// TODO use generics for the types
export const difference = (origObj: any, newObj: any) => {
  const changes = (newerObj: any, orgObj: any) => {
    let arrayIndexCounter = 0;
    return transform(newerObj, (result: any, value, key) => {
      if (!isEqual(value, orgObj[key])) {
        const resultKey = isArray(orgObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(orgObj[key])
            ? changes(value, orgObj[key])
            : value;
      }
    });
  };

  return changes(newObj, origObj);
};

const printDifferencesToConsole = (org: any, changed: any): void => {
  const diff = difference(org, changed);
  const changedKeys = Object.keys(diff);
  const message: string[] = [];
  changedKeys.forEach((key) => {
    if (key === 'highsAndLows') {
      message.push('highsAndLows');
      return;
    }
    message.push(`${key} -> ${org[key]} to ${changed[key]}`);
  });

  console.log('changed  --> ', message.toString());
};

const savePairInfoToDB = async (
  notation: string,
  coinID: string,
  highPrice: number,
  lowFromHighPrice: number,
  sanitizedData: DataProp,
  higestPrice: number,
  newHighsAndLows: Omit<HighLowPrice, '__typename'>[],
  alertPrice: number | null,
  initialBalance: number,
  reservedBalance: number,
  originalPairInfo: UpdatePairInfoInput,
  timeSinceLastUpdate: number
) => {
  const fakebalance = notation.toLocaleLowerCase() === 'btc' ? 0.2 : 2000;
  const newPairInfo = {
    id: `${coinID}-${notation}`,
    relativeHigh: highPrice,
    relativeLow: lowFromHighPrice,
    higestPrice: highPrice > higestPrice ? highPrice : higestPrice,
    highsAndLows: newHighsAndLows,
    alertPrice,
    // alertPercentage // TODO add for performance optimization
    initialBalance: initialBalance || fakebalance,
    reservedBalance: reservedBalance || fakebalance / 2,
  };

  if (
    !isEqual(newPairInfo, originalPairInfo) ||
    timeSinceLastUpdate >= LAST_DB_UPDATE_MINUTE
  ) {
    if (
      !isEqual(newPairInfo, originalPairInfo) &&
      timeSinceLastUpdate < LAST_DB_UPDATE_MINUTE
    ) {
      console.log(`--> updated ${newPairInfo.id} because`);
      printDifferencesToConsole(originalPairInfo, newPairInfo);
    }

    await awsDB.updatePairInfoPrices({
      ...newPairInfo,
      currentPrice: sanitizedData.close,
    });
    // console.log(
    //   `saved new pairInfo for ${coinID}-${notation}: ${sanitizedData.close}`
    // );
  } else {
    // console.log(
    //   `will not save info for ${coinID}-${notation} as nothing changed!`
    // );
  }
};

const savePiggyBankPositionsToDB = async (
  orgPiggyBankPositions: PiggyBankPosition[],
  piggyBankPositions: PiggyBankPosition[],
  coinID: string,
  notation: TradeNotations
) => {
  if (isEqual(orgPiggyBankPositions, piggyBankPositions)) {
    return;
  }

  // If no original positions, create all new ones
  if (orgPiggyBankPositions.length === 0) {
    const positionsPromises = piggyBankPositions.map(async (position) =>
      awsDB.createPiggyBankPosition({
        coinID,
        notation,
        piggyBankPosition: position,
      })
    );

    await Promise.all(positionsPromises);
    console.log('all new piggyBank positions added for ', coinID, notation);
    return;
  }

  // Handle updates and deletions
  const promisedPositions = orgPiggyBankPositions.map(async (orgPosition) => {
    const position = piggyBankPositions.find(
      (updPosition) => orgPosition.id === updPosition.id
    );

    if (!position) {
      await awsDB.deletePiggyBankPosition(orgPosition.id);
      console.log('deleted the piggyBank position ', orgPosition.id);
      return;
    }

    // Check if any values are changed
    if (!isEqual(position, orgPosition)) {
      await awsDB.updatePiggyBankPosition(position);
      console.log('updated the piggyBank position ', position.id);
    }
  });

  // Handle new positions that weren't in original
  const newPositions = piggyBankPositions.filter(
    (position) => !orgPiggyBankPositions.find((org) => org.id === position.id)
  );

  if (newPositions.length > 0) {
    const newPositionsPromises = newPositions.map(async (position) =>
      awsDB.createPiggyBankPosition({
        coinID,
        notation,
        piggyBankPosition: position,
      })
    );
    await Promise.all(newPositionsPromises);
    console.log('added new piggyBank positions for ', coinID, notation);
  }

  await Promise.all(promisedPositions);
};

export const saveToAWS = async (
  orgBuyBids: LayeredBuyBidDetails,
  layeredBuyBids: LayeredBuyBidDetails,
  coinID: string,
  notation: TradeNotations,
  orgBuyBoughts: LayeredBoughtDetails,
  layeredBuyBoughts: LayeredBoughtDetails,
  highsAndLows: Omit<HighLowPrice, '__typename'>[],
  highPrice: number,
  previousLowPrice: number,
  sanitizedData: DataProp,
  lowFromHighPrice: number,
  higestPrice: number,
  alertPrice: number | null,
  initialBalance: number,
  reservedBalance: number,
  originalPairInfo: UpdatePairInfoInput,
  orgPiggyBankPositions: PiggyBankPosition[],
  piggyBankPositions: PiggyBankPosition[],
  timeSinceLastUpdate: number
) => {
  const PromiseBuysSaved = saveUpdatedbuyBids(
    orgBuyBids,
    layeredBuyBids,
    coinID,
    notation
  );
  const PromisedBoughtsSaved = saveBoughtsInDB(
    orgBuyBoughts,
    layeredBuyBoughts,
    coinID,
    notation
  );
  const newHighsAndLows = setLowAndHighPrice(
    highsAndLows,
    highPrice,
    previousLowPrice,
    sanitizedData.time
  );
  const PromisePairInfoSaved = savePairInfoToDB(
    notation,
    coinID,
    highPrice,
    lowFromHighPrice,
    sanitizedData,
    higestPrice,
    newHighsAndLows,
    alertPrice,
    initialBalance,
    reservedBalance,
    originalPairInfo,
    timeSinceLastUpdate
  );

  const PromisePiggyBankPositionsSaved = savePiggyBankPositionsToDB(
    orgPiggyBankPositions,
    piggyBankPositions,
    coinID,
    notation
  );

  await Promise.all([
    PromiseBuysSaved,
    PromisedBoughtsSaved,
    PromisePairInfoSaved,
    PromisePiggyBankPositionsSaved,
  ]);
};

export const createAlertsForNewOrder = async (
  pairID: string,
  buyType: BuyType,
  buyOrBoughtOrderId: string
) => {
  const alertData: CreateAlertsInput = {
    id: `Alert-${buyOrBoughtOrderId}`,
    buyType,
    buyOrBoughtId: buyOrBoughtOrderId,
    status: AlertStatus.new,
    ...(buyType === 'buy' && { alertsBuyOrderId: buyOrBoughtOrderId }),
    ...(buyType === 'sell' && { alertsBoughtOrderId: buyOrBoughtOrderId }),
  };

  await awsDB.createAlert(alertData);
};

export const removeLineForBuysSetBuyStoploss = (
  layeredBuyBids: LayeredBuyBidDetails,
  candleData: DataProp,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>
): LayeredBuyBidDetails =>
  layeredBuyBids.map((buys) => {
    const { id, buyPrice, buyColor, buyPercent, stoploss } = buys;

    if (!stoploss && buyPrice > candleData.low) {
      const stoplossPrice = +(buyPrice + (5 / 100) * buyPrice).toFixed(8);

      if (
        candlestickSeries &&
        linesInChart?.buyLines &&
        linesInChart.buyLines[id].buyLine
      ) {
        candlestickSeries.removePriceLine(linesInChart.buyLines[id].buyLine!);
        linesInChart.buyLines[id].stopLossLine = createPriceLineInChart(
          candlestickSeries,
          stoplossPrice,
          buyColor!,
          `${buyPercent}% stoploss buy order placed`,
          1
        );
      }

      createAlertsForNewOrder(buys.pairID, BuyType.buy, id);

      buys.stoploss = true;
      buys.stoplossPrice = stoplossPrice;
    }
    return buys;
  });

export const setStopLossForProfitBoughts = (
  layeredBuyBoughts: LayeredBoughtDetails,
  closePrice: number,
  percentageStatistics: PercentageStatistics,
  linesInChart: LinesInChart,
  layerSkipped: number,
  marketCycle: MarketCycle,
  fibLevels: FibLevels,
  candlestickSeries?: ISeriesApi<'Candlestick'>
) =>
  layeredBuyBoughts.map((buys) => {
    const {
      id,
      buyPrice,
      buyColor,
      stoploss,
      buyPercent,
      profitMoved,
      sellTime,
      buyOrderType,
      fibLevel,
    } = buys;
    let stoplossLine;

    if (sellTime || stoploss) return buys;

    const { stopLossPrice, stopLossPerc } = getStoplossPriceAndPerc(
      closePrice,
      buyPrice,
      buyPercent,
      0,
      percentageStatistics,
      layerSkipped,
      marketCycle,
      Number(fibLevel) ?? undefined,
      fibLevels,
      profitMoved,
      buyOrderType || BuyOrderType.regular
    );

    if (stopLossPrice === buyPrice) return buys;

    // set new line for stoploss
    if (candlestickSeries) {
      stoplossLine = createPriceLineInChart(
        candlestickSeries,
        stopLossPrice,
        buyColor!,
        `${
          buyOrderType === BuyOrderType.piggyBankFibOrder
            ? fibLevel
            : buyPercent
        }% stoploss ${stopLossPerc.toFixed(1)}% profit}`,
        1
      );

      linesInChart.boughtLines = {
        ...linesInChart.boughtLines,
        [id]: {
          stopLossLine: stoplossLine,
        },
      };
    }

    createAlertsForNewOrder(buys.pairID, BuyType.sell, buys.id);

    // add stoploss to its array
    buys = {
      ...buys,
      stoploss: true,
      stoplossPrice: +stopLossPrice.toFixed(8),
      profitSecured: stopLossPerc,
      profitMoved: 0,
    };

    // mark the buy as stoploss added
    return buys;
  });

export const processBuyBids = async (
  layeredBuyBoughts: LayeredBoughtDetails,
  layeredBuyBids: LayeredBuyBidDetails,
  initialBalance: number,
  reservedBalance: number,
  coinID: string,
  notation: TradeNotations,
  highPrice: number,
  sanitizedData: DataProp,
  percentageStatistics: PercentageStatistics,
  shouldPlacePaperOrder: boolean,
  balanceInDB: Balances,
  noOfLayersToSkip: number,
  marketCycle: MarketCycle,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>,
  customMsg?: string
) => {
  layeredBuyBids = adjustBuyStopLossAndLine(
    layeredBuyBids,
    sanitizedData,
    linesInChart,
    candlestickSeries
  );

  ({
    buyBoughts: layeredBuyBoughts,
    remainingBuyBids: layeredBuyBids,
    remainingBalance: initialBalance,
    remReservedBalance: reservedBalance,
  } = deleteFromBidsAddToBought(
    coinID,
    notation,
    highPrice,
    layeredBuyBids,
    layeredBuyBoughts,
    sanitizedData,
    initialBalance,
    reservedBalance,
    percentageStatistics,
    shouldPlacePaperOrder,
    balanceInDB,
    noOfLayersToSkip,
    marketCycle,
    customMsg
  ));

  const updatedBuyBoughts = await placeOrCheckRealBuyOrders(
    coinID,
    notation,
    layeredBuyBoughts,
    sanitizedData,
    shouldPlacePaperOrder,
    customMsg
  );

  const resolvedBuys = await Promise.all(updatedBuyBoughts);
  layeredBuyBoughts = resolvedBuys;
  return { layeredBuyBoughts, layeredBuyBids, initialBalance, reservedBalance };
};
export const processBuyBoughts = async (
  coinID: string,
  notation: TradeNotations,
  layeredBuyBoughts: LayeredBoughtDetails,
  sanitizedData: DataProp,
  initialBalance: number,
  percentageStatistics: PercentageStatistics,
  hasBreakEvenStoploss: boolean,
  isNewHighPriceSet: boolean,
  linesInChart: LinesInChart,
  layerSkipped: number,
  marketCycle: MarketCycle,
  piggyBankPositions: PiggyBankPosition[],
  fibLevels: FibLevels,
  candlestickSeries?: ISeriesApi<'Candlestick'>,
  customMsg?: string
) => {
  // check if stoploss is triggered
  const { newBuyBoughts, profitBalance, newPiggyBankPositions } =
    adjustStopLossSecureProfitAddLine(
      coinID,
      notation,
      layeredBuyBoughts,
      sanitizedData,
      initialBalance,
      percentageStatistics,
      isNewHighPriceSet,
      hasBreakEvenStoploss || false,
      linesInChart,
      layerSkipped,
      marketCycle,
      piggyBankPositions,
      fibLevels,
      candlestickSeries,
      customMsg
    );
  layeredBuyBoughts = newBuyBoughts;
  initialBalance = profitBalance;
  piggyBankPositions = newPiggyBankPositions;

  // for all stoploss executed orders, place actual sell order
  // if already placed, check if order is fully completed
  const updatedBuySold = await placeOrCheckRealSellOrders(
    coinID,
    notation,
    layeredBuyBoughts,
    sanitizedData,
    fibLevels,
    customMsg
  );

  const resolvedSold = await Promise.all(updatedBuySold);
  layeredBuyBoughts = resolvedSold;
  return { layeredBuyBoughts, initialBalance, piggyBankPositions };
};

export interface MakeEachCandleTickChecksReturn {
  highPriceForCoin: number;
  lowFromHighPriceForCoins: number;
  layeredBuyBidsForCoin: LayeredBuyBidDetails;
  layeredBuyBoughtsForCoin: LayeredBoughtDetails;
  finalInitialBalance: number;
  finalReservedBalance: number;
  alertPriceForCoin: number | null;
  hasBreakEvenStoplossForCoin: boolean;
  piggyBankPositionsForCoin: PiggyBankPosition[];
}

export const makeEachCandleTickChecks = async ({
  coinID,
  notation,
  sanitizedData,
  highPrice,
  lowFromHighPrice,
  layeredBuyBids,
  layeredBuyBoughts,
  initialBalance,
  reservedBalance,
  alertPrice,
  percentageStatistics,
  hasBreakEvenStoploss,
  saveInDB = false,
  higestPrice,
  highsAndLows,
  paperTrade = false,
  balances,
  noOfLayersToSkip,
  timeSinceLastUpdate,
  linesInChart,
  marketCycle,
  collectFreeCoins,
  piggyBankPositions = [],
  fibLevels,
  candlestickSeries,
}: MakeEachCandleTickChecksProps): Promise<MakeEachCandleTickChecksReturn> => {
  const isNewHighPriceSet = false;
  const orgBuyBids = cloneDeep(layeredBuyBids);
  const orgBuyBoughts = cloneDeep(layeredBuyBoughts);
  const previousLowPrice = cloneDeep(lowFromHighPrice);
  const orgPiggyBankPositions = cloneDeep(piggyBankPositions);
  const { averageAlertPercentage: alertPercentage } = percentageStatistics;
  const originalPairInfo = cloneDeep({
    id: `${coinID}-${notation}`,
    relativeHigh: highPrice,
    relativeLow: lowFromHighPrice,
    higestPrice,
    highsAndLows,
    alertPrice,
    initialBalance,
    reservedBalance,
  });

  ({
    newHighPrice: highPrice,
    newLowFromHighPrice: lowFromHighPrice,
    newLayeredBuyBids: layeredBuyBids,
    newAlertPrice: alertPrice,
  } = setRecentHighLowPriceAndLine(
    sanitizedData.close,
    sanitizedData as DataProp,
    highPrice,
    lowFromHighPrice,
    layeredBuyBids,
    Boolean(layeredBuyBids.length),
    alertPrice,
    percentageStatistics,
    highsAndLows,
    linesInChart,
    candlestickSeries
  ));

  // Check if price is above 0.702 fib level
  const isAboveFibLevel =
    fibLevels['0.702'] !== 0 && sanitizedData.close > fibLevels['0.702'];

  if (isAboveFibLevel && piggyBankPositions.length > 0) {
    // console.log(
    //   `${coinID}-${notation}: Price ${sanitizedData.close} is above 0.702 fib level ${fibLevels['0.702']}`
    // );

    const { orders: fibOrders, updatedPiggyBankPositions } =
      createFibLevelOrders(
        piggyBankPositions,
        fibLevels,
        coinID,
        notation,
        sanitizedData.time
      );

    if (fibOrders.length > 0) {
      layeredBuyBoughts = [...layeredBuyBoughts, ...fibOrders];
      piggyBankPositions = updatedPiggyBankPositions;
    }
  }

  // check alerts and if triggered set buy bids
  const readyToBuy = checkAlertTriggered(
    alertPrice,
    lowFromHighPrice,
    Boolean(layeredBuyBids.length),
    linesInChart,
    candlestickSeries
  );

  if (readyToBuy) {
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      console.log(coinID, notation, 'is ready to place buy bids!');
    }

    layeredBuyBids = placeLayerdBuyBids(
      coinID,
      notation,
      highPrice,
      higestPrice,
      layeredBuyBoughts,
      alertPercentage,
      noOfLayersToSkip,
      sanitizedData.close,
      linesInChart,
      highsAndLows,
      collectFreeCoins,
      marketCycle,
      fibLevels,
      candlestickSeries
    );

    alertPrice = null; // reseting so we dont trigger readyToBuy again
  }

  // check if we have buys and if any of them are hit.
  const shouldCoinsHaveBuyStoploss = checkBuyOrdersHit(
    layeredBuyBids,
    sanitizedData
  );

  if (shouldCoinsHaveBuyStoploss) {
    layeredBuyBids = removeLineForBuysSetBuyStoploss(
      layeredBuyBids,
      sanitizedData,
      linesInChart,
      candlestickSeries
    );
  }

  ({ layeredBuyBoughts, layeredBuyBids, initialBalance, reservedBalance } =
    await processBuyBids(
      layeredBuyBoughts,
      layeredBuyBids,
      initialBalance,
      reservedBalance,
      coinID,
      notation,
      highPrice,
      sanitizedData,
      percentageStatistics,
      paperTrade,
      balances,
      noOfLayersToSkip,
      marketCycle,
      linesInChart,
      candlestickSeries
    ));

  // check if the current price is greater than our % higher buy prices.
  const buysInProfit = checkIfInProfit(
    coinID,
    sanitizedData,
    layeredBuyBoughts,
    percentageStatistics,
    noOfLayersToSkip,
    marketCycle
  );
  //  if yes, place stoploss
  if (buysInProfit) {
    // set value in layeredBuyBoughts to stoploss set so we dont check again for same buy prices
    // TODO : cannot decide if passing high in getStopLossPriceAndPerc is better or not.

    layeredBuyBoughts = setStopLossForProfitBoughts(
      layeredBuyBoughts,
      sanitizedData.close,
      percentageStatistics,
      linesInChart,
      noOfLayersToSkip,
      marketCycle,
      fibLevels,
      candlestickSeries
    );
  }

  ({ layeredBuyBoughts, initialBalance, piggyBankPositions } =
    await processBuyBoughts(
      coinID,
      notation,
      layeredBuyBoughts,
      sanitizedData,
      initialBalance,
      percentageStatistics,
      hasBreakEvenStoploss,
      isNewHighPriceSet,
      linesInChart,
      noOfLayersToSkip,
      marketCycle,
      piggyBankPositions,
      fibLevels,
      candlestickSeries
    ));

  if (saveInDB) {
    await saveToAWS(
      orgBuyBids,
      layeredBuyBids,
      coinID,
      notation,
      orgBuyBoughts,
      layeredBuyBoughts,
      highsAndLows,
      highPrice,
      previousLowPrice,
      sanitizedData,
      lowFromHighPrice,
      higestPrice,
      alertPrice,
      initialBalance,
      reservedBalance,
      originalPairInfo,
      orgPiggyBankPositions,
      piggyBankPositions,
      timeSinceLastUpdate
    );
    // console.log('SAVING DONE for -- ', coinID, notation);
  }

  return {
    highPriceForCoin: highPrice,
    lowFromHighPriceForCoins: lowFromHighPrice,
    layeredBuyBidsForCoin: layeredBuyBids,
    layeredBuyBoughtsForCoin: layeredBuyBoughts,
    finalInitialBalance: initialBalance,
    finalReservedBalance: reservedBalance,
    alertPriceForCoin: alertPrice,
    hasBreakEvenStoplossForCoin: hasBreakEvenStoploss,
    piggyBankPositionsForCoin: piggyBankPositions,
  };
};

export const executeEmergencySell = async (
  coinID: string,
  notation: TradeNotations,
  buyOrder: LayeredBought,
  candleData: DataProp
) => {
  const symbol = getTradeSymbol(coinID, notation);
  const { id, buyPercent, buyOrderInfo, sellOrderInfo, realBuyOrderPlaced } =
    buyOrder;

  // Skip if no real buy order exists
  if (!buyOrderInfo && !realBuyOrderPlaced) {
    return buyOrder;
  }
  // If there's an existing sell order, cancel it first
  if (sellOrderInfo?.id) {
    const canceledDetail = (await cancelSpecificOrder(
      buyPercent,
      symbol,
      sellOrderInfo.id
    )) as Order;

    if (!canceledDetail || canceledDetail.status !== 'canceled') {
      console.error(
        `EMERGENCY --> Failed to cancel existing sell order for ${coinID} - ${buyPercent}% with orderId ${sellOrderInfo.id}`
      );
      return buyOrder;
    }

    console.info(
      `EMERGENCY --> Cancelled existing sell order for ${coinID} - ${buyPercent}% with orderId ${sellOrderInfo.id}`
    );
  }

  // Place market sell order
  try {
    const marketOrderDetail = (await placeMarketSellOrder(
      buyPercent,
      symbol,
      buyOrderInfo!.amount
    )) as Order;

    if (marketOrderDetail?.status) {
      const strippedOrderInfo = stripUndefinedValue(marketOrderDetail);
      const profitSecured =
        ((marketOrderDetail.average || marketOrderDetail.price) /
          buyOrder.buyPrice -
          1) *
        100;

      // Send notification
      awsDB.addNewNotification(
        id,
        coinID,
        notation,
        NotificationType.warning,
        `🚨 EMERGENCY SELL - ${coinID} - ${buyPercent}% executed with ${profitSecured.toFixed(
          2
        )}% profit/loss!`,
        false
      );

      // Post tweet if not in development
      if (process.env.REACT_APP_NODE_ENV !== 'development') {
        callLambdaToPostTweet({
          coinID,
          notation,
          buyType: 'sell',
          candleData,
          order: buyOrder,
        });
      }

      return {
        ...buyOrder,
        sellOrderInfo: strippedOrderInfo,
        realSellOrderStatus: marketOrderDetail.status,
        stoplossPrice: marketOrderDetail.average || marketOrderDetail.price,
        profitSecured,
        sellTime: candleData.time,
      };
    }

    throw new Error('Market order placement failed');
  } catch (error) {
    console.error(
      `EMERGENCY --> Failed to place market sell order for ${coinID} - ${buyPercent}%:`,
      error
    );

    awsDB.addNewNotification(
      id,
      coinID,
      notation,
      NotificationType.error,
      `EMERGENCY SELL FAILED - ${coinID} - ${buyPercent}%: ${error}`,
      false
    );

    return buyOrder;
  }
};
