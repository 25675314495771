import _ from 'lodash';
import {
  BuyOrderType,
  HighLowPrice,
  MarketCycle,
  OrderStatus,
  PiggyBankPosition,
  PriceType,
} from '../../API';
import {
  LayeredBoughtDetails,
  LayeredBought,
  PercForHighLow,
  PercentageStatistics,
  FibLevels,
  TradeNotations,
} from '../../types';

const ADJUSTED_BUFFER = 0.96;
const STOPLOSS_ADJUSTMENT_THRESHOLD = 5;
const SCALING_FACTOR = 0.5; // Adjust this value to control how much stopLossPerc increases as profitSecured increases
let DEFAULT_ALERT_PERCENTAGE = 33;
const PIGGY_BANK_MIN_PROFIT_FOR_STOPLOSS = 30; // Minimum profit % before setting stoploss
const PIGGY_BANK_INITIAL_STOPLOSS = 15; // Initial stoploss % from current price
const PIGGY_BANK_TRAILING_STOPLOSS_GAP = 10; // Trailing gap % as price moves up

export const FIB_ORDER_PLACEMENTS = {
  '0.702': { color: '#ffeb3c' },
  '1.618': { color: '#2962ff' },
  '2.618': { color: '#f23644' },
  '3.618': { color: '#9c27b0' },
  '4.236': { color: '#e91e63' },
} as const;

export const setDefaultAlertPercentage = (value: number) => {
  DEFAULT_ALERT_PERCENTAGE = value;
};

export const getDefaultAlertPercentage = () => DEFAULT_ALERT_PERCENTAGE;

export const getPercentageDiff = (
  candleHigh?: number,
  lowFromHighPrice?: number
) => {
  if (!candleHigh || !lowFromHighPrice) return 0;
  const percDiff = ((candleHigh - lowFromHighPrice) / lowFromHighPrice) * 100;
  return percDiff < 0 ? percDiff * -1 : percDiff;
};

const getTotalNotSoldAmountFromBoughts = (
  layeredBuyBoughts: LayeredBoughtDetails,
  closePrice: number
) => {
  let totalNotSold = 0;
  let btcWithProfitOrLoss = 0;

  // Calculate for regular trades
  layeredBuyBoughts.forEach((buys) => {
    const { profitSecured, invested, buyPrice, sellTime } = buys;

    if (sellTime) return;

    if (profitSecured) {
      // Regular orders - calculate full profit
      btcWithProfitOrLoss = +(
        invested! +
        (profitSecured! / 100) * invested!
      ).toFixed(5);
    } else if (buyPrice >= closePrice) {
      // if loss calculate loss
      const loss = getPercentageDiff(closePrice, buyPrice);
      btcWithProfitOrLoss = +(invested! - (loss / 100) * invested!).toFixed(5);
    } else {
      btcWithProfitOrLoss = invested!;
    }

    totalNotSold = +(totalNotSold + btcWithProfitOrLoss).toFixed(5);
  });

  return totalNotSold;
};

export const calculateTotalBalance = (
  closePrice: number,
  initialBalance: number,
  layeredBuyBoughts: LayeredBoughtDetails
) => {
  if (layeredBuyBoughts.length === 0) {
    return initialBalance;
  }

  const totalNotSold = getTotalNotSoldAmountFromBoughts(
    layeredBuyBoughts,
    closePrice
  );

  return +(totalNotSold + initialBalance).toFixed(5);
};

export const percentageDIffFromHighToLowPrice = (
  highPrice: number,
  lowPrice: number
) => ((highPrice - lowPrice) / highPrice) * 100;

export const getPriceBelowPercentage = (price: number, percentage: number) =>
  +(price - (percentage / 100) * price).toFixed(8);

export const calculatePercentageDIffFromHighAndLow = (
  priceType: string,
  currentPrice: number | undefined,
  index: number,
  lowAndHighPrices: Omit<HighLowPrice, '__typename'>[]
) => {
  const prevPrice = lowAndHighPrices[index - 1]
    ? lowAndHighPrices[index - 1].price
    : null;
  if (!prevPrice) return { perc: 0, priceType };

  return {
    perc: +getPercentageDiff(currentPrice, prevPrice).toFixed(1),
    priceType,
  };
};

export const calculateTotalPnLPercentage = (
  boughts: LayeredBoughtDetails,
  currentPrice: number
) => {
  const totalInvestedAmount = boughts?.reduce(
    (acc, { invested }) => (invested ? acc + invested : acc),
    0
  );

  const totalPnLAmount = boughts?.reduce(
    (acc, { invested, profitSecured, buyPrice }) => {
      if (invested && profitSecured) {
        return acc + invested! + (+profitSecured.toFixed(2) / 100) * invested!;
      }

      const profitOrLoss = ((currentPrice - buyPrice) / buyPrice) * 100;

      return acc + invested! + (profitOrLoss / 100) * invested!;
    },
    0
  );

  const profitLoss =
    ((totalPnLAmount - totalInvestedAmount) / totalInvestedAmount) * 100;

  return {
    profitLoss,
    totalInvestedAmount,
    totalProfitLossAmount: totalPnLAmount,
  };
};

// Mean: The average of a set of numbers, calculated by dividing the sum of all values by the number of values.
// Median: The middle value in a list of numbers, which separates the higher half from the lower half.
// Mode: The value that appears most frequently in a data set.

export const getLowAndAvgLow = (onlyLows: PercForHighLow[]) => {
  const filteredValues = removeOutliers(onlyLows);
  const statistics = calculateStatistics(filteredValues);

  const avgLows =
    onlyLows.reduce((prev, curr) => prev + curr.perc, 0) / onlyLows.length;

  return { lowLows: Number(statistics.mode?.toFixed(0)), avgLows };
};

export const getLowHighAndAvgHigh = (onlyHighs: PercForHighLow[]) => {
  const filteredValues = removeOutliers(onlyHighs);
  const statistics = calculateStatistics(filteredValues);

  // const avgHighs =
  //   onlyHighs.reduce((prev, curr) => prev + curr.perc, 0) / onlyLows.length;

  return {
    lowHighs: Number(statistics.mode?.toFixed(0)),
    avgHighs: statistics.mean,
  };
};

// Function to remove outliers
const removeOutliers = (values: PercForHighLow[]): PercForHighLow[] => {
  // Extract the 'perc' values
  const percValues = values.map((item) => item.perc);

  // Sort the perc values
  const sortedPercValues = _.sortBy(percValues);

  // Calculate Q1 (25th percentile) and Q3 (75th percentile)
  const Q1 = sortedPercValues[Math.floor(sortedPercValues.length / 4)];
  const Q3 = sortedPercValues[Math.ceil(sortedPercValues.length * (3 / 4)) - 1];

  // Interquartile Range (IQR)
  const IQR = Q3 - Q1;

  // Define the lower and upper bounds
  const lowerBound = Q1 - 1.5 * IQR;
  const upperBound = Q3 + 1.5 * IQR;

  // Filter out objects where perc is within the bounds (non-outliers)
  return values.filter(
    (item) => item.perc >= lowerBound && item.perc <= upperBound
  );
};

// Function to calculate mean, median, and mode
const calculateStatistics = (
  values: PercForHighLow[]
): {
  mean: number;
  median: number;
  mode: number | null;
} => {
  // Extract the 'perc' values
  const percValues = values.map((item) => item.perc);

  // Sort the perc values
  const sortedValues = _.sortBy(percValues);

  // Calculate Mean
  const mean = _.mean(sortedValues);

  // Calculate Median
  const mid = Math.floor(sortedValues.length / 2);
  const median =
    sortedValues.length % 2 !== 0
      ? sortedValues[mid]
      : (sortedValues[mid - 1] + sortedValues[mid]) / 2;

  // Calculate Mode
  const frequencyMap: { [key: number]: number } = {};
  let mode: number | null = null;
  let maxCount = 0;

  // Calculate the frequency of each value
  sortedValues.forEach((value) => {
    frequencyMap[value] = (frequencyMap[value] || 0) + 1;
    if (frequencyMap[value] > maxCount) {
      maxCount = frequencyMap[value];
      mode = value;
    }
  });

  // Return the statistics
  return { mean, median, mode };
};

export const averageHighsAndLows = (
  highsAndLows: Omit<HighLowPrice, '__typename'>[]
) => {
  const percetagesForBothPrices: PercForHighLow[] = [];
  if (highsAndLows?.length) {
    highsAndLows.forEach((price, index) => {
      percetagesForBothPrices.push(
        calculatePercentageDIffFromHighAndLow(
          price.priceType,
          price.price,
          index,
          highsAndLows
        )
      );
    });
  }

  const onlyLows = percetagesForBothPrices.filter(
    (highLow) => highLow.priceType === 'low' && highLow.perc
  );
  const onlyHighs = percetagesForBothPrices.filter(
    (highLow) => highLow.priceType === 'high' && highLow.perc
  );

  const { lowLows, avgLows } = getLowAndAvgLow(onlyLows);
  const { lowHighs, avgHighs } = getLowHighAndAvgHigh(onlyHighs);

  return {
    lowLows: +lowLows?.toFixed(0),
    avgLows: +avgLows.toFixed(0),
    lowHighs: +lowHighs?.toFixed(0),
    avgHighs: +avgHighs.toFixed(0),
  };
};

export const calculatePercentageStatistics = (
  highsAndLows?: Omit<HighLowPrice, '__typename'>[]
) => {
  const statistics = highsAndLows
    ? averageHighsAndLows(highsAndLows)
    : {
        avgLows: DEFAULT_ALERT_PERCENTAGE,
        lowLows: 0,
        avgHighs: 0,
        lowHighs: 0,
      };

  // console.log(
  //   NOTATION,
  //   'avgLows',
  //   statistics.avgLows,
  //   'lowLows',
  //   statistics.lowLows
  // );

  const avgAlertPercForCoin =
    statistics.avgLows > DEFAULT_ALERT_PERCENTAGE
      ? statistics.avgLows - 15
      : DEFAULT_ALERT_PERCENTAGE;

  // calculate the average time taken in days from one high to another high

  const percentageStatistics: PercentageStatistics = {
    lowestAlertPercentage: statistics.lowLows,
    averageAlertPercentage:
      avgAlertPercForCoin > DEFAULT_ALERT_PERCENTAGE
        ? avgAlertPercForCoin
        : DEFAULT_ALERT_PERCENTAGE,
    lowestProfitPercentage: statistics.lowHighs,
    averageProfitPercentage: statistics.avgHighs,
  };

  return percentageStatistics;
};

export const getLayerNumbering = (
  buyPercent: number,
  layerSkipped: number,
  alertPercentage: number
) => {
  let layer = +((buyPercent - 5 * layerSkipped - alertPercentage) / 5).toFixed(
    0
  );
  layer = layer === 0 ? 1 : layer;
  layer = layer > 5 ? 5 : layer;
  return layer;
};

export const getLayers = (color: string) => {
  // Check if it's a fib order color
  const fibOrder = Object.values(FIB_ORDER_PLACEMENTS).find(
    (placement) => placement.color === color
  );
  if (fibOrder) {
    return 5;
  }

  // Regular layer colors
  switch (color) {
    case '#8012ed':
      return 1;
    case '#f82165':
      return 2;
    case '#cab301':
      return 3;
    case '#35fe4c':
      return 4;
    case '#079ade':
      return 5;
    case '#e0ff0b':
      return 'Manual';
    default:
      return 'Unknown';
  }
};

export const getPercentNeededForBuys = (
  buyPercent: number,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  marketCyle: MarketCycle
) => {
  const layer = getLayerNumbering(
    buyPercent,
    layerSkipped,
    percentageStatistics.averageAlertPercentage
  );
  let percIncreaseNeeded = 15;
  const lowestProfitPercentage = percentageStatistics.lowestProfitPercentage;
  const averageProfitPercentage = percentageStatistics.averageProfitPercentage;
  const isBull = marketCyle === 'bull';
  // || marketCyle === 'accumulation';

  switch (layer) {
    case 1:
      percIncreaseNeeded = isBull ? lowestProfitPercentage : 10;
      break;
    case 2:
      percIncreaseNeeded = isBull ? lowestProfitPercentage : 15;
      break;
    case 3:
      percIncreaseNeeded = isBull ? lowestProfitPercentage : 20;
      break;
    case 4:
      percIncreaseNeeded = isBull ? averageProfitPercentage : 25;
      break;
    case 5:
      percIncreaseNeeded = isBull ? averageProfitPercentage : 30;
      break;
    default:
      percIncreaseNeeded = isBull ? lowestProfitPercentage : 15;
      break;
  }
  return percIncreaseNeeded;
};

export const getProfitGenerated = (
  profitSecured: number,
  invested: number,
  initialBalance: number = 0,
  buyOrderType: BuyOrderType = BuyOrderType.regular,
  buyPrice: number,
  fibLevels?: FibLevels
) => {
  if (
    buyOrderType === BuyOrderType.piggyBankEligible &&
    fibLevels &&
    fibLevels.ATH !== 0
  ) {
    const profit = profitSecured * 0.01 * invested;
    const buyDrawdown = Number(
      ((fibLevels.ATH - buyPrice) / fibLevels.ATH) * 100
    );
    const { tradePerc } = calculateProfitSplit(
      buyDrawdown,
      fibLevels.drawdownPercentage
    );

    return +(initialBalance + (profit * (tradePerc / 100) + invested)).toFixed(
      5
    );
  }

  return +(
    initialBalance +
    (profitSecured * 0.01 * invested + invested)
  ).toFixed(5);
};

const calculateStopLossPrice = (stopLossPerc: number, coinBuyPrice: number) =>
  +(coinBuyPrice + (stopLossPerc / 100) * coinBuyPrice).toFixed(8);

export const getDynamicStoplossForBought = (
  actualProfitPerc: number,
  percTocapture: number,
  profitSecured: number
) => {
  // Adjust the calculation of dynamicScalingFactor to increase with percTocapture but not exceed 0.9
  const minFactor = 0.1;
  const maxFactor = 0.9;

  const dynamicScalingFactor =
    minFactor + (maxFactor - minFactor) * (actualProfitPerc / 100) ** 2;
  Math.sqrt(actualProfitPerc / 100);

  // Define the buffer range you want to maintain
  const minBuffer = 0.96;
  const maxBuffer = 0.99;

  // Calculate the adjustedBuffer based on dynamicScalingFactor
  const adjustedBuffer =
    minBuffer + (maxBuffer - minBuffer) * dynamicScalingFactor;
  const updatedStopLossPerc =
    percTocapture + profitSecured * dynamicScalingFactor;
  return { adjustedBuffer, updatedStopLossPerc };
};

// Common calculations function
const getCommonCalculations = (
  close: number,
  buyPrice: number,
  buyPercent: number,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  marketCyle: MarketCycle
) => {
  const coinBuyPrice = +buyPrice.toFixed(8);

  const shouldIncrease = getPercentNeededForBuys(
    buyPercent,
    percentageStatistics,
    layerSkipped,
    marketCyle
  );
  const buyPlusProfit = +(
    coinBuyPrice +
    (shouldIncrease / 100) * coinBuyPrice
  ).toFixed(8);
  const actualProfitPerc = ((close - coinBuyPrice) / coinBuyPrice) * 100;

  return { coinBuyPrice, shouldIncrease, buyPlusProfit, actualProfitPerc };
};

// Function for bull and accumulation phases
const getStoplossPriceAndPercBullish = (
  close: number,
  buyPrice: number,
  buyPercent: number,
  profitSecured: number,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  fibLevel: number | null | undefined,
  fibLevels: FibLevels,
  profitMoved?: number | null | undefined,
  buyOrderType: BuyOrderType = BuyOrderType.regular
) => {
  // Handle piggyBank orders separately
  if (buyOrderType === BuyOrderType.piggyBankFibOrder && fibLevel) {
    const { stopLossPrice, stopLossPerc } = getPiggyBankStoplossPrice(
      close,
      buyPrice,
      profitSecured || null,
      fibLevel,
      fibLevels
    );
    return {
      stopLossPrice,
      stopLossPerc,
      newProfitMoved: profitMoved || 0,
    };
  }

  // Existing bullish logic for regular orders
  let moved = profitMoved || 0;
  const { coinBuyPrice, shouldIncrease, buyPlusProfit, actualProfitPerc } =
    getCommonCalculations(
      close,
      buyPrice,
      buyPercent,
      percentageStatistics,
      layerSkipped,
      MarketCycle.bull
    );

  let stopLossPrice = coinBuyPrice;
  let stopLossPerc = 0;
  if (close <= buyPlusProfit) return { stopLossPrice, stopLossPerc };

  // Dynamic profit-taking logic
  if (actualProfitPerc > shouldIncrease + 10) {
    // Calculate dynamic gap (between 5% and 15%)
    const maxGap = 15;
    const minGap = 5;
    const gapRange = maxGap - minGap;
    const profitFactor = Math.min(
      Math.max((actualProfitPerc - shouldIncrease) / 100, 0),
      1
    );
    const dynamicGap = maxGap - gapRange * profitFactor;

    // Set stop-loss price based on dynamic gap
    stopLossPrice = close * (1 - dynamicGap / 100);

    // Ensure stop-loss is not below buy price
    stopLossPrice = Math.max(stopLossPrice, coinBuyPrice);

    stopLossPerc = ((stopLossPrice - coinBuyPrice) / coinBuyPrice) * 100;

    // Ensure stop-loss is not below profitSecured level
    const minStopLossPrice = coinBuyPrice * (1 + profitSecured / 100);
    if (stopLossPrice < minStopLossPrice) {
      stopLossPrice = minStopLossPrice;
      stopLossPerc = profitSecured;
    }
    moved = stopLossPerc > profitSecured ? moved + 1 : moved;
  }
  return { stopLossPrice, stopLossPerc, newProfitMoved: moved };
};

// Function for bear and violent phases
const getStoplossPriceAndPercBearish = (
  close: number,
  buyPrice: number,
  buyPercent: number,
  profitSecured: number,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  fibLevel: number | null | undefined,
  fibLevels: FibLevels,
  profitMoved?: number | null | undefined,
  buyOrderType: BuyOrderType = BuyOrderType.regular
) => {
  // Handle piggyBank orders separately
  if (buyOrderType === BuyOrderType.piggyBankFibOrder && fibLevel) {
    const { stopLossPrice, stopLossPerc } = getPiggyBankStoplossPrice(
      close,
      buyPrice,
      profitSecured || null,
      fibLevel,
      fibLevels
    );
    return {
      stopLossPrice,
      stopLossPerc,
      newProfitMoved: profitMoved || 0,
    };
  }

  // Existing bearish logic for regular orders
  let moved = profitMoved || 0;
  const { coinBuyPrice, shouldIncrease, buyPlusProfit, actualProfitPerc } =
    getCommonCalculations(
      close,
      buyPrice,
      buyPercent,
      percentageStatistics,
      layerSkipped,
      MarketCycle.bear
    );

  let stopLossPrice = coinBuyPrice;
  let stopLossPerc = 0;
  if (close <= buyPlusProfit) return { stopLossPrice, stopLossPerc };

  const percTocapture = actualProfitPerc - shouldIncrease;

  // calculate % increase in profit.
  if (percTocapture >= STOPLOSS_ADJUSTMENT_THRESHOLD) {
    // ! This is the dynamic stoploss calculation maybe we can use this in future
    // let adjustedBuffer;
    // ({ adjustedBuffer, updatedStopLossPerc: stopLossPerc } = getScaledStoploss(
    //   actualProfitPerc,
    //   percTocapture,
    //   profitSecured
    // ));

    stopLossPerc = percTocapture + profitSecured * SCALING_FACTOR;
    stopLossPerc = Math.max(stopLossPerc, profitSecured);
    stopLossPrice = calculateStopLossPrice(stopLossPerc, coinBuyPrice);
    if (stopLossPrice > close) {
      stopLossPrice = close * ADJUSTED_BUFFER;
      stopLossPerc = (stopLossPrice / coinBuyPrice - 1) * 100;
      stopLossPerc = Math.max(stopLossPerc, profitSecured);
      stopLossPrice = calculateStopLossPrice(stopLossPerc, coinBuyPrice);
    }
    moved = stopLossPerc > profitSecured ? moved + 1 : moved;
  }
  return { stopLossPrice, stopLossPerc, newProfitMoved: moved };
};

// Main function that chooses between the two strategies based on market cycle
export const getStoplossPriceAndPerc = (
  close: number,
  buyPrice: number,
  buyPercent: number,
  profitSecured: number,
  percentageStatistics: PercentageStatistics,
  layerSkipped: number,
  marketCycle: MarketCycle,
  fibLevel: number | null | undefined,
  fibLevels: FibLevels,
  profitMoved?: number | null | undefined,
  buyOrderType: BuyOrderType = BuyOrderType.regular
) => {
  const isBullish = marketCycle === MarketCycle.bull;
  // || marketCycle === MarketCycle.accumulation;

  return isBullish
    ? getStoplossPriceAndPercBullish(
        close,
        buyPrice,
        buyPercent,
        profitSecured,
        percentageStatistics,
        layerSkipped,
        fibLevel ?? undefined,
        fibLevels,
        profitMoved,
        buyOrderType
      )
    : getStoplossPriceAndPercBearish(
        close,
        buyPrice,
        buyPercent,
        profitSecured,
        percentageStatistics,
        layerSkipped,
        fibLevel ?? undefined,
        fibLevels,
        profitMoved,
        buyOrderType
      );
};

const getLastLowPrice = (
  lowAndHighPrices: Omit<HighLowPrice, '__typename'>[]
) => {
  const reversed = lowAndHighPrices.slice().reverse();
  const lastLowPrice = reversed.find((price) => price.priceType === 'low');
  return lastLowPrice;
};

const instantiateLowAndHighPriceIfEmpty = (
  lowAndHighPrices: Omit<HighLowPrice, '__typename'>[],
  lowPriceInChart: number,
  time: number
) => {
  if (!lowAndHighPrices.length) {
    lowAndHighPrices.push({
      price: lowPriceInChart,
      time,
      priceType: 'low' as PriceType,
    });
  }
  return lowAndHighPrices;
};

export const addLowPricesBasedOnDifferentConditions = (
  prevLowPrice: Omit<HighLowPrice, '__typename'> | undefined,
  lowPriceInChart: number,
  lowHighPrices: Omit<HighLowPrice, '__typename'>[],
  time: number,
  prevLastPrice: Omit<HighLowPrice, '__typename'>
) => {
  const difference = getPercentageDiff(lowPriceInChart, prevLastPrice.price);

  if (prevLowPrice?.price && lowPriceInChart < prevLowPrice?.price) {
    lowHighPrices.push({
      price: lowPriceInChart,
      time,
      priceType: 'low' as PriceType,
    });
  } else if (!prevLowPrice) {
    lowHighPrices.push({
      price: lowPriceInChart,
      time,
      priceType: 'low' as PriceType,
    });
  } else if (prevLastPrice.priceType === 'high' && difference >= 40) {
    lowHighPrices.push({
      price: lowPriceInChart,
      time,
      priceType: 'low' as PriceType,
    });
  }
};

export const addNewLowPriceToLowHighPrices = (
  lowHighPrices: Omit<HighLowPrice, '__typename'>[],
  lowPriceInChart: number,
  time: number
) => {
  const prevLastPrice = lowHighPrices[lowHighPrices.length - 1];
  const prevLowPrice = getLastLowPrice(lowHighPrices);
  const isNewLowPriceSmaller = lowPriceInChart < prevLastPrice.price;
  if (!isNewLowPriceSmaller) return;

  if (prevLastPrice.priceType === 'low') {
    lowHighPrices.pop();
  }

  addLowPricesBasedOnDifferentConditions(
    prevLowPrice,
    lowPriceInChart,
    lowHighPrices,
    time,
    prevLastPrice
  );
};

export const addHighPricesBasedOnDifferentConditions = (
  lastPrice: Omit<HighLowPrice, '__typename'>,
  lowHighPrices: Omit<HighLowPrice, '__typename'>[],
  lastHighPrice: Omit<HighLowPrice, '__typename'> | undefined,
  highPriceInChart: number,
  time: number
) => {
  if (lastPrice.priceType === 'high') {
    lowHighPrices.pop();
  }
  if (lastHighPrice?.price && highPriceInChart > lastHighPrice?.price) {
    lowHighPrices.push({
      price: highPriceInChart,
      time,
      priceType: 'high' as PriceType,
    });
  } else if (lastHighPrice && lastHighPrice.price !== highPriceInChart) {
    lowHighPrices.push({
      price: highPriceInChart,
      time,
      priceType: 'high' as PriceType,
    });
  } else if (!lastHighPrice) {
    lowHighPrices.push({
      price: highPriceInChart,
      time,
      priceType: 'high' as PriceType,
    });
  }
};

const getLastLowAndHighPrice = (
  lowHighPrices: Omit<HighLowPrice, '__typename'>[]
) => {
  const reversedLowAndHighPrices = lowHighPrices.slice().reverse();
  const lastHighPrice = reversedLowAndHighPrices.find(
    (price) => price.priceType === 'high'
  );
  const lastLowPrice = reversedLowAndHighPrices.find(
    (price) => price.priceType === 'low'
  );
  return { lastLowPrice, lastHighPrice };
};

export const addNewHighPriceToLowHighPrices = (
  lowHighPrices: Omit<HighLowPrice, '__typename'>[],
  highPriceInChart: number,
  time: number
) => {
  const { lastLowPrice, lastHighPrice } = getLastLowAndHighPrice(lowHighPrices);
  const percentageDifference = getPercentageDiff(
    highPriceInChart,
    lastLowPrice?.price
  );

  const lastPrice = lowHighPrices.slice(-1)[0];
  if (percentageDifference >= 40 && highPriceInChart > lastPrice.price) {
    addHighPricesBasedOnDifferentConditions(
      lastPrice,
      lowHighPrices,
      lastHighPrice,
      highPriceInChart,
      time
    );
  }
};

export const setLowAndHighPrice = (
  lowAndHighPrices: Omit<HighLowPrice, '__typename'>[],
  highPriceInChart: number,
  lowPriceInChart: number,
  time: number
): Omit<HighLowPrice, '__typename'>[] => {
  if (!lowAndHighPrices) return [];
  const lowHighPrices = instantiateLowAndHighPriceIfEmpty(
    lowAndHighPrices,
    lowPriceInChart,
    time
  );

  addNewLowPriceToLowHighPrices(lowHighPrices, lowPriceInChart, time);
  addNewHighPriceToLowHighPrices(lowHighPrices, highPriceInChart, time);

  return lowHighPrices;
};

export const getcoinsBoughtAndCapitalSpent = (
  layeredBuyBoughts: LayeredBoughtDetails
) => {
  const coinsBought: number = layeredBuyBoughts.reduce(
    (acc: number, coin: LayeredBought) => {
      return acc + coin.invested! / coin.buyPrice;
    },
    0
  );

  const btcSpent: number = layeredBuyBoughts?.reduce(
    (acc: number, coin: LayeredBought) => acc + coin.invested!,
    0
  );

  return { btcSpent, coinsBought };
};

export const getCoinsSoldAndCapitalGained = (
  allSoldCoins: LayeredBoughtDetails
) => {
  const coinsSold: number = allSoldCoins.reduce(
    (acc: number, coin: LayeredBought) => acc + coin.invested! / coin.buyPrice,
    0
  );

  const btcGained: number = allSoldCoins?.reduce(
    (acc: number, coin: LayeredBought) =>
      acc + (coin.invested! + (coin.profitSecured! / 100) * coin.invested!),
    0
  );
  return { btcGained, coinsSold };
};

export const getBEPriceForNotSoldCoins = (
  currentPrice: number,
  layeredBuyBoughts: LayeredBoughtDetails = []
) => {
  const allUnsoldCoins = layeredBuyBoughts.filter((layers) => !layers.sellTime);

  const { btcSpent, coinsBought } =
    getcoinsBoughtAndCapitalSpent(allUnsoldCoins);

  const breakEven = btcSpent / coinsBought;

  return +breakEven.toFixed(8);
};

export const getBreakEvenPrice = (
  currentPrice: number,
  layeredBuyBoughts: LayeredBoughtDetails = []
) => {
  const allSoldCoins = layeredBuyBoughts.filter((layers) => layers.sellTime);
  const { btcGained, coinsSold } = getCoinsSoldAndCapitalGained(allSoldCoins);
  const { btcSpent, coinsBought } =
    getcoinsBoughtAndCapitalSpent(layeredBuyBoughts);
  const breakEven = (btcSpent - btcGained) / (coinsBought - coinsSold);

  return +breakEven.toFixed(8);
};

type InvestmentConfig = {
  regular: {
    [key: number]: number | { default: number; skipAmount: number };
  };
  capitulation: {
    [key: number]: number | { default: number; skipAmount: number };
  };
};

let currentInvestmentConfig: InvestmentConfig = {
  regular: {
    1: 5,
    2: 10,
    3: { default: 25, skipAmount: 30 },
    4: 40,
    5: 35,
  },
  capitulation: {
    1: 0,
    2: 5,
    3: { default: 25, skipAmount: 30 },
    4: 40,
    5: 35,
  },
};

export const setInvestmentConfig = (config: InvestmentConfig) => {
  currentInvestmentConfig = config;
};

export const getInvestmentConfig = () => {
  const config = Object.entries(currentInvestmentConfig.capitulation)
    .map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        return `L${key}:${value.default}/${value.skipAmount}`;
      }
      return `L${key}:${value}`;
    })
    .join(' ');

  return config;
};

export const getInvestmentAmount = (
  freeBalance: number,
  buyPercBelow: number,
  alertPercentage: number,
  noOfLayersToSkip: number,
  marketCycle: MarketCycle
) => {
  const layer = getLayerNumbering(
    buyPercBelow,
    noOfLayersToSkip,
    alertPercentage
  );

  const config =
    marketCycle === MarketCycle.capitulation
      ? currentInvestmentConfig.capitulation
      : currentInvestmentConfig.regular;

  let amountToInvest: number;
  const layerConfig = config[layer];

  if (typeof layerConfig === 'object' && layerConfig !== null) {
    // Handle special case for layer with skip amount
    amountToInvest =
      noOfLayersToSkip >= 2 ? layerConfig.skipAmount : layerConfig.default;
  } else {
    // Handle regular layers
    amountToInvest = layerConfig === undefined ? 10 : (layerConfig as number); // Only default to 10% if undefined
  }

  let investedInDollar = +(amountToInvest * 0.01 * freeBalance).toFixed(5);

  if (investedInDollar >= 20000 && layer === 1) {
    investedInDollar = 20000;
  }
  if (investedInDollar >= 50000 && layer === 2) {
    investedInDollar = 50000;
  }

  return investedInDollar;
};

// Create a type that ensures stoplossPrice exists
export type BoughtWithStoploss = LayeredBought & {
  stoplossPrice: number; // Make this required, not optional
  sellTime: number; // We know this exists too
  invested: number; // And this
  buyPrice: number; // And this
};

export const calculateProfitSplit = (
  buyDrawdown: number,
  drawdownPercentage: number
) => {
  // Drawdown range: 0% (top) to 100% (bottom)
  // Profit retention range: 40% (at top) to 80% (at bottom)
  const MIN_RETENTION = 40;
  const MAX_RETENTION = 80;
  const retentionRange = MAX_RETENTION - MIN_RETENTION;

  // Calculate how close the buy is to the market bottom
  // If buyDrawdown is close to drawdownPercentage, this will be close to 1
  // If buyDrawdown is far from drawdownPercentage, this will be close to 0
  const proximityToBottom =
    1 - Math.abs(drawdownPercentage - buyDrawdown) / drawdownPercentage;

  // Calculate retention percentage based on proximity to bottom
  const retentionPerc = MIN_RETENTION + retentionRange * proximityToBottom;

  return {
    piggyBankPerc: retentionPerc,
    tradePerc: 100 - retentionPerc,
  };
};

export const handlePiggyBankSplit = (
  buys: BoughtWithStoploss,
  fibLevels: FibLevels
): PiggyBankPosition | null => {
  if (buys.buyOrderType !== BuyOrderType.piggyBankEligible) {
    return null;
  }

  const { invested, buyPrice, stoplossPrice } = buys;
  const { ATH, drawdownPercentage } = fibLevels;
  const buyDrawdown = Number(((ATH - buyPrice) / ATH) * 100);
  const totalProfit = (stoplossPrice - buyPrice) * (invested / buyPrice);
  const isRealTrade =
    Boolean(buys.realBuyOrderPlaced) &&
    buys.realBuyOrderStatus === 'closed' &&
    Boolean(buys.sellTime) === false;
  // If we're at a loss, return original buy order without splitting
  if (totalProfit <= 0) {
    return null;
  }

  // Calculate dynamic profit split based on drawdown
  const { piggyBankPerc } = calculateProfitSplit(
    buyDrawdown,
    drawdownPercentage
  );

  // Split only the profit
  const reservedProfitAmount = totalProfit * (piggyBankPerc / 100);
  const reservedCoins = reservedProfitAmount / buys.stoplossPrice;

  const notation =
    (buys.pairID.split('-').pop()?.toUpperCase() as TradeNotations) || '';

  return {
    __typename: 'PiggyBankPosition',
    id: `PiggyBank-${buys.id}`,
    sourceOrderId: buys.id,
    pairID: buys.pairID,
    notation,
    totalCoins: reservedCoins,
    isRealPiggyBankTrade: isRealTrade,
    invested: reservedProfitAmount,
    soldCoins: 0,
    status: OrderStatus.open,
    entryPrice: buys.stoplossPrice,
    baseType: 'piggyBank',
    createdAt: buys.sellTime.toString(),
    updatedAt: buys.sellTime.toString(),
  };
};

export const getPiggyBankStats = (
  coins: {
    piggyBankPositions: PiggyBankPosition[];
    currentPrice: number;
  }[]
) => {
  let totalInvested = 0;
  let currentValue = 0;

  coins.forEach(({ piggyBankPositions, currentPrice }) => {
    piggyBankPositions
      .filter((position) => position.status === OrderStatus.open)
      .forEach((position) => {
        totalInvested += position.invested;
        currentValue += position.totalCoins * currentPrice;
      });
  });

  return {
    totalInvested: +totalInvested.toFixed(2),
    currentValue: +currentValue.toFixed(2),
  };
};

type FibOrderPlacement = {
  price: number;
  fibLevel: string;
  percentageOfCoins: number;
  color: string;
};

const getFibOrderPlacements = (
  price702: number,
  fibLevels: FibLevels
): FibOrderPlacement[] => {
  return [
    {
      price: price702 * 0.9, // 10% below 0.702
      fibLevel: '0.702',
      percentageOfCoins: 50,
      color: FIB_ORDER_PLACEMENTS['0.702'].color,
    },
    {
      price: fibLevels['1.618'],
      fibLevel: '1.618',
      percentageOfCoins: 10,
      color: FIB_ORDER_PLACEMENTS['1.618'].color,
    },
    {
      price: fibLevels['2.618'],
      fibLevel: '2.618',
      percentageOfCoins: 8,
      color: FIB_ORDER_PLACEMENTS['2.618'].color,
    },
    {
      price: fibLevels['3.618'],
      fibLevel: '3.618',
      percentageOfCoins: 19.2,
      color: FIB_ORDER_PLACEMENTS['3.618'].color,
    },
    {
      price: fibLevels['4.236'],
      fibLevel: '4.236',
      percentageOfCoins: 12.8,
      color: FIB_ORDER_PLACEMENTS['4.236'].color,
    },
  ];
};

type FibOrdersResult = {
  orders: LayeredBought[];
  updatedPiggyBankPositions: PiggyBankPosition[];
};

export const createFibLevelOrders = (
  piggyBankPositions: PiggyBankPosition[],
  fibLevels: FibLevels,
  coinID: string,
  notation: TradeNotations,
  currentTime: number
): FibOrdersResult => {
  const activePositions = piggyBankPositions.filter(
    (position) => position.status === OrderStatus.open
  );

  if (activePositions.length === 0) {
    return {
      orders: [],
      updatedPiggyBankPositions: piggyBankPositions,
    };
  }

  // Separate active positions into real and paper trades
  const realTradePositions = activePositions.filter(
    (position) => position.isRealPiggyBankTrade
  );
  const paperTradePositions = activePositions.filter(
    (position) => !position.isRealPiggyBankTrade
  );

  // Process each group separately
  const processPositions = (
    positions: PiggyBankPosition[]
  ): LayeredBought[] => {
    if (positions.length === 0) return [];

    const totalCoins = positions.reduce(
      (sum, position) => sum + position.totalCoins,
      0
    );

    const weightedEntryPrice = positions.reduce(
      (sum, position) =>
        sum + (position.entryPrice * position.totalCoins) / totalCoins,
      0
    );

    const orderPlacements = getFibOrderPlacements(
      fibLevels['0.702'],
      fibLevels
    );

    return orderPlacements.map(
      (placement, index): LayeredBought => ({
        id: `${coinID}-${notation}-fib-${placement.fibLevel}-${index}-${currentTime}`,
        pairID: `${coinID}-${notation}`,
        buyOrderType: BuyOrderType.piggyBankFibOrder,
        fibLevel: placement.fibLevel,
        buyTime: currentTime,
        bidTime: currentTime,
        originalBuyPrice: weightedEntryPrice,
        buyPrice: weightedEntryPrice,
        buyPercent: 0,
        invested:
          (placement.percentageOfCoins / 100) * totalCoins * weightedEntryPrice,
        buyColor: placement.color,
        highPrice: placement.price,
        piggyBankSourcePosition: positions[0],
        isRealPiggyBankTrade: positions[0].isRealPiggyBankTrade, // Inherit isRealTrade from source position
      })
    );
  };

  // Process both types of trades
  const realOrders = processPositions(realTradePositions);
  const paperOrders = processPositions(paperTradePositions);

  // Update all positions to mark as used
  const updatedPiggyBankPositions = piggyBankPositions.map((position) => {
    if (position.status === OrderStatus.open) {
      return {
        ...position,
        soldCoins: position.totalCoins,
        status: OrderStatus.closed,
      };
    }
    return position;
  });

  return {
    orders: [...realOrders, ...paperOrders],
    updatedPiggyBankPositions,
  };
};

const getPiggyBankStoplossPrice = (
  close: number,
  buyPrice: number,
  profitSecured: number | null,
  fibLevel: number,
  fibLevels: FibLevels
) => {
  const fibLevelKey = fibLevel.toString() as keyof typeof fibLevels;

  if (fibLevels[fibLevelKey] > close) {
    return { stopLossPrice: buyPrice, stopLossPerc: 0 };
  }

  const actualProfitPerc = ((close - buyPrice) / buyPrice) * 100;

  // Don't set stoploss until minimum profit threshold is met
  if (actualProfitPerc < PIGGY_BANK_MIN_PROFIT_FOR_STOPLOSS) {
    return { stopLossPrice: buyPrice, stopLossPerc: 0 };
  }

  // If no previous stoploss exists, set initial stoploss
  if (!profitSecured) {
    const stopLossPrice = close * (1 - PIGGY_BANK_INITIAL_STOPLOSS / 100);
    const stopLossPerc = ((stopLossPrice - buyPrice) / buyPrice) * 100;
    return { stopLossPrice, stopLossPerc };
  }

  // For existing stoploss, implement trailing logic
  const newStopLossPrice = close * (1 - PIGGY_BANK_TRAILING_STOPLOSS_GAP / 100);
  const newStopLossPerc = ((newStopLossPrice - buyPrice) / buyPrice) * 100;

  // Only update if new stoploss would be higher than existing
  if (newStopLossPerc > profitSecured) {
    return { stopLossPrice: newStopLossPrice, stopLossPerc: newStopLossPerc };
  }

  // Keep existing stoploss
  const existingStopLossPrice = buyPrice * (1 + profitSecured / 100);
  return { stopLossPrice: existingStopLossPrice, stopLossPerc: profitSecured };
};
