import React, { useState, useMemo } from 'react';
import { Coin, OrderBids, PairInfo } from '../../../API';
import {
  areBuysInTradeAndHasStoploss,
  isRealBoughtOrder,
} from '../../../chart-specifics/coinCalculation';
import { DashboardCoin, SortAlertArgs, SortTradeArgs } from '../../../types';
import { getBEPriceForNotSoldCoins } from '../../../utils/calculations/percentageAndprice';
import { ReactTable } from './ReactTable';
import {
  defaulthideColumnsOnMobile,
  getNoOfAlertTriggered,
  getNoOfTradesOn,
  sortAlerts,
  sortTrades,
} from './coinsTableParts';
import { preloadSparklineData, CACHE_DURATION } from '../../../api/apiCalls';
import { useMediaQuery } from 'react-responsive';

type CoinsTableProps = {
  istradePairBtc: boolean;
  untransformedData: Coin[];
  activateDontTradeForCoin: () => void;
  hideInactivePairs: boolean;
  lastUpdated: number | undefined;
};

export const transformCoinsTableData = (
  istradePairBtc: boolean,
  allCoinData: Coin[],
  hideInactivePairs: boolean
): DashboardCoin[] => {
  let i = 0;
  const coin: DashboardCoin[] = [];
  const allCoinsData: DashboardCoin[] = [];
  const notation = istradePairBtc ? 'btc' : 'usd';
  const alternateNotation = istradePairBtc ? 'usd' : 'btc';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.values(allCoinData).forEach((data: any) => {
    const tradePair = data.pairs.items.filter(
      (pair: PairInfo) => pair.id === `${data.id}-${notation}`
    )[0];

    if (hideInactivePairs && tradePair?.dontTrade) {
      return;
    }

    const alternatePair = data.pairs.items.filter(
      (pair: PairInfo) => pair.id === `${data.id}-${alternateNotation}`
    )[0];

    if (!tradePair || !alternatePair) {
      console.log(`could not find trade pair for ${data.id}-${notation}
      `);
      return;
    }

    const { rank, id, symbol } = data;

    const { currentPrice: currentAlternatePrice } = alternatePair;
    const {
      currentPrice,
      relativeHigh,
      higestPrice,
      layeredBuyBids,
      layeredBuyBoughts,
      dontTrade,
    } = tradePair;

    const boughtOrders = layeredBuyBoughts?.items;
    const buyOrders = layeredBuyBids?.items;

    const fromATH = +(
      ((higestPrice - currentPrice) / higestPrice) *
      100
    ).toFixed(2);

    const fromFourMonthATH = +(
      ((relativeHigh - currentPrice) / relativeHigh) *
      100
    ).toFixed(2);

    const { isCurrentlyInTrade, hasStopLossPrice, hasRealOrder, finalTrade } =
      areBuysInTradeAndHasStoploss(boughtOrders);

    const buyBoughts = hasRealOrder
      ? boughtOrders?.filter(isRealBoughtOrder)
      : boughtOrders;

    const breakEvenPrice = getBEPriceForNotSoldCoins(currentPrice, buyBoughts);

    const stopLossPerc =
      +((currentPrice - breakEvenPrice) / breakEvenPrice) * 100;

    const hasPiggyBankEligible = buyOrders?.some(
      (bid: OrderBids) => bid.buyOrderType === 'piggyBankEligible'
    );

    const hasPiggyBankFibOrder = buyOrders?.some(
      (bid: OrderBids) => bid.buyOrderType === 'piggyBankFibOrder'
    );

    coin[i] = {
      rank,
      coinDetails: [id, symbol, dontTrade || null],
      currentPrice,
      currentAlternatePrice,
      fromFourMonthATH,
      relativeHigh,
      fromATH,
      higestPrice,
      alerts: {
        hasAlertTriggered: Boolean(buyOrders && buyOrders.length),
        hasStoploss: Boolean(
          buyOrders && buyOrders.some((bids: OrderBids) => bids.stoploss)
        ),
        piggyBankEligible: hasPiggyBankEligible,
        piggyBankFibOrder: hasPiggyBankFibOrder,
      },
      tradeDetails: {
        isCurrentlyInTrade,
        hasStopLossPrice,
        stopLossPerc: stopLossPerc || -Infinity,
        hasRealOrder,
        finalTrade,
      },
      symbol,
    };
    allCoinsData.push(coin[i]);
    i += 1;
  });

  return allCoinsData;
};

export const CoinsTable: React.FC<CoinsTableProps> = ({
  istradePairBtc,
  untransformedData,
  activateDontTradeForCoin,
  hideInactivePairs,
  lastUpdated,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const transformedCoins = React.useMemo(
    () =>
      transformCoinsTableData(
        istradePairBtc,
        untransformedData,
        hideInactivePairs
      ),
    [istradePairBtc, untransformedData, hideInactivePairs]
  );

  // Preload sparkline data with a delay to avoid rate limits
  React.useEffect(() => {
    if (transformedCoins) {
      const coinIds = transformedCoins.map((coin) => coin.coinDetails[0]);

      // Initial load
      preloadSparklineData(coinIds, lastUpdated);

      // Set up interval to check cache expiration
      const interval = setInterval(() => {
        if (lastUpdated) {
          const nextUpdateTime = lastUpdated + CACHE_DURATION;
          const now = Date.now();

          if (now >= nextUpdateTime) {
            console.log('Cache expired...');
            preloadSparklineData(coinIds, lastUpdated);
            clearInterval(interval); // Stop checking after cache is cleared
          }
        }
      }, 60000); // Check every minute

      return () => clearInterval(interval);
    }
  }, [transformedCoins, lastUpdated]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Coin',
        columns: [
          {
            Header: 'Rank',
            accessor: 'rank',
            width: 50,
          },
          {
            Header: 'Name',
            accessor: 'coinDetails',
            className: 'marketName',
            width: 110,
          },
          {
            Header: 'Price',
            accessor: 'currentPrice',
            width: 200,
          },
          {
            Header: '7D Trend',
            accessor: (row: any) => ({
              coinId: row.coinDetails[0],
              symbol: row.symbol,
            }),
            width: 150,
            sortType: (rowA: any, rowB: any) => {
              const getPercentChange = (coinId: string) => {
                try {
                  const cached = localStorage.getItem(`sparkline_${coinId}`);
                  if (cached) {
                    const { data } = JSON.parse(cached);
                    if (!data?.length) return 0;
                    const firstPrice = data[0];
                    const lastPrice = data[data.length - 1];
                    return ((lastPrice - firstPrice) / firstPrice) * 100;
                  }
                } catch (error) {
                  console.warn('Failed to read cache for sorting:', error);
                }
                return 0;
              };

              const changeA = getPercentChange(rowA.original.coinDetails[0]);
              const changeB = getPercentChange(rowB.original.coinDetails[0]);

              return changeA - changeB;
            },
          },
          {
            Header: 'Local High Info',
            accessor: (row: any) => ({
              percentage: row.fromFourMonthATH,
              price: row.relativeHigh,
            }),
            sortType: (rowA: any, rowB: any) => {
              return (
                rowA.original.fromFourMonthATH - rowB.original.fromFourMonthATH
              );
            },
            width: 160,
          },
          {
            Header: 'ATH Info',
            accessor: (row: any) => ({
              percentage: row.fromATH,
              price: row.higestPrice,
            }),
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.fromATH - rowB.original.fromATH;
            },
            width: 160,
          },
        ],
      },
      {
        Header: 'Extra',
        columns: [
          {
            Header: transformedCoins && getNoOfAlertTriggered(transformedCoins),
            accessor: 'alerts',
            width: 160,
            sortType: (
              a: SortAlertArgs,
              b: SortAlertArgs,
              columnId: string,
              desc: number
            ) => sortAlerts(a, b, columnId, desc),
          },
          {
            Header: () => getNoOfTradesOn(transformedCoins, isMobile),
            accessor: 'tradeDetails',
            width: 170,
            sortType: (
              a: SortTradeArgs,
              b: SortTradeArgs,
              columnId: string,
              desc: number
            ) => sortTrades(a, b, columnId, desc),
          },
          {
            Header: 'Action',
            accessor: 'action',
            width: 60,
          },
        ],
      },
    ],
    [transformedCoins, isMobile]
  );

  return (
    <div className="col-lg-10 coinholdings">
      <ReactTable
        columns={columns}
        data={transformedCoins}
        sortAccessor="tradeDetails"
        istradePairBtc={istradePairBtc}
        descending
        hideColumnsOnMobile={defaulthideColumnsOnMobile}
      />
    </div>
  );
};
