/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
// create a new react component
import React, { useState, useEffect, useRef } from 'react';
import { BarData, ISeriesApi, Time, createChart } from 'lightweight-charts';
import { Grid } from 'semantic-ui-react';
import { DataProp, TradeNotations } from '../../types';
import AwsDatabase from '../../database/Database';
import {
  getTickerData,
  showTradesAndBreakeven,
} from '../../chart-specifics/linesAndMarkers';

import { makeEachCandleTickChecks } from '../../chart-specifics/coinCalculation';

import { getHumanReadableDate } from '../../utils/calculations/timeCalculation';
import {
  BackTestCoin,
  calculateGainLossPercentage,
  fetchCoinDetails,
  getTradeStatistics,
  isEveryCoinLengthSame,
  millisecToSec,
} from '../../backtest-node/utils';
import {
  timeScale,
  chartLayoutStyle,
  chartSeriesOptions,
} from '../../chart-specifics/create-chart';
import { BackTestStats } from '../BackTest/BackTestStats';
import {
  ACC_START_LAYER_TO_SKIP,
  CHART_TO_SHOW,
  ALL_COINS_TO_TEST,
  FIRST_BEAR_ACCUMULATION_START_DATE,
  FIRST_BEAR_END_DATE,
  FIRST_BEAR_SELL_OFF_START_DATE,
  FIRST_BEAR_START_DATE,
  FIRST_SELL_OFF_START_LAYER_TO_SKIP,
  INITIAL_BALANCE,
  LAYERS_TO_SKIP,
  NOTATION,
  SEC_BEAR_ACCUMULATION_START_DATE,
  SEC_BEAR_END_DATE,
  SEC_BEAR_SELL_OFF_START_DATE,
  SEC_BEAR_START_DATE,
  SHOW_CHART_AT_ONCE,
  SHOW_TABLE,
  TIME_TO_TRADE,
  SEC_BULL_MARKET_TOP,
  SEC_BULL_STARTS,
  MARKET_CYCLE,
  COLLECT_FREE_COINS,
} from '../../config/backTestConfig';
import { LayerOption, Percentages } from '../../types/backTest';
import {
  addToSkippedLayers,
  calculateSelectedLayerDetails,
  filterWithShowOption,
  getMarketInfo,
  getSelectedCoinDetails,
  getSelectedCoinDetailsForTable,
  logMarketData,
  playChimeSound,
} from '../../utils/backTestUtils';
import { BackTestTable } from '../BackTest/BackTestTable';
import { getPiggyBankStats } from '../../utils/calculations/percentageAndprice';
import {
  logMemoryUsage,
  logPerformance,
} from '../../utils/performanceMonitoring';

const AwsDB = new AwsDatabase();

const START_DATE = SEC_BEAR_START_DATE;
const END_DATE = SEC_BEAR_END_DATE;
// 1660860000 // 19 aug 2022
// const START_DATE = 1651356000; // SEC_BEAR_START_DATE; // 1647298800
// const END_DATE = 1712786400; // SEC_BEAR_END_DATE;

const SELLOFF_STARTS =
  START_DATE < 1619827200
    ? FIRST_BEAR_SELL_OFF_START_DATE
    : SEC_BEAR_SELL_OFF_START_DATE;
const ACCUMULATION_STARTS =
  START_DATE < 1619827200
    ? FIRST_BEAR_ACCUMULATION_START_DATE
    : SEC_BEAR_ACCUMULATION_START_DATE;

const MultiCoinBackTest: React.FC = () => {
  const chartRef = React.createRef<HTMLDivElement>();
  const mainBalanceRef = useRef(INITIAL_BALANCE);
  const prevLowBalanceRef = useRef(0);
  const [timerInMs] = useState(4);
  const [gainLossPercentage, setGainLossPercentage] = useState<Percentages>({});
  const [currentDate, setCurrentDate] = useState<number>();
  const [candlestickSeries, setCandlestickSeries] =
    useState<ISeriesApi<'Candlestick'>>();
  const [showOption, setShowOption] = useState<LayerOption>('all');
  const [isBacktestDone, setIsBacktestDone] = useState(false);
  const [coinDetails, setCoinDetails] = useState<Record<string, BackTestCoin>>(
    {}
  );
  const [filteredCoinDetails, setFilteredCoinDetails] = useState<
    Record<string, BackTestCoin> | undefined
  >(undefined);

  const tradeStatistics = getTradeStatistics(coinDetails);

  useEffect(() => {
    const fetchCoinDetailsAsync = async () => {
      const coinDetailsWithTrimmedData = await fetchCoinDetails(
        ALL_COINS_TO_TEST,
        AwsDB,
        NOTATION,
        START_DATE,
        END_DATE
      );

      setCoinDetails(coinDetailsWithTrimmedData);
    };

    fetchCoinDetailsAsync();

    return () => {};
  }, []);

  useEffect(() => {
    if (CHART_TO_SHOW === null) {
      return;
    }
    if (chartRef.current && Object.keys(coinDetails).length !== 0) {
      const chart = createChart(chartRef.current, {
        width: chartRef.current.clientWidth,
        height: 800,
        timeScale,
      });
      chart.applyOptions({ ...chartLayoutStyle });
      const series = chart.addCandlestickSeries();
      series.applyOptions({ ...chartSeriesOptions });
      setCandlestickSeries(series);
    }
  }, [coinDetails]);

  useEffect(() => {
    if (SHOW_CHART_AT_ONCE && candlestickSeries && CHART_TO_SHOW) {
      const allTicker = coinDetails[CHART_TO_SHOW]?.tickers.map(
        (ticker: BarData & { unix: number }) => ({
          time: ticker.unix as Time,
          open: ticker.open,
          high: ticker.high,
          low: ticker.low,
          close: ticker.close,
        })
      );
      candlestickSeries.setData(allTicker);
    }
  }, [candlestickSeries, coinDetails]);

  useEffect(() => {
    if (isBacktestDone || SHOW_CHART_AT_ONCE) {
      console.log('backtest done');
      return undefined;
    }
    let counter = 0;
    const startTime = Date.now();
    let skippedLayers = '';

    console.log('perf backtest starts', new Date(startTime).toLocaleString());
    let backTestInterval: number | NodeJS.Timeout | undefined;

    if (
      chartRef.current &&
      mainBalanceRef.current &&
      Object.keys(coinDetails).length !== 0
    ) {
      // check if every coin has same length of tickets
      const everyCoinLengthSame = isEveryCoinLengthSame(coinDetails);

      if (!everyCoinLengthSame) {
        console.log('ERROR!!! length of tickers is not same');
        throw new Error('ERROR!!! length of tickers is not same');
      }

      backTestInterval = setInterval(async () => {
        for (const coin of ALL_COINS_TO_TEST) {
          const coinDetail = coinDetails[coin];

          if (!coinDetail) {
            return;
          }

          const {
            Coin,
            tickers,
            highPrice,
            lowFromHighPrice,
            layeredBuyBids,
            layeredBuyBoughts,
            alertPrice,
            hasAlertTriggered,
            highsAndLows,
            higestPrice,
            linesInChart,
            percentageStatistics,
            hasBreakEvenStoploss,
            piggyBankPositions,
            fibLevels,
          } = coinDetail;
          const sanitizedData = getTickerData(tickers[counter]);

          const { layersToSkip, marketPhase } = getMarketInfo(
            sanitizedData.time as number,
            LAYERS_TO_SKIP,
            FIRST_SELL_OFF_START_LAYER_TO_SKIP,
            ACC_START_LAYER_TO_SKIP
          );

          if (candlestickSeries) {
            if (coin === CHART_TO_SHOW && !SHOW_CHART_AT_ONCE) {
              candlestickSeries.update(sanitizedData);
            }
            if (!SHOW_CHART_AT_ONCE) {
              candlestickSeries.update(sanitizedData);
            }
          }

          // 5% of initial capital (200K)
          const minimumNeededBalance = NOTATION === 'usd' ? 10000 : 0.1;

          if (
            prevLowBalanceRef.current !== mainBalanceRef.current &&
            mainBalanceRef.current < minimumNeededBalance
          ) {
            const date = getHumanReadableDate(sanitizedData.time as number);
            prevLowBalanceRef.current = mainBalanceRef.current;
            console.log(
              `balance is too low - balance ${mainBalanceRef.current} time ${date}`
            );
          }

          if (counter !== tickers.length) {
            const param = {
              coinID: Coin.id,
              notation: NOTATION as TradeNotations,
              sanitizedData: sanitizedData as unknown as DataProp,
              highPrice: highPrice || sanitizedData.high,
              lowFromHighPrice: lowFromHighPrice || sanitizedData.low,
              layeredBuyBids,
              layeredBuyBoughts,
              initialBalance: mainBalanceRef.current,
              reservedBalance: 0,
              alertPrice,
              alertTriggered: hasAlertTriggered,
              percentageStatistics,
              hasBreakEvenStoploss,
              saveInDB: false,
              higestPrice: higestPrice,
              highsAndLows,
              paperTrade: true,
              balances: {
                btc: { free: 0, total: 0, real: 0 },
                usd: { free: 0, total: 0, real: 0 },
              },
              noOfLayersToSkip: layersToSkip,
              timeSinceLastUpdate: 1,
              linesInChart,
              marketCycle: marketPhase,
              collectFreeCoins: COLLECT_FREE_COINS,
              piggyBankPositions,
              fibLevels,
              candlestickSeries:
                coin === CHART_TO_SHOW ? candlestickSeries : undefined,
            };

            const {
              highPriceForCoin,
              lowFromHighPriceForCoins,
              layeredBuyBidsForCoin,
              layeredBuyBoughtsForCoin,
              finalInitialBalance,
              finalReservedBalance,
              alertPriceForCoin,
              hasBreakEvenStoplossForCoin,
              piggyBankPositionsForCoin,
            } = await makeEachCandleTickChecks(param);

            coinDetails[coin] = {
              ...coinDetail,
              currentPrice: sanitizedData.close,
              highPrice: highPriceForCoin,
              alertPrice: alertPriceForCoin,
              lowFromHighPrice: lowFromHighPriceForCoins,
              layeredBuyBoughts: layeredBuyBoughtsForCoin,
              layeredBuyBids: layeredBuyBidsForCoin,
              hasBreakEvenStoploss: hasBreakEvenStoplossForCoin,
              piggyBankPositions: piggyBankPositionsForCoin,
            };

            if (coin === CHART_TO_SHOW && candlestickSeries) {
              showTradesAndBreakeven(
                candlestickSeries,
                finalInitialBalance,
                finalReservedBalance,
                NOTATION,
                layeredBuyBoughts,
                sanitizedData,
                hasBreakEvenStoploss,
                linesInChart,
                highsAndLows
              );
            }

            mainBalanceRef.current = finalInitialBalance;

            // Calculate gain/loss percentage right after updating the balance
            const newGainLossPercentage = calculateGainLossPercentage(
              mainBalanceRef.current,
              INITIAL_BALANCE,
              coinDetails
            );
            setGainLossPercentage(newGainLossPercentage); // This will trigger a re-render

            setCoinDetails(coinDetails);
          }
        }

        const firstCoinsDetails = Object.values(coinDetails)[0];
        const { tickers } = firstCoinsDetails;
        const sanitizedData = getTickerData(tickers[counter]);
        setCurrentDate(sanitizedData.time as number);
        const { layersToSkip, marketPhase } = getMarketInfo(
          sanitizedData.time as number,
          LAYERS_TO_SKIP,
          FIRST_SELL_OFF_START_LAYER_TO_SKIP,
          ACC_START_LAYER_TO_SKIP
        );
        skippedLayers = addToSkippedLayers(skippedLayers, layersToSkip);

        logMarketData(
          sanitizedData,
          coinDetails,
          mainBalanceRef.current,
          skippedLayers,
          SELLOFF_STARTS,
          ACCUMULATION_STARTS,
          END_DATE
        );

        if (TIME_TO_TRADE === 'day') {
          counter += 24;
        } else {
          counter += 1;
        }

        if (counter >= tickers.length) {
          console.log('backtest done');
          console.log(
            'perf  time taken in  sec',
            millisecToSec(Date.now() - startTime),
            new Date(Date.now()).toLocaleString()
          );
          window.clearInterval(backTestInterval);

          playChimeSound();

          setIsBacktestDone(true);
        }

        // Add performance logging
        // logMemoryUsage(counter, tickers.length);
        // logPerformance(counter, tickers.length);
      }, timerInMs);
    }

    return () => {
      if (backTestInterval) {
        clearInterval(backTestInterval);
      }
    };
  }, [coinDetails, timerInMs, candlestickSeries]);

  useEffect(() => {
    const filtered = filterWithShowOption(coinDetails, showOption, NOTATION);

    setFilteredCoinDetails(getSelectedCoinDetails(filtered, coinDetails));
  }, [showOption]);

  const selectedCoinDetails = getSelectedCoinDetails(
    filteredCoinDetails,
    coinDetails
  );

  const selectedCoinDetailsForTable = getSelectedCoinDetailsForTable(
    filteredCoinDetails,
    coinDetails
  );

  const selectedLayerDetails =
    calculateSelectedLayerDetails(selectedCoinDetails);

  const piggyBankStats = getPiggyBankStats(
    Object.values(coinDetails).map((coin) => ({
      piggyBankPositions: coin.piggyBankPositions || [],
      currentPrice: coin.currentPrice || 0,
    }))
  );

  return (
    <div className="backtest-multi">
      <div ref={chartRef} />
      <Grid divided="vertically" padded relaxed>
        <BackTestStats
          initialBalance={INITIAL_BALANCE}
          currentBalance={mainBalanceRef.current}
          gainLossPercentage={gainLossPercentage}
          tradeStatistics={tradeStatistics}
          currentDate={currentDate}
          isBacktestDone={isBacktestDone}
          setShowOption={setShowOption}
          notation={NOTATION}
          selectedLayerDetails={selectedLayerDetails}
          piggyBankStats={piggyBankStats}
        />
        {SHOW_TABLE && (
          <BackTestTable selectedCoinDetails={selectedCoinDetailsForTable} />
        )}
      </Grid>
    </div>
  );
};

export default MultiCoinBackTest;
