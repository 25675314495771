import React, { useEffect, useState, memo } from 'react';
import { getSparklinePoints } from '../../../api/apiCalls';

type SparklineProps = {
  coinId: string;
  width?: number;
  height?: number;
};

export const Sparkline: React.FC<SparklineProps> = memo(
  ({ coinId, width = 100, height = 30 }) => {
    const [points, setPoints] = useState<number[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        const data = await getSparklinePoints(coinId);
        if (data) {
          setPoints(data);
        }
      };

      fetchData();
    }, [coinId]);

    if (!points.length) return null;

    const min = Math.min(...points);
    const max = Math.max(...points);
    const range = max - min;

    // Calculate percentage change
    const firstPrice = points[0];
    const lastPrice = points[points.length - 1];
    const percentChange = ((lastPrice - firstPrice) / firstPrice) * 100;
    const isPositive = percentChange >= 0;

    const pathPoints = points.map((p, i) => {
      const x = (i / (points.length - 1)) * width;
      const y = height - ((p - min) / range) * height;
      return `${x},${y}`;
    });

    const path = `M ${pathPoints.join(' L ')}`;
    const color = isPositive ? '#21ba45' : '#db2828';

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <svg width={width} height={height}>
          <path d={path} fill="none" stroke={color} strokeWidth="1" />
        </svg>
        <span
          style={{
            color,
            fontSize: '0.85em',
            fontWeight: 500,
            minWidth: '50px',
          }}
        >
          {percentChange > 0 ? '+' : ''}
          {percentChange.toFixed(1)}%
        </span>
      </div>
    );
  }
);

Sparkline.displayName = 'Sparkline';
