import fs from 'fs';
import path from 'path';
import { promisify } from 'util'; // Add this import
import {
  BackTestCoin,
  BackTestTableCoin,
  calculateGainLossPercentage,
  getTradeStatistics,
} from '../backtest-node/utils';
import {
  INITIAL_BALANCE,
  FIRST_BEAR_ACCUMULATION_START_DATE,
  FIRST_BEAR_SELL_OFF_START_DATE,
  FIRST_BEAR_END_DATE,
  SEC_BEAR_ACCUMULATION_START_DATE,
  SEC_BEAR_SELL_OFF_START_DATE,
  SEC_BEAR_END_DATE,
  SEC_BEAR_START_DATE,
} from '../config/backTestConfig';
import { LayerOption } from '../types/backTest';
import { DataPropWithTime, TradeNotations } from '../types';
import { getHumanReadableDate } from './calculations/timeCalculation';
import { formatNumberDE } from './utils';
import { getInvestmentConfig } from './calculations/percentageAndprice';
import { MarketCycle, OrderStatus } from '../API';
const writeFileAsync =
  typeof window === 'undefined' // Check if running in Node.js
    ? promisify(fs.writeFile) // Use Node.js fs
    : async (filePath: string, data: string) => {
        // Use the File API to write data in the browser
        const blob = new Blob([data], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filePath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

interface MarketInfo {
  layersToSkip: number;
  marketPhase: MarketCycle;
}

export const getMarketInfo = (
  currentTime: number,
  layersToSkip: number,
  firstSellOffStartLayerToSkip: number,
  accStartLayerToSkip: number
): MarketInfo => {
  // Sell-off periods
  if (
    (currentTime >= FIRST_BEAR_SELL_OFF_START_DATE &&
      currentTime <= FIRST_BEAR_ACCUMULATION_START_DATE) ||
    (currentTime >= SEC_BEAR_SELL_OFF_START_DATE &&
      currentTime <= SEC_BEAR_ACCUMULATION_START_DATE)
  ) {
    return {
      layersToSkip: firstSellOffStartLayerToSkip,
      marketPhase: MarketCycle.capitulation,
    };
  }

  // Accumulation periods
  if (
    (currentTime > FIRST_BEAR_ACCUMULATION_START_DATE &&
      currentTime <= FIRST_BEAR_END_DATE) ||
    (currentTime > SEC_BEAR_ACCUMULATION_START_DATE &&
      currentTime <= SEC_BEAR_END_DATE)
  ) {
    return {
      layersToSkip: accStartLayerToSkip,
      marketPhase: MarketCycle.accumulation,
    };
  }

  // Between bears period
  if (currentTime > FIRST_BEAR_END_DATE && currentTime < SEC_BEAR_START_DATE) {
    return {
      layersToSkip: 0,
      marketPhase: MarketCycle.bull,
    };
  }

  // Default case
  return {
    layersToSkip: layersToSkip,
    marketPhase: MarketCycle.bear,
  };
};

export const logWithColor = (message: string, color: 'red' | 'green') => {
  const colorCodes: Record<string, string> = {
    red: '\x1b[31m', // Red color
    green: '\x1b[32m', // Green color
  };
  const resetCode = '\x1b[0m';
  console.log(`${colorCodes[color]}%s${resetCode}`, message);
};

const appendRowToTable = async (logData: (string | number)[][]) => {
  const logFilePath = path.join(__dirname, '../backtest-node/log.html');
  const tableRow = `<tr>
    <td>${logData[1][1]}</td>
     <td>${getInvestmentConfig()}</td>
    <td style="${
      Number((logData[1][2] as string).replace('%', '')) > 0
        ? 'color: green;'
        : 'color: red;'
    }">${logData[1][2]}</td>
    <td style="${
      Number((logData[1][3] as string).replace('%', '')) > 0
        ? 'color: green;'
        : 'color: red;'
    }">${logData[1][3]}</td>
    <td>${formatNumberDE(Number(logData[1][4]))}</td>
    <td>${formatNumberDE(Number(logData[1][5]))}</td>
    <td>${logData[1][6]}</td>
    <td>${logData[1][7]}</td>
  </tr>`;

  // Read the existing log.html file
  const data = await fs.promises.readFile(logFilePath, 'utf8'); // Use promises

  // Append the new row inside the <tbody>
  const updatedData = data.replace(/(<tbody>[\s\S]*?<\/tbody>)/, (match) =>
    match.replace('</tbody>', `${tableRow}\n</tbody>`)
  );

  // Write the updated content back to the file
  try {
    await writeFileAsync(logFilePath, updatedData); // Await the write operation
    // console.log('Successfully appended to the log file.'); // Log success
  } catch (e) {
    console.error('Error writing to HTML file:', e);
  }
};

export const logBalance = async (
  message: string,
  coinDetails: Record<string, BackTestCoin>,
  mainBalanceRef: React.MutableRefObject<number> | number,
  initialBalance: number,
  customLayersToSkip: string | number
) => {
  const gainLossPercentage = calculateGainLossPercentage(
    typeof mainBalanceRef === 'object'
      ? mainBalanceRef.current
      : mainBalanceRef,
    initialBalance,
    coinDetails
  );
  const tradeStatistics = getTradeStatistics(coinDetails);

  // Calculate PiggyBank value
  const piggyBankTotal = Object.values(coinDetails).reduce((total, coin) => {
    const piggyBankValue = coin.piggyBankPositions
      .filter((pos) => pos.status === OrderStatus.open)
      .reduce((sum, pos) => sum + pos.totalCoins * coin.currentPrice, 0);
    return total + piggyBankValue;
  }, 0);

  const unlockedBalance =
    gainLossPercentage?.percentageChangeWithUnlocked?.toFixed(2);
  const fullBalance = gainLossPercentage?.PercChangeWithLockedAlso?.toFixed(2);

  const logData = [
    [
      'Message',
      'skipped',
      'Full Profit',
      'Unlocked Balance %',
      'Unlocked Balance',
      'locked balance',
      'PiggyBank',
      '% sold',
      'total trades',
    ],
    [
      message,
      customLayersToSkip,
      `${fullBalance}%`,
      `${unlockedBalance}%`,
      typeof mainBalanceRef === 'object'
        ? mainBalanceRef.current.toFixed(2)
        : mainBalanceRef.toFixed(2),
      gainLossPercentage.lockedBalance.toFixed(2),
      piggyBankTotal.toFixed(2),
      `${(tradeStatistics.percentageOfSoldTrades || 0).toFixed(0)}%`,
      tradeStatistics.totalBoughtTrades,
    ],
  ];

  console.table(logData);

  if (typeof window === 'undefined') {
    await appendRowToTable(logData);
  }
};

export const logMarketData = async (
  sanitizedData: DataPropWithTime,
  coinDetails: Record<string, BackTestCoin>,
  mainBalance: number,
  skippedLayers: string,
  selloffStarts: number,
  accumulationStarts: number,
  endDate: number
) => {
  if (
    sanitizedData.time === endDate
    //   ||
    // sanitizedData.time === selloffStarts ||
    // sanitizedData.time === accumulationStarts
  ) {
    const message =
      sanitizedData.time === selloffStarts
        ? `xx VIOLENT SELLOFF STARTED ${getHumanReadableDate(selloffStarts)}`
        : sanitizedData.time === accumulationStarts
        ? `xx ACCUMULATION PHASE STARTED ${getHumanReadableDate(
            accumulationStarts
          )}`
        : `BEAR MARKET END ${getHumanReadableDate(endDate)}`;
    await logBalance(
      message,
      coinDetails,
      mainBalance,
      INITIAL_BALANCE,
      skippedLayers
    );
  }
};

export const addToSkippedLayers = (
  skippedLayers: string,
  customLayersToSkip: number
) => {
  const lastSkippedLayer = skippedLayers.split(',').pop(); // Get the last value of skippedLayers
  let updatedSkippedLayers = skippedLayers;
  if (skippedLayers === '') {
    updatedSkippedLayers += customLayersToSkip.toString();
  } else if (lastSkippedLayer !== customLayersToSkip.toString()) {
    updatedSkippedLayers += `,${customLayersToSkip}`;
  }
  return updatedSkippedLayers;
};

const filterBasedOnLayer = (
  coins: Record<string, BackTestCoin>,
  color: string
) =>
  Object.entries(coins).reduce((acc, [key, coin]) => {
    acc[key] = {
      ...coin,
      layeredBuyBoughts: coin.layeredBuyBoughts.filter(
        (trade) => trade.buyColor === color
      ),
      layeredBuyBids: coin.layeredBuyBids.filter(
        (trade) => trade.buyColor === color
      ),
    };
    return acc;
  }, {} as Record<string, BackTestCoin>);

export const filterBasedOnIncomplete = (coins: Record<string, BackTestCoin>) =>
  Object.entries(coins).reduce((acc, [key, coin]) => {
    acc[key] = {
      ...coin,
      layeredBuyBids: [],
      layeredBuyBoughts: coin.layeredBuyBoughts.filter(
        (trade) => 'invested' in trade && !('sellTime' in trade)
      ),
    };
    return acc;
  }, {} as Record<string, BackTestCoin>);

export const filterBasedOnLoss = (coins: Record<string, BackTestCoin>) =>
  Object.entries(coins).reduce((acc, [key, coin]) => {
    acc[key] = {
      ...coin,
      layeredBuyBids: [],
      layeredBuyBoughts: coin.layeredBuyBoughts.filter(
        (trade) => trade.profitSecured !== undefined && trade.profitSecured! < 0
      ),
    };
    return acc;
  }, {} as Record<string, BackTestCoin>);

export const filterWithShowOption = (
  coins: Record<string, BackTestCoin>,
  options: LayerOption,
  NOTATION: TradeNotations
) => {
  const MINIMUM_TRADE_AMOUNT = {
    usd: 1000,
    btc: 0.01,
  };

  switch (options) {
    case 'all':
      return coins;
    case 'layer 1':
      return filterBasedOnLayer(coins, '#8012ed');
    case 'layer 2':
      return filterBasedOnLayer(coins, '#f82165');
    case 'layer 3':
      return filterBasedOnLayer(coins, '#cab301');
    case 'layer 4':
      return filterBasedOnLayer(coins, '#35fe4c');
    case 'layer 5':
      return filterBasedOnLayer(coins, '#079ade');
    case 'incomplete':
      return filterBasedOnIncomplete(coins);
    case 'loss':
      return filterBasedOnLoss(coins);
    case 'smallTrades':
      return Object.entries(coins).reduce((acc, [key, coin]) => {
        const minAmount = MINIMUM_TRADE_AMOUNT[NOTATION];

        acc[key] = {
          ...coin,
          layeredBuyBids: [],
          layeredBuyBoughts: coin.layeredBuyBoughts.filter(
            (trade) => trade.invested! < minAmount
          ),
        };
        return acc;
      }, {} as Record<string, BackTestCoin>);
    default:
      return coins;
  }
};

export const calculateSelectedLayerDetails = (
  selectedCoinDetails: Record<string, BackTestCoin>
) => {
  const noOfSelectedLayers = Object.values(selectedCoinDetails).reduce(
    (acc, coin) => acc + coin.layeredBuyBoughts.length,
    0
  );
  const totalProfitOfSelectedLayers = Object.values(selectedCoinDetails).reduce(
    (acc, coin) =>
      acc +
      coin.layeredBuyBoughts.reduce(
        (acc2, trade) =>
          acc2 +
          (Number.isFinite(trade.profitSecured) ? trade.profitSecured! : 0),
        0
      ),
    0
  );
  return { noOfSelectedLayers, totalProfitOfSelectedLayers };
};

export const getSelectedCoinDetails = (
  filteredCoinDetails: Record<string, BackTestCoin> | undefined,
  coinDetails: Record<string, BackTestCoin>
): Record<string, BackTestCoin> =>
  filteredCoinDetails && Object.keys(filteredCoinDetails).length > 0
    ? filteredCoinDetails
    : coinDetails;

export const playChimeSound = () => {
  const audio = new Audio('../../audio/bells.mp3');

  audio.addEventListener('canplaythrough', () => {
    audio.play().catch((error) => {
      console.error('Error playing audio:', error);
    });
  });

  audio.addEventListener('error', (event) => {
    console.error('Error loading audio:', event);
  });
};

export const getSelectedCoinDetailsForTable = (
  filteredDetails: Record<string, BackTestCoin> | undefined,
  allCoinDetails: Record<string, BackTestCoin>
): Record<string, BackTestTableCoin> => {
  const details =
    !filteredDetails || Object.keys(filteredDetails).length === 0
      ? allCoinDetails
      : filteredDetails;

  return Object.entries(details).reduce((acc, [key, coin]) => {
    // Only pick the required properties
    const tableData: BackTestTableCoin = {
      currentPrice: coin.currentPrice,
      layeredBuyBids: coin.layeredBuyBids,
      layeredBuyBoughts: coin.layeredBuyBoughts,
      Coin: {
        name: coin.Coin.name,
      },
      percentageStatistics: {
        averageAlertPercentage:
          coin.percentageStatistics.averageAlertPercentage,
      },
      fibLevels: {
        ATH: coin.fibLevels.ATH,
        ATL: coin.fibLevels.ATL,
        drawdownPercentage: coin.fibLevels.drawdownPercentage,
      },
    };

    return {
      ...acc,
      [key]: tableData,
    };
  }, {});
};
