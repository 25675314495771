import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import Countdown from 'react-countdown';
import { Auth } from 'aws-amplify';

import Notifications from './Notifications';
import useAuth from '../hooks/useAuth';

type HeaderProps = {
  lastUpdated?: number | undefined;
};

const Completionist = () => (
  <button
    type="button"
    onClick={() => window.location.reload()}
    style={{ border: 'none', background: 'transparent' }}
  >
    Reload!
  </button>
);
type RendererProps = {
  minutes: number;
  seconds: number;
  completed: boolean;
};
const renderer = ({ minutes, seconds, completed }: RendererProps) => {
  if (completed) {
    return <Completionist />;
  }

  return (
    <span>
      {minutes}:{seconds}
    </span>
  );
};

export const MainHeader: React.FC<HeaderProps> = ({ lastUpdated }) => {
  const isDev = process.env.NODE_ENV !== 'production';
  const { user, isAdmin } = useAuth({ useAuto: false });

  return (
    <>
      <header className="header" style={{ borderBottom: '2px solid #E3CC8C' }}>
        <nav>
          <div className="logo">
            <Link to="/sales">
              <img src="../../getRichSlow.png" alt="logo" />
            </Link>
          </div>
          <input type="checkbox" id="menu-toggle" />
          <label htmlFor="menu-toggle" className="menu-icon">
            &#9776;
          </label>
          <ul className="menu">
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            {isDev && (
              <>
                <li>
                  <Link to="/analysis">Analysis</Link>
                </li>
                <li>
                  <Link to="/BackTestMultipleCoins">BackTest</Link>
                </li>
                <li>
                  <Link to="/BackTestHighLows">BackTest Lows and highs</Link>
                </li>
              </>
            )}
            {isAdmin && (
              <li>
                <Link to="/settings">Settings</Link>
              </li>
            )}
          </ul>
          <div className="Notifications">
            {lastUpdated && (
              <Countdown date={lastUpdated + 15 * 60000} renderer={renderer} />
            )}

            <Notifications />
            {user && user?.username && (
              <Icon
                onClick={async () => {
                  await Auth.signOut();
                }}
                name="sign-out"
                size="large"
                style={{ cursor: 'pointer', marginLeft: '16px' }}
              />
            )}
          </div>
        </nav>
      </header>
    </>
  );
};
