import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { revertCoinId } from '../../../utils/utils';
import {
  DashboardCoin,
  SortAlertArgs,
  TradeDetailsDashBoard,
  isLayerBuyBought,
  SortTradeArgs,
  SortBuyTimeArgs,
  CoinOrdersTable,
} from '../../../types';
import { Percentage } from '../Percentage';
import { OrderStatus, UpdateOrderBoughtsInput } from '../../../API';
import AwsDatabase from '../../../database/Database';

const awsDB = new AwsDatabase();

export const defaulthideColumnsOnMobile = [
  'fromFourMonthATH',
  'relativeHigh',
  'fromATH',
  'higestPrice',
  'Local High Info',
  'ATH Info',
  'action',
  'timeTaken',
  'buyTime',
  'sellTime',
  'buyPrice',
  'stoplossInfo',
  'coinActions',
];

export const getTrProps = (_state: unknown, rowInfo?: any) => {
  if (rowInfo) {
    return {
      opacity: rowInfo.original.sellTime ? '0.4' : '1',
      fontWeight:
        rowInfo.original.buyTime && !rowInfo.original.sellTime ? '700' : '300',
    };
  }
  return {};
};

export const getTradePairColumn = (
  coinDetails: DashboardCoin['coinDetails'],
  istradePairBtc: boolean
) => (
  <Link
    target="_blank"
    to={`/awscoin/${coinDetails[0]}/${istradePairBtc ? 'btc' : 'usd'}`}
    style={{ color: coinDetails[2] ? 'gray' : '#4183c4' }}
  >
    <img
      style={{
        width: '24px',
        height: '24px',
        margin: '0px 5px 0px 0px',
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'white',
      }}
      src={`https://cryptologos.cc/logos/${revertCoinId(
        coinDetails[0]
      )}-logo.svg?v=010`}
      alt=""
      loading="lazy"
    />
    <span id={coinDetails[0]}>{coinDetails[1]}</span>
  </Link>
);

export const omitKey = (obj: any) => {
  const { key, ...rest } = obj;
  return rest;
};

export const getTradesOnColumn = (tradeDetails: TradeDetailsDashBoard) => {
  const {
    isCurrentlyInTrade,
    hasStopLossPrice,
    stopLossPerc,
    hasRealOrder,
    finalTrade,
  } = tradeDetails;
  let color;

  if (!isCurrentlyInTrade || (isCurrentlyInTrade && !hasStopLossPrice)) {
    color = 'initial';
  } else if (isCurrentlyInTrade && hasStopLossPrice) {
    color = finalTrade?.buyColor ?? '#85cc00';
  }

  return (
    <Percentage percentage={stopLossPerc}>
      {hasRealOrder && (
        <Icon
          name="certificate"
          style={{
            color: 'orange',
          }}
        />
      )}
      {!Number.isNaN(stopLossPerc) &&
        stopLossPerc !== -Infinity &&
        `${stopLossPerc.toFixed(2)} %`}

      <span
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: color,
          color: stopLossPerc < 0 ? 'red' : 'green',
          display: 'block',
        }}
      />
    </Percentage>
  );
};

export const getPercFromFourMonthHighColumn = (fromFourMonthATH: number) => (
  <span
    style={{
      width: '100%',
      height: '100%',
      borderRadius: '2px',
    }}
  >
    {fromFourMonthATH}
    <span
      style={{
        width: `${fromFourMonthATH}%`,
        height: '10px',
        backgroundColor:
          fromFourMonthATH > 59
            ? '#85cc00'
            : fromFourMonthATH > 49
            ? '#c6e270'
            : fromFourMonthATH > 39
            ? '#ffbf00'
            : '#0c87d0',
        borderRadius: '2px',
        transition: 'all .2s ease-out',
        display: 'block',
      }}
    />
  </span>
);

export const getPercFromAllTimeHighColumn = (fromATH: number) => (
  <span
    style={{
      width: '100%',
      height: '100%',
      borderRadius: '2px',
    }}
  >
    {fromATH}
    <span
      style={{
        width: `${fromATH}%`,
        height: '10px',
        backgroundColor:
          fromATH > 90 ? '#85cc00' : fromATH > 35 ? '#ffbf00' : '#0c87d0',
        borderRadius: '2px',
        transition: 'all .2s ease-out',
        display: 'block',
      }}
    />
  </span>
);

export const getAlertTriggeredDiv = (alerts: DashboardCoin['alerts']) => {
  if (!alerts.hasAlertTriggered) {
    return null;
  }

  let content = '';
  if (alerts.piggyBankEligible) {
    content += ' 🐷'; // Add pig emoji for eligible
  }
  if (alerts.piggyBankFibOrder) {
    content += ' 🤑'; // Add money face emoji for fib orders
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: alerts.hasStoploss
          ? 'rgba(133, 204, 0, 0.468)'
          : alerts.hasAlertTriggered
          ? 'rgb(12, 135, 208)'
          : 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        color: '#fff',
        fontSize: '16px',
        lineHeight: 1,
        gap: '4px',
      }}
    >
      {content}
    </div>
  );
};

export const sortTrades = (
  a: SortTradeArgs,
  b: SortTradeArgs,
  id: string,
  desc: number
): number => {
  const firstVal =
    !desc && a.values[id].stopLossPerc === -Infinity
      ? Infinity
      : a.values[id].stopLossPerc;
  const secondVal =
    !desc && b.values[id].stopLossPerc === -Infinity
      ? Infinity
      : b.values[id].stopLossPerc;

  return Number(firstVal) - Number(secondVal);
};

export const sortAlerts = (
  a: SortAlertArgs,
  b: SortAlertArgs,
  id: string,
  desc: number
): number => {
  const firstVal =
    !desc && Number(a.values[id].hasAlertTriggered) === -Infinity
      ? Infinity
      : a.values[id].hasAlertTriggered;
  const secondVal =
    !desc && Number(b.values[id].hasAlertTriggered) === -Infinity
      ? Infinity
      : b.values[id].hasAlertTriggered;

  return Number(secondVal) - Number(firstVal);
};

export const sortBuyTime = (
  a: SortBuyTimeArgs,
  b: SortBuyTimeArgs,
  id: string,
  _desc: number
): number => {
  const firstVal = a.values[id] === -Infinity ? Infinity : a.values[id];
  const secondVal = b.values[id] === -Infinity ? Infinity : b.values[id];

  return firstVal - secondVal;
};

export const getNoOfAlertTriggered = (allCoinsData: DashboardCoin[]) => {
  const alertTriggered = allCoinsData.filter(
    (coin: DashboardCoin) => coin.alerts.hasAlertTriggered
  );

  return `${alertTriggered.length} Alerts on - ${+(
    (alertTriggered.length / allCoinsData.length) *
    100
  ).toFixed(0)}%`;
};

export const getNoOfTradesOn = (
  allCoinsData: DashboardCoin[],
  isMobile = false
) => {
  const noOfActiveTrades = allCoinsData.filter(
    (coin: DashboardCoin) => coin.tradeDetails.isCurrentlyInTrade
  );

  if (isMobile) {
    return `Trades`;
  }

  return `${noOfActiveTrades.length} Trades on - ${+(
    (noOfActiveTrades.length / allCoinsData.length) *
    100
  ).toFixed(0)}%`;
};

const markOrderAsPaper = async (original: CoinOrdersTable) => {
  const updateBoughtOrder: UpdateOrderBoughtsInput = {
    id: original.orderData.id,
    realBuyOrderStatus: null,
  };
  await awsDB.updateCoinBoughtOrder(updateBoughtOrder);
};

const deleteAlreadyBoughtOrder = async (original: CoinOrdersTable) => {
  await awsDB.deleteCoinBoughtOrder(original.orderData.id);
};

const markOrderAsSold = async (
  original: CoinOrdersTable,
  currentPrice: number
) => {
  const profitSecured = +(
    ((currentPrice - original.buyPrice) / original.buyPrice) *
    100
  ).toFixed(2);

  const realSellOrderInfo = {
    sellOrderInfo: {
      amount: original.invested! / original.buyPrice,
      status: 'closed' as OrderStatus,
    },
    realSellOrderStatus: 'closed',
  };

  const updateBoughtOrder: UpdateOrderBoughtsInput = {
    id: original.orderData.id,
    ...(isLayerBuyBought(original.orderData) && realSellOrderInfo),
    profitSecured,
    sellTime: new Date().getTime(),
    stoplossPrice: currentPrice,
  };

  await awsDB.updateCoinBoughtOrder(updateBoughtOrder);
};

const deleteBuyBidOrder = async (original: CoinOrdersTable) => {
  await awsDB.deleteCoinBidOrder(original.orderData.id);
};

export const getButtonsBasedOnOrders = (
  original: CoinOrdersTable,
  currentPrice?: number,
  isAdmin?: boolean
) => (
  <>
    {isAdmin &&
    isLayerBuyBought(original.orderData) &&
    original.orderData.realBuyOrderStatus &&
    original.orderData.realBuyOrderStatus === 'toBePlaced' ? (
      <Button
        compact
        size="mini"
        type="button"
        className="btn btn-error"
        onClick={() => {
          if (!currentPrice) return;
          markOrderAsPaper(original);
        }}
      >
        Mark Paper
      </Button>
    ) : null}
    {isAdmin && original.buyTime && !original.sellTime ? (
      <Button
        compact
        size="mini"
        type="button"
        className="btn btn-error"
        onClick={() => {
          if (!currentPrice) return;
          markOrderAsSold(original, currentPrice);
        }}
      >
        Mark Sold
      </Button>
    ) : null}

    {isAdmin && !original.sellTime && (
      <Button
        compact
        size="mini"
        type="button"
        className="btn btn-error"
        onClick={() => {
          if (original.buyTime) {
            deleteAlreadyBoughtOrder(original);
          }

          if (!original.buyTime) {
            deleteBuyBidOrder(original);
          }
        }}
      >
        Delete
      </Button>
    )}
  </>
);
