import { BarData, createChart, ISeriesApi } from 'lightweight-charts';
import React from 'react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  Container,
  Grid,
  Header,
  Label,
  Statistic,
  Step,
  Image,
  Checkbox,
  Segment,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import {
  AWSCoinInformation,
  LayeredBoughtDetails,
  AvgHighLowsPerc,
  MainChartProps,
  TradeNotations,
  LinesInChart,
} from '../../types';
import {
  averageHighsAndLows,
  calculateTotalPnLPercentage,
} from '../../utils/calculations/percentageAndprice';
import { revertCoinId } from '../../utils/utils';
import { MainHeader } from '../layout/Header';
import { AwsCoinForm } from '../SubComponents/forms/CoinForm';
import { AwsManualBuyForm } from '../SubComponents/forms/ManualBuyForm';
import { Percentage } from '../SubComponents/Percentage';
import { AwsOrdersTable } from '../SubComponents/tables/CoinOrdersTable';
import AwsDatabase from '../../database/Database';
import {
  chartLayoutStyle,
  chartSeriesOptions,
  preparechart,
  timeScale,
} from '../../chart-specifics/create-chart';
import { getCoinTotalInvestedAndProfit } from '../layout/Dashboard';
import { getHistoricalDataForCoin } from '../../api/apiCalls';

const AwsDB = new AwsDatabase();

type TotalProfitLossProps = {
  boughts: LayeredBoughtDetails;
  currentPrice: number;
};

type Params = { coinID: string; tradeNotation: TradeNotations };
export const TotalProfitLoss: React.FC<TotalProfitLossProps> = ({
  boughts,
  currentPrice,
}) => {
  const { profitLoss } = calculateTotalPnLPercentage(boughts, currentPrice);

  if (Number.isNaN(profitLoss)) return null;

  return (
    <h1>
      <Percentage percentage={profitLoss}>{profitLoss.toFixed(2)}%</Percentage>
    </h1>
  );
};

type TotalTimeTakenProps = { coinDetails: AWSCoinInformation };
export const TotalTimeTaken: React.FC<TotalTimeTakenProps> = ({
  coinDetails,
}: TotalTimeTakenProps) => {
  const { layeredBuyBoughts, currentPrice } = coinDetails;
  const { avgTimeForAllTradeToComplete } = getCoinTotalInvestedAndProfit(
    layeredBuyBoughts.items,
    currentPrice
  );

  return (
    <h2 className="noMargin">{`${avgTimeForAllTradeToComplete.toFixed(
      0
    )} days`}</h2>
  );
};

const CoinStatistic = React.memo(
  ({ coinID, name, rank }: { coinID: string; name: string; rank: number }) => (
    <Statistic.Group size="tiny" className="marginAuto">
      <Statistic>
        <Statistic.Value>
          <Image
            src={`https://cryptologos.cc/logos/${revertCoinId(
              coinID
            )}-logo.svg?v=010`}
            inline
            circular
            width="30"
            style={{ verticalAlign: 'top', marginRight: '4px' }}
          />
          {name}
        </Statistic.Value>
        <Label className="marginTop" color="olive">
          Rank : {rank}
        </Label>
      </Statistic>
    </Statistic.Group>
  )
);

// Separate components for each step type
const ProfitLossStep = React.memo(
  ({
    boughts,
    currentPrice,
  }: {
    boughts: LayeredBoughtDetails;
    currentPrice: number;
  }) => (
    <Step
      icon="bitcoin"
      description={
        <TotalProfitLoss boughts={boughts} currentPrice={currentPrice} />
      }
    />
  )
);

const TimeTakenStep = React.memo(
  ({ coinDetails }: { coinDetails: AWSCoinInformation }) => (
    <Step
      className="hide-on-mobile"
      icon="thumbs up"
      title={<TotalTimeTaken coinDetails={coinDetails} />}
      description="for sold orders"
    />
  )
);

const HighLowStep = React.memo(
  ({ highsAndLowsAvg }: { highsAndLowsAvg?: AvgHighLowsPerc }) => (
    <>
      <Step
        className="hide-on-mobile center"
        icon="angle double down"
        title={
          highsAndLowsAvg && (
            <b>
              Low {highsAndLowsAvg.lowLows}
              <Header as="b" size="medium" color="olive">
                {' '}
                |{' '}
              </Header>
              Med <Label color="olive">{highsAndLowsAvg.avgLows}</Label>
            </b>
          )
        }
        description="Drop from the highs"
        active
      />
      <Step
        className="hide-on-mobile"
        icon="angle double up"
        title={
          highsAndLowsAvg && (
            <b>
              {`Low ${highsAndLowsAvg.lowHighs}`}{' '}
              <Header as="b" size="medium" color="olive">
                {' '}
                |{' '}
              </Header>
              Med{' '}
              <Label color="olive">
                {highsAndLowsAvg.avgHighs && highsAndLowsAvg.avgHighs > 200
                  ? 200
                  : highsAndLowsAvg.avgHighs}
              </Label>
            </b>
          )
        }
        description="Rise from the lows"
      />
    </>
  ),
  (prevProps, nextProps) => {
    // If both are undefined or null, they're equal
    if (!prevProps.highsAndLowsAvg && !nextProps.highsAndLowsAvg) {
      return true;
    }
    // If one is undefined/null and the other isn't, they're different
    if (!prevProps.highsAndLowsAvg || !nextProps.highsAndLowsAvg) {
      return false;
    }
    // Compare actual values
    return (
      prevProps.highsAndLowsAvg.lowLows === nextProps.highsAndLowsAvg.lowLows &&
      prevProps.highsAndLowsAvg.avgLows === nextProps.highsAndLowsAvg.avgLows &&
      prevProps.highsAndLowsAvg.lowHighs ===
        nextProps.highsAndLowsAvg.lowHighs &&
      prevProps.highsAndLowsAvg.avgHighs === nextProps.highsAndLowsAvg.avgHighs
    );
  }
);

const ToggleStep = React.memo(
  ({
    label,
    checked,
    onChange,
    id,
  }: {
    label: string;
    checked: boolean;
    onChange: () => void;
    id: string;
  }) => (
    <Step
      description={
        <Segment compact basic>
          <Label as="span" basic size="large" className="noBorder">
            {label}
          </Label>
          <Checkbox
            toggle
            className="vmiddle"
            id={id}
            name={id}
            checked={checked}
            onChange={onChange}
          />
        </Segment>
      }
    />
  )
);

const CoinInfoComponent: React.FC = (): JSX.Element | null => {
  const { coinID, tradeNotation } = useParams<Params>();
  if (!coinID || !tradeNotation) {
    throw new Error('No coinID or tradeNotation provided');
  }

  const linesInChart: LinesInChart = {
    highPriceLine: undefined,
    lowPriceLine: undefined,
    alertPriceLine: undefined,
    breakEvenLine: undefined,
    buyLines: undefined,
    boughtLines: undefined,
  };

  const [coinDetails, setCoinDetails] = React.useState<AWSCoinInformation>();
  const [coinHistData, setCoinHistData] = React.useState<BarData[]>();
  const [priceLines, setPriceLines] =
    React.useState<LinesInChart>(linesInChart);
  const [chartAndCandleStick, setMainChart] = React.useState<MainChartProps>();
  const [showTrades, setShowTrades] = React.useState<boolean>(true);
  const [highsAndLowsAvg, setHighsAndLowsAvg] =
    React.useState<AvgHighLowsPerc>();
  const notation = tradeNotation === 'btc' ? 'btcPair' : 'usdPair';
  const chartRef = React.createRef<HTMLDivElement>();
  const [showFibLevels, setShowFibLevels] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchCoinData = async () => {
      const symbol = coinID.split('-')[0];

      const [histBinanceData, coinInfo] = await Promise.all([
        getHistoricalDataForCoin(symbol, tradeNotation, 'day', 2000),
        AwsDB.getCoinDetailsWithBuysAndBoughts(`${coinID}-${tradeNotation}`),
      ]);

      if (coinInfo !== coinDetails) {
        setCoinDetails(coinInfo);
      }
      setCoinHistData(histBinanceData);
    };

    fetchCoinData();
  }, [coinID, tradeNotation]);

  React.useEffect(() => {
    const showPnL = false;

    if (!chartRef.current || !coinDetails || !coinHistData) return;

    const initializeChart = () => {
      if (chartAndCandleStick) return chartAndCandleStick;

      const chart = createChart(chartRef.current!, {
        width: chartRef.current!.clientWidth,
        height: 800,
        timeScale,
      });
      chart.applyOptions({ ...chartLayoutStyle });

      const candlestickSeries = chart.addCandlestickSeries();
      candlestickSeries.applyOptions({ ...chartSeriesOptions });

      return {
        mainChart: chart,
        mainCandlestickSeries: candlestickSeries,
      };
    };

    const chartWithSeries = chartAndCandleStick || initializeChart();

    preparechart(
      tradeNotation,
      notation,
      coinDetails,
      coinHistData,
      priceLines,
      chartWithSeries,
      setPriceLines,
      setMainChart,
      showPnL,
      showTrades,
      showFibLevels
    );

    setHighsAndLowsAvg(averageHighsAndLows(coinDetails.highsAndLows));
  }, [
    coinDetails,
    coinHistData,
    chartAndCandleStick,
    showTrades,
    showFibLevels,
    chartRef.current,
  ]);

  React.useEffect(() => {
    const resizeChart = () => {
      if (chartAndCandleStick?.mainChart && chartRef.current) {
        chartAndCandleStick.mainChart.resize(chartRef.current.clientWidth, 800);
      }
    };

    window.addEventListener('resize', resizeChart);
    return () => window.removeEventListener('resize', resizeChart);
  }, [chartAndCandleStick]);

  const handleTradesToggle = () => {
    setShowTrades((prev) => !prev);
  };

  const handleFibsToggle = () => {
    if (!chartAndCandleStick || !chartRef.current) return;

    const { mainChart } = chartAndCandleStick;

    // Reset candlestick series
    mainChart.removeSeries(chartAndCandleStick.mainCandlestickSeries);
    const newCandlestickSeries = mainChart.addCandlestickSeries();
    newCandlestickSeries.applyOptions({ ...chartSeriesOptions });

    // Update chart state
    setMainChart({
      mainChart,
      mainCandlestickSeries: newCandlestickSeries,
    });

    setShowFibLevels((prev) => !prev);
  };

  if (!coinDetails?.Coin.name) return null;

  return (
    <>
      <MainHeader />
      <Container>
        <Grid container style={{ marginTop: 0 }}>
          <Grid.Row>
            <CoinStatistic
              coinID={coinID}
              name={coinDetails.Coin.name}
              rank={coinDetails.Coin.rank}
            />
          </Grid.Row>
        </Grid>
        <Grid divided="vertically">
          <Grid.Row className="noPadding">
            <Grid.Column stretched className="noMargin">
              <div style={{ background: 'black' }} ref={chartRef} />
            </Grid.Column>
          </Grid.Row>
          <Step.Group fluid className="noMargin">
            <ProfitLossStep
              boughts={coinDetails.layeredBuyBoughts.items}
              currentPrice={coinDetails.currentPrice}
            />
            <TimeTakenStep coinDetails={coinDetails} />
            <HighLowStep highsAndLowsAvg={highsAndLowsAvg} />
            <ToggleStep
              label="show Trades ?"
              checked={showTrades}
              onChange={handleTradesToggle}
              id="showTrades"
            />
            <ToggleStep
              label="show Fibs ?"
              checked={showFibLevels}
              onChange={handleFibsToggle}
              id="showFibs"
            />
          </Step.Group>

          <AwsCoinForm
            coinID={coinID}
            notation={tradeNotation}
            higestPrice={coinDetails.higestPrice}
            relativeHigh={coinDetails.relativeHigh}
            paperTrade={coinDetails.dontTrade}
          />

          <AwsManualBuyForm
            coinID={coinID}
            notation={tradeNotation}
            currentPrice={coinDetails.currentPrice}
            paperTrade={coinDetails.dontTrade}
            highPrice={coinDetails.higestPrice}
          />

          <AwsOrdersTable
            currentPrice={coinDetails.currentPrice}
            bids={coinDetails.layeredBuyBids.items}
            boughts={coinDetails.layeredBuyBoughts.items}
            isTradePairBtc={tradeNotation === 'btc'}
          />
        </Grid>
      </Container>
    </>
  );
};

export default CoinInfoComponent;
