import React from 'react';
import {
  TextArea,
  Button,
  Header,
  Segment,
  Divider,
  Message,
  Icon,
} from 'semantic-ui-react';
import { useAllCoinData } from '../../hooks/useAllCoinData';
import {
  addNewFiftyCoinsToDB,
  deleteDontTradeCoinsFromDB,
  refreshRankingsFromCMC,
} from '../../../utils/coinManagementOperations';
import { executeEmergencySell } from '../../../chart-specifics/coinCalculation';
import { TradeNotations } from '../../../API';
import { DataProp } from '../../../types';
import AwsDatabase from '../../../database/Database';
const awsDB = new AwsDatabase();

export const CoinManagementForm: React.FC = () => {
  const [newCoins, setNewCoins] = React.useState<string>('');
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [isAddingCoins, setIsAddingCoins] = React.useState(false);
  const [isEmergencySelling, setIsEmergencySelling] = React.useState(false);
  const [sellType, setSellType] = React.useState<'BTC' | 'USD' | 'BOTH'>(
    'BOTH'
  );
  const { data, getCoinDataAndBalance } = useAllCoinData();

  const handleAddCoins = async () => {
    const coinsArray = newCoins
      .split('\n')
      .map((coin) => coin.trim())
      .filter((coin) => coin !== '');

    if (coinsArray.length === 0) {
      alert('Please enter at least one coin ID');
      return;
    }

    setIsAddingCoins(true);
    try {
      const addedCoins = await addNewFiftyCoinsToDB(data, coinsArray);

      if (addedCoins.length > 0) {
        alert(
          `Successfully added ${addedCoins.length} coins with their high/low values!`
        );
      } else {
        alert(
          'No new coins were added. They might already exist in the database.'
        );
      }

      setNewCoins('');
    } catch (error) {
      console.error('Error adding coins:', error);
      alert('Error adding coins. Check console for details.');
    } finally {
      setIsAddingCoins(false);
    }
  };

  const handleDeleteDontTradeCoins = async () => {
    if (
      window.confirm('Are you sure you want to delete all dont trade coins?')
    ) {
      try {
        await deleteDontTradeCoinsFromDB(data);
        alert('Dont trade coins deleted successfully!');
      } catch (error) {
        console.error('Error deleting coins:', error);
        alert('Error deleting coins. Check console for details.');
      }
    }
  };

  const handleRefreshRankings = async () => {
    if (!data) {
      alert('No coins data available');
      return;
    }

    setIsRefreshing(true);
    try {
      const updatedCoins = await refreshRankingsFromCMC(data);
      if (updatedCoins.length > 0) {
        alert(
          `Successfully updated rankings for ${updatedCoins.length} coins!`
        );
      } else {
        alert('All coin rankings are already up to date.');
      }
    } catch (error) {
      console.error('Error refreshing rankings:', error);
      alert('Error refreshing rankings. Check console for details.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleEmergencySell = async () => {
    if (
      !window.confirm(
        `Are you sure you want to sell all ${sellType} real trades? This action cannot be undone.`
      )
    ) {
      return;
    }

    setIsEmergencySelling(true);
    try {
      // Filter real trades based on sellType
      const pairsWithSellTypes = data.flatMap((coin) =>
        (coin.pairs?.items || []).filter((pair) => {
          if (!pair) return false;
          const isTradablePair = pair.dontTrade === false;
          const isBTCPair = pair.notation.toUpperCase() === 'BTC';
          const isUSDPair =
            pair.notation.toUpperCase() === 'USD' ||
            pair.notation.toUpperCase() === 'USDT';

          if (sellType === 'BOTH') return isTradablePair;
          if (sellType === 'BTC') return isTradablePair && isBTCPair;
          if (sellType === 'USD') return isTradablePair && isUSDPair;
          return false;
        })
      );

      const realTrades = pairsWithSellTypes.flatMap((pair) => {
        const filteredBoughts = (pair?.layeredBuyBoughts?.items || []).filter(
          (bought) => {
            if (!bought) return false;
            const isRealTrade =
              bought.realBuyOrderPlaced &&
              bought.realBuyOrderStatus === 'closed' &&
              Boolean(bought.sellTime) === false;
            return isRealTrade;
          }
        );
        console.log(`Filtered boughts for pair ${pair?.id}:`, filteredBoughts);
        return filteredBoughts;
      });

      // Process each trade
      const results = await Promise.allSettled(
        realTrades.map(async (trade) => {
          if (!trade) return null;

          const notation = trade.pairID.split('-').pop() as TradeNotations;
          const candleData = {
            time: Date.now() / 1000, // Convert to seconds
            close: 0, // We don't need this for emergency sell
            high: 0,
            low: 0,
            open: 0,
            volume: 0,
          };

          try {
            const updatedOrder = await executeEmergencySell(
              trade.pairID,
              notation,
              trade,
              candleData as unknown as DataProp
            );

            if (updatedOrder.sellTime) {
              // Update the order in database
              await awsDB.updateCoinBoughtOrder({
                ...updatedOrder,
                id: trade.id,
                stoplossPrice: updatedOrder.stoplossPrice,
              });
            }

            return updatedOrder;
          } catch (error) {
            console.error(`Failed to process trade ${trade.id}:`, error);
            return null;
          }
        })
      );

      // Count successful sells
      const successfulSells = results.filter(
        (result) => result.status === 'fulfilled' && result.value?.sellTime
      ).length;

      // Refresh data
      await getCoinDataAndBalance();

      alert(
        `Emergency sell complete!\nSuccessfully sold: ${successfulSells}/${realTrades.length} trades`
      );
    } catch (error) {
      console.error('Error during emergency sell:', error);
      alert('Error during emergency sell. Check console for details.');
    } finally {
      setIsEmergencySelling(false);
    }
  };

  return (
    <Segment>
      <Button
        color="teal"
        onClick={handleRefreshRankings}
        loading={isRefreshing}
        disabled={isRefreshing}
      >
        Refresh Coin Rankings
      </Button>
      <Divider />

      {/* Emergency Sell Section */}
      <Segment raised>
        <Header
          as="h4"
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
        >
          <Icon name="exclamation triangle" color="red" />
          Emergency Sell All Real Trades
        </Header>
        <Message warning>
          <Message.Header>Warning: Emergency Sell</Message.Header>
          <p>
            This action will sell all your real trades immediately at market
            price. This cannot be undone.
          </p>
        </Message>
        <div style={{ marginBottom: '1rem' }}>
          <Button.Group>
            <Button
              active={sellType === 'BTC'}
              onClick={() => setSellType('BTC')}
              style={{
                backgroundColor: sellType === 'BTC' ? '#2185d0' : undefined,
                color: sellType === 'BTC' ? 'white' : undefined,
              }}
            >
              BTC Pairs
            </Button>
            <Button.Or />
            <Button
              active={sellType === 'USD'}
              onClick={() => setSellType('USD')}
              style={{
                backgroundColor: sellType === 'USD' ? '#2185d0' : undefined,
                color: sellType === 'USD' ? 'white' : undefined,
              }}
            >
              USD Pairs
            </Button>
            <Button.Or />
            <Button
              active={sellType === 'BOTH'}
              onClick={() => setSellType('BOTH')}
              style={{
                backgroundColor: sellType === 'BOTH' ? '#2185d0' : undefined,
                color: sellType === 'BOTH' ? 'white' : undefined,
              }}
            >
              All Pairs
            </Button>
          </Button.Group>
        </div>
        <Button
          negative
          loading={isEmergencySelling}
          disabled={isEmergencySelling}
          onClick={handleEmergencySell}
        >
          Emergency Sell {sellType === 'BOTH' ? 'All' : sellType} Trades at
          Market Price
        </Button>
      </Segment>
      <Divider />

      <TextArea
        placeholder="Enter coin IDs (one per line) e.g.:
sol-solana
avax-avalanche
matic-polygon"
        value={newCoins}
        onChange={(e) => setNewCoins(e.target.value)}
        rows={4}
        style={{ width: '50%', marginBottom: '1rem' }}
      />
      <div style={{ marginTop: '1rem' }}>
        <Button primary loading={isAddingCoins} onClick={handleAddCoins}>
          Add New Coins
        </Button>
        <Button negative onClick={handleDeleteDontTradeCoins} disabled>
          Delete Don't Trade Coins
        </Button>
      </div>
    </Segment>
  );
};
