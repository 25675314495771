import { Placeholder, Segment } from 'semantic-ui-react';

export const ChartLoadingSkeleton = () => (
  <Segment
    tertiary
    secondary
    style={{
      backgroundColor: 'transparent',
      height: '400px',
      padding: 0,
      margin: 0,
      border: 'none',
    }}
  >
    <Placeholder
      fluid
      style={{
        backgroundColor: 'transparent',
        height: '100%',
        margin: 0,
      }}
    >
      <Placeholder.Image
        rectangular
        style={{
          backgroundColor: 'transparent',
          height: '100%',
          margin: 0,
        }}
      />
    </Placeholder>
  </Segment>
);
